var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.formattedItems.length > 1)?_c('v-col',{class:_vm.$vuetify.rtl ? 'ml-md-auto border-left' : 'mr-md-auto border-right',attrs:{"cols":"12","md":"3"}},[_c('v-tabs',{directives:[{name:"sticky",rawName:"v-sticky"}],staticClass:"d-none d-md-block",attrs:{"optional":"","vertical":"","hide-slider":"","sticky-z-index":2,"sticky-offset":"{top: 70}"}},[_vm._l((_vm.formattedItems),function(ref,index){
var to = ref.to;
var label = ref.label;
return [_c('v-tab',{key:index,staticClass:"text-body-1 text-capitalize",attrs:{"active-class":"NewsSkyBlue SystemCTA--text font-weight-medium","exact":"","to":{name: to}}},[_c('div',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t(label))+" ")]),_c('v-icon',{class:_vm.$vuetify.rtl ? 'mr-auto' : 'ml-auto',attrs:{"size":"20","right":!_vm.$vuetify.rtl,"left":_vm.$vuetify.rtl}},[_vm._v(" "+_vm._s(_vm.$vuetify.rtl ? 'mdi-chevron-left' : 'mdi-chevron-right')+" ")])],1),_c('v-divider',{key:("divider-" + index)})]})],2),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-menu',{attrs:{"min-width":"100%","tile":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"justify-start NewsSkyBlue",attrs:{"height":"56","ripple":false,"color":"NewsSkyBlue","depressed":"","text":"","block":""}},'v-btn',attrs,false),on),[_c('span',{staticClass:"SystemCTA--text text-subtitle-2 text-capitalize"},[_vm._v(" "+_vm._s(_vm.$t(_vm.current.label))+" "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)])]}}],null,false,3869002647)},[_c('v-list',{staticClass:"py-0",attrs:{"tile":"","elevation":"0"}},[_vm._l((_vm.formattedItemsForMobile),function(ref,index){
var to = ref.to;
var label = ref.label;
return [(index < _vm.items.length - 1)?_c('v-divider',{key:index}):_vm._e(),_c('v-list-item',{key:label,attrs:{"to":{name: to},"exact":""}},[_c('v-list-item-title',{staticClass:"SystemCTA--text text-body-1"},[_vm._v(_vm._s(_vm.$t(label)))])],1)]})],2)],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }