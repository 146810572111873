<template>
  <div>
    <template v-if="isMoreGroupVisible">
      <div class="text-body-1 Text01--text">
          <span :key="index" v-for="({name}, index) in groups">
            {{ name }}<span v-if="index + 1 !== groups.length">, </span>
        </span>

        <v-btn
            v-if="moreGroups.length"
            color="primary"
            text
            x-small
            @click.stop="isMoreGroupVisible = false"
        >
          <span class="text-body-2 font-weight-medium text-lowercase">
            {{ $t("showLess") }}
          </span>
        </v-btn>
      </div>
    </template>

    <template v-else>
      <div class="text-body-1 Text01--text" v-if="cutsGroups.length">
          <span :key="index" v-for="({name}, index) in cutsGroups">
            {{ name }}<span v-if="index + 1 !== cutsGroups.length">, </span>
          </span>

        <v-btn
            v-if="moreGroups.length"
            color="primary"
            text
            x-small
            @click.stop="isMoreGroupVisible = true"
        >
          <span class="text-body-2 font-weight-medium text-lowercase">
            + {{ moreGroups.length }} {{ $t("more") }}
          </span>
        </v-btn>
      </div>
    </template>


  </div>
</template>

<script>
export default {
  name: "MoreGroups",

  data() {
    return {
      isMoreGroupVisible: false
    };
  },

  props: {
    groups: {
      type: Array,
      required: true
    }
  },

  computed: {
    cutsGroups() {
      return this.groups.filter((group, index) => index < 7);
    },

    moreGroups() {
      return this.groups.filter((group, index) => index >= 7);
    }
  }
};
</script>

<style scoped/>
