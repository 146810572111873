<template>
  <v-row no-gutters>
    <v-col class="news-detail-text fs-16">
      <div v-if="response.member_ids.length > 1">
        <div class="mb-2" v-for="(n, index) in response.member_ids.length" :key="index">
          <template v-if="memberInfo[index]">
            <v-avatar size="40" :class="$vuetify.rtl ? 'ml-2' : 'mr-2'">
              <avatar
                  :size="40"
                  :initials="memberInfo[index].initials"
                  :src="memberInfo[index].avatar_field"
                  color="white"
                  backgroundColor="var(--v-IconsBlue-base)"
              />
            </v-avatar>
            <span class="text-subtitle-1 Text01--text">{{ memberInfo[index].name }}</span>
          </template>
        </div>
      </div>
      <div class="d-inline-flex align-items-center" v-else-if="memberInfo">
        <v-avatar size="40" :class="$vuetify.rtl ? 'ml-2' : 'mr-2'">
          <avatar
              :size="40"
              :initials="memberInfo.initials"
              :src="memberInfo.avatar_field"
              color="white"
              backgroundColor="var(--v-IconsBlue-base)"
          />
        </v-avatar>
        <span class="text-subtitle-1 Text01--text" v-html="childrenNames"/>
      </div>
      <div class="d-inline-flex align-items-center" v-else>
        <v-avatar color="IconsBlue" size="40" :class="$vuetify.rtl ? 'ml-2' : 'mr-2'">
          <v-icon dark>
            mdi-account
          </v-icon>
        </v-avatar>
        <span class="text-subtitle-1 Text01--text">
          [{{isAppTypeCare() ? $t('residentRemovedFromSystem') : $t('childRemovedFromSystem')}}]
        </span>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import {Avatar} from "vue-avatar";
import {mapGetters} from "vuex";
import {UserGetters} from "@/store/user";
import appTypeMixin from "@/mixins/appTypeMixin";

export default {
  components: {
    Avatar,
  },

  mixins: [appTypeMixin],

  props: {
    optionChosenKey: {
      required: true,
    },
    childrenNames: {
      required: true,
    },
    response: {
      required: true,
    }
  },

  computed: {
    ...mapGetters({
      members: `user/${UserGetters.Members}`,
    }),

    memberInfo() {
      if (this.response.member_ids.length === 1) {
        return this.members.find((member) => member.id === this.response.member_ids[0]);
      }

      if (this.response.member_ids.length > 1) {
        return this.members.filter((member) => this.response.member_ids.includes(member.id));
      }

      return null;
    },
  },
}
</script>