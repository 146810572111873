<template>
  <v-sheet
      class="body-1 Text01--text pa-4"
      color="SystemBG"
  >
    <h5 class="subtitle-2 mb-2">
      {{ title }}
    </h5>
    {{ description }}
  </v-sheet>
</template>

<script>
export default {
  name: "InfoMessage",

  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="scss"/>