import { UserGetters } from "@/store/user";
import { EventsService } from '@/services/ApiServices';
import {defaultApiPromiseHandler} from "@/helpers/helpers";
import {GlobalGetters} from "@/store/global";

export const EventFilterType = {
    FilterAll: 'filter_all',
    FilterNew: 'filter_new',
    FilterUpdated: 'filter_updated',
    FilterDeleted: 'filter_deleted'
};

export const EventsViewType = {
    Calendar: 'calendar',
    Schedule: 'schedule',
}

export const EventType = {
    Normal: '0',
    Meeting: '1',
}

const initialState = {
    events: [],
    filters: EventFilterType.FilterAll,
    viewType: EventsViewType.Calendar,
    showUpdateList: false,
    lastCalendarUpdate: null,
};

export const EventGetters = {
    Events: 'events',
    Filters: 'filters',
    ViewType: 'viewType',
    ShowUpdateList: 'showUpdateList',
    LastCalendarUpdate: 'lastCalendarUpdate',
};

export const EventActions = {
    FetchEvents: 'fetchEvents',
    FetchEventsItem: 'fetchEventsItem',
    MarkAllAsSeen: 'markAllAsSeen',
    MarkAsSeen: 'markAsSeen',
    UpdateViewType: 'updateViewType',
};

export const EventMutations = {
    SetEvents: 'setEvents',
    SetShowUpdateList: 'setShowUpdateList',
    SetLastCalendarUpdate: 'setLastCalendarUpdate',
    SetFilters: 'setFilters',
    SetViewType: 'setViewType',
};

const state = { ...initialState };

const getters = {
    [EventGetters.Events](state) {
        return state.events;
    },
    [EventGetters.Filters](state) {
        return state.filters;
    },
    [EventGetters.ViewType](state) {
        return state.viewType;
    },
    [EventGetters.ShowUpdateList](state) {
        return state.showUpdateList;
    },
    [EventGetters.LastCalendarUpdate](state) {
        return state.lastCalendarUpdate;
    },
};

const actions = {
    async [EventActions.FetchEvents]({rootGetters, commit, getters}) {
        const associationId = rootGetters[`user/${UserGetters.AssociationId}`];
        const authenticatedUser = rootGetters[`user/${UserGetters.User}`];

        let params = {
            query: rootGetters[`global/${GlobalGetters.Query}`],
            filters: [getters[EventGetters.Filters]]
        }

        return EventsService.getListItems(associationId, authenticatedUser?.user_id, params).then(({data}) => {
            const {items, showUpdateList, lastCalendarUpdate} = data;
            commit(EventMutations.SetEvents, items);
            commit(EventMutations.SetShowUpdateList, showUpdateList);
            commit(EventMutations.SetLastCalendarUpdate, lastCalendarUpdate);
        }).catch((e) => {
            throw new Error(e.response.data.message);
        });
    },
    async [EventActions.FetchEventsItem]({rootGetters}, {id, translationLang}) {
        const associationId = rootGetters[`user/${UserGetters.AssociationId}`];
        const authenticatedUser = rootGetters[`user/${UserGetters.User}`];

        return EventsService.getListItem(associationId, authenticatedUser.user_id, id, translationLang).then(({data}) => {
            return data;
        }).catch((e) => {
            throw new Error(e.response.data.message);
        });
    },
    [EventActions.UpdateViewType]({commit}, viewType) {
        commit(EventMutations.SetViewType, viewType);
    },
    async [EventActions.MarkAllAsSeen]({rootGetters}) {
        const associationId = rootGetters[`user/${UserGetters.AssociationId}`];

        return EventsService.markAllAsSeen(associationId)
            .then(({data}) => {
                return defaultApiPromiseHandler(data);
            }).catch((e) => {
                throw new Error(e.response.data.message);
            });
    },
    async [EventActions.MarkAsSeen]({rootGetters}, eventId) {
        const associationId = rootGetters[`user/${UserGetters.AssociationId}`];

        return EventsService.markAsSeen(associationId, eventId)
            .then(({data}) => {
                return defaultApiPromiseHandler(data);
            }).catch((e) => {
                throw new Error(e.response.data.message);
            });
    }
};

const mutations = {
    [EventMutations.SetEvents](state, events) {
        state.events = events;
    },
    [EventMutations.SetFilters](state, filters) {
        state.filters = filters;
    },
    [EventMutations.SetViewType](state, viewType) {
        state.viewType = viewType;
    },
    [EventMutations.SetShowUpdateList](state, payload) {
        state.showUpdateList = payload;
    },
    [EventMutations.SetLastCalendarUpdate](state, payload) {
        state.lastCalendarUpdate = payload;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
