<template>
  <v-dialog
      v-model="sheet"
      :max-width="600"
      scrollable
      @click:outside="close"
      overlay-color="#061F4B"
      overlay-opacity="0.8"
  >
    <v-card tile elevation="0">
      <v-card-title class="Text01--text text-subtitle-1">
        {{ $t('dialog_select_association_title') }}
      </v-card-title>

      <v-divider/>

      <v-card-text>
        <v-list class="pb-0" two-line>
          <v-list-item
              v-ripple="{ class: 'SystemCTA--text' }"
              v-for="association in associations"
              :key="association.id"
              @click="currentAssociationId !== association.id ? selectAssociation(association.id) : null"
              :class="currentAssociationId === association.id ? 'v-item--active v-list-item--active' : ''"
          >
            <v-list-item-avatar>
              <v-avatar color="IconsBlue" size="40">
                <img v-if="association.logo" :src="'data:image/jpg;base64,' + association.logo">
                <v-icon color="SystemWhite" v-else>mdi-city</v-icon>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-title>{{ association.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";
import {UserGetters} from "@/store/user";

export default {
  props: {
    open: {
      type: Boolean
    },
    associationsList: {
      type: Array,
    },
  },
  computed: {
    ...mapGetters({
      associationsFromUser: `user/${UserGetters.UserActiveAssociations}`,
      currentAssociationId: `user/${UserGetters.AssociationId}`,
    }),
    associations() {
      if (this.associationsList && this.associationsList.length > 0) {
        return this.associationsList;
      }

      return this.associationsFromUser;
    },
    sheet: {
      get: function () {
        return this.open;
      },
      set: function (newValue) {
        this.$emit('update:open', newValue);
      }
    },
  },
  methods: {
    selectAssociation(associationCode) {
      this.$emit('selectAssociation', associationCode);
      this.sheet = false;
    },
    close() {
      this.$emit('close');
      this.sheet = !this.sheet;
    }
  }
}
</script>