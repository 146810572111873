<template>
  <v-menu ref="menu" v-model="showTimePickerMenu" :close-on-content-click="false" nudge-bottom="58" min-width="auto">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="formattedValue"
        :error-messages="errors"
        v-bind="attrs"
        v-on="on"
        :label="$t('time')"
        hide-details="auto"
        required
        readonly
        outlined
      />
    </template>
    <v-time-picker
      v-model="selectedTime"
      :format="displayFormat"
    >
      <v-spacer/>
      <v-btn
        text
        color="primary"
        @click="showTimePickerMenu = false"
      >
        {{ $t('cancel') }}
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="saveTime"
      >
        {{ $t('ok') }}
      </v-btn>
    </v-time-picker>
  </v-menu>
</template>

<script>
import pickupLunchMixin from "@/mixins/pickupLunchMixin";
import i18next from "i18next";

export default {
  name: 'PickupTimePicker',

  mixins: [ pickupLunchMixin ],

  props: {
    value: {
      type: String,
      default: ''
    },
    errors: {
      type: Array,
      default: () => []
    },
  },

  data() {
    return {
      showTimePickerMenu: false,
      selectedTime: this.value
    }
  },

  computed: {
    formattedValue: {
      get() {
        return this.formatPickupTime(this.value);
      },
      set( newValue ) {
        this.value = newValue;
      }
    },
    displayFormat() {
      return i18next.language === 'en' ? 'ampm' : '24hr';
    },
  },

  methods: {
    saveTime() {
      this.$emit('input', this.selectedTime);
      this.showTimePickerMenu = false;
    }
  }

}
</script>
