
import {NoticeboardService} from '@/services/ApiServices';
import {UserGetters} from "@/store/user";

export const NOTICEBOARD_PAGE_SIZE = 20;

const initialState = {
    noticeboardItems: [],
};

export const NoticeboardActions = {
    fetchNoticeboardItems: 'fetchNoticeboardItems',
    getNoticeboardFile: 'getNoticeboardFile'
};

const state = {...initialState};

const actions = {
    async [NoticeboardActions.fetchNoticeboardItems]({rootGetters}, offset = 0) {
        const associationId = rootGetters[`user/${UserGetters.AssociationId}`];
        const authenticatedUser = rootGetters[`user/${UserGetters.User}`];
        
        return NoticeboardService.getNoticeboardItems(associationId, authenticatedUser?.user_id, offset).then(({data}) => {
                return data.items
            }).catch((e) => {
                throw new Error(e.response.data.message);
            });
    },

    async [NoticeboardActions.getNoticeboardFile]({rootGetters}, id, acceptType) {
        const associationId = rootGetters[`user/${UserGetters.AssociationId}`];
        const authenticatedUser = rootGetters[`user/${UserGetters.User}`];

        return NoticeboardService.getNoticeboardFile(associationId, authenticatedUser.user_id, id, acceptType).then(({data}) => {
            
            return data;
        }).catch((e) => {
            throw new Error(e.response.data.message);
        });
    },
 
};

export default {
    namespaced: true,
    state,
    actions
};
