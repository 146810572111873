<template>
  <full-width-page-form-layout :max-width="588" :loading="submitLoading">
    <v-form @submit.prevent="initForgotPassword" class="single-column-form">
      <v-container>
        <v-row no-gutters>
          <v-col col="12" class="d-flex justify-space-between align-center">
            <div class="text-sm-h2 text-subtitle-1 Text01--text">{{ $t('dialog_forgot_password_title') }}</div>
            <language-changer/>
          </v-col>

          <v-col cols="12" class="mt-6">
            <v-text-field
                v-model="email"
                :error-messages="emailErrors"
                :label="$t('contactsEmail')"
                hide-details="auto"
                type="email"
                class="mb-8"
                outlined
            />
          </v-col>
          <v-col cols="12">
            <v-btn depressed :loading="submitLoading" block type="submit" color="primary">
              {{ $t('resetPassword') }}
            </v-btn>
          </v-col>
          <v-col cols="12" class="text-center mt-5">
            <v-btn :loading="submitLoading" block color="primary" outlined :to="{name: 'login'}">
              {{ $t('back') }}
            </v-btn>
          </v-col>

          <v-col cols="12" class="align-center d-flex justify-space-between mt-5">
            <a
                target="_blank"
                class="text-decoration-none text-body-2"
                :href="globalTermsAndConditions"
                v-if="Boolean(globalTermsAndConditions)"
            >
              {{ $t('termsConditions') }}
            </a>
            <a
                target="_blank"
                class="text-decoration-none text-body-2"
                :href="globalPrivacyPolicyUrl"
                v-if="Boolean(globalPrivacyPolicyUrl)"
            >
              {{ $t('privacy_policy_title') }}
            </a>
          </v-col>

        </v-row>
      </v-container>
    </v-form>
    <association-selector
        :open.sync="openAssociationSelector"
        @close="associationSelectorClosed"
        @selectAssociation="initForgotPasswordWithAssociation"/>
  </full-width-page-form-layout>
</template>

<script>
import FullWidthPageFormLayout from "@/components/FullWidthPageFormLayout";
import {mapActions} from 'vuex';
import {UserActions} from "@/store/user";
import {required, email} from "vuelidate/lib/validators";
import validationMixin from "@/mixins/validationMixin";
import AssociationSelector from "@/components/AssociationSelector";
import {ForgotPasswordStatuses} from "@/store/user";
import {LegalActions} from "@/store/legalsDocuments";
import LanguageChanger from "@/components/LanguageChanger";

export default {
  name: "ForgotPasswordPage",

  async mounted() {
    await this.initLoadingPageData();
  },

  components: {
    FullWidthPageFormLayout,
    AssociationSelector,
    LanguageChanger
  },
  data() {
    return {
      email: null,
      submitLoading: false,
      openAssociationSelector: false,
      selectedAssociationCode: '',
      globalPrivacyPolicyUrl: null,
      globalTermsAndConditions: null,
    }
  },
  mixins: [validationMixin],
  validations: {
    email: {required, email},
  },
  computed: {
    emailErrors() {
      return this.getErrorMessagesForFieldWithRules('email', {
        required,
        email,
      });
    },
  },
  methods: {
    ...mapActions({
      forgotPassword: `user/${UserActions.ForgotPassword}`,
      fetchLegalItems: `legalsDocuments/${LegalActions.GetLegalLinks}`,
    }),

    async initLoadingPageData() {
      await this.fetchLegalItems()
          .then(async ({data}) => {
            const {global_privacy_policy_url, global_terms_and_conditions} = data;

            this.globalPrivacyPolicyUrl = global_privacy_policy_url;
            this.globalTermsAndConditions = global_terms_and_conditions;
          })
          .catch(e => {
            this.$toasted.error(e.message);
          });
    },

    initForgotPassword() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.submitLoading = true;
      this.forgotPassword({
        email: this.email,
        associationCode: this.selectedAssociationCode
      }).then((forgotPasswordStatus) => {
        if (forgotPasswordStatus === ForgotPasswordStatuses.SelectAssociation) {
          this.openAssociationSelector = true;
          return true;
        }
        this.$v.$reset();
        this.submitLoading = false;
        this.email = null;
        this.selectedAssociationCode = null;
        this.$toasted.success(this.$t('resetPasswordLinkSent'));
      }).catch((e) => {
        this.$toasted.error(e.message);
        this.submitLoading = false;
      })
    },
    initForgotPasswordWithAssociation(associationCode) {
      this.selectedAssociationCode = associationCode;
      return this.initForgotPassword();
    },
    associationSelectorClosed() {
      this.submitLoading = false;
    },
  }
}
</script>
