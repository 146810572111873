<template>
  <v-dialog
      v-model="dialog"
      :max-width="maxWidth"
      scrollable
      @click:outside="close"
      overlay-color="#061F4B"
      overlay-opacity="0.8"
  >
    <v-card :disabled="loading" :loading="loading" tile>
      <v-toolbar flat color="transparent" max-height="50">
        <v-spacer/>
        <v-btn :class="$vuetify.rtl ? 'ml-0' : 'mr-0'" icon color="Text03" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text ref="container" class="px-2">
        <v-list two-line class="py-0">
          <v-list-item>
            <v-list-item-icon v-if="icon" class="my-0">
              <v-icon :color="iconColor" :size="iconSize">
                {{ icon }}
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content
              class="py-0 text-truncate"
              :class="{ 'text-center': $vuetify.breakpoint.xsOnly }"
            >
              <v-list-item-title>
                <div class="text-subtitle-1 mb-2 text-wrap Text01--text">
                  {{ title }}
                </div>
              </v-list-item-title>
              <v-list-item-subtitle class="overflow-visible">
                <slot name="content">
                  <div class="text-wrap Text02--text text-body-2">
                    {{ label }}
                  </div>
                </slot>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>

      <v-card-actions class="d-flex flex-column flex-sm-row justify-end px-6 pb-6 gap-4">
        <v-btn
            v-if="cancelButtonVisible"
            color="primary"
            outlined
            :min-width="106"
            :disabled="loading"
            :block="$vuetify.breakpoint.xsOnly"
            @click="close"
        >
          {{ cancelButtonLabel || $t("cancel") }}
        </v-btn>

        <v-btn
            v-if="applyButtonVisible"
            depressed
            class="ml-0"
            :min-width="106"
            :color="applyButtonColor"
            :loading="loading"
            :block="$vuetify.breakpoint.xsOnly"
            @click="apply"
        >
          {{ applyButtonLabel || $t("ok") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmationModal",

  props: {
    value: {
      type: Boolean
    },
    title: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    icon: {
      type: String,
      required: false,
      default: function () {
        return null;
      }
    },
    iconColor: {
      type: String,
      required: false,
      default: function () {
        return "primary";
      }
    },
    iconSize: {
      type: Number,
      required: false,
      default: 70
    },
    applyButtonLabel: {
      type: String,
      default: null
    },
    applyButtonColor: {
      type: String,
      default: function () {
        return "error";
      }
    },
    cancelButtonLabel: {
      type: String,
      default: null
    },
    applyButtonVisible: {
      type: Boolean,
      default: true
    },
    cancelButtonVisible: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: Number,
      default: 552
    }
  },
  computed: {
    dialog: {
      get: function () {
        return this.value;
      },
      set: function (newValue) {
        this.$emit("input", newValue);
      }
    }
  },
  methods: {
    close() {
      this.$emit('close');
      this.dialog = false;
    },
    apply() {
      this.$emit("apply", false);
    }
  }
};
</script>
