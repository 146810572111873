import {UserGetters} from "@/store/user";
import {MembersService} from "@/services/ApiServices";

const initialState = {
    members: [],
    member: {
        avatar: null,
        avatar_url: null,
        config: {
            lunchinfo: false,
            pickupinfo: false,
            show_check_in_check_out: false,
            sicklist: false,
            additionalcontacts: false,
            showabsentcomment: false,
            showabsentreason: false,
        },
        first_name: null,
        last_name: null,
        name: null,
        groups: [],
        id: null,
        lunch_required_friday: false,
        lunch_required_monday: false,
        lunch_required_thursday: false,
        lunch_required_tuesday: false,
        lunch_required_wednesday: false,
        standard_pickup_time_friday: null,
        standard_pickup_time_monday: null,
        standard_pickup_time_thursday: null,
        standard_pickup_time_tuesday: null,
        standard_pickup_time_wednesday: null,
    },
};

export const MemberGetters = {
    Member: 'member',
    Members: 'members'
};

export const MemberMutations = {
    SetMember: 'setMember',
    SetMembers: 'setMembers',
};

export const MemberActions = {
    FetchMembers: 'fetchMembers',
    FetchMember: 'fetchMember',
    UpdateAvatar: 'updateAvatar',
    FetchAvatar: 'fetchAvatar',
    SaveAvatar: 'saveAvatar',
    DeleteAvatar: 'deleteAvatar'
};

export const MEMBERS_PAGE_SIZE = 20;

const state = {...initialState};

const getters = {
    [MemberGetters.Member](state) {
        return state.member;
    },
    [MemberGetters.Members](state) {
        return state.members;
    },
};
const mutations = {
    [MemberMutations.SetMember](state, payload) {
        state.member = payload;
    },
    [MemberMutations.SetMembers](state, payload) {
        state.members = payload;
    },
};

const actions = {
    async [MemberActions.FetchMembers]({rootGetters, commit}, offset = 0) {
        const associationId = rootGetters[`user/${UserGetters.AssociationId}`];
        const authenticatedUser = rootGetters[`user/${UserGetters.User}`];

        return await MembersService.getMembers(associationId, offset).then(async ({data}) => {
            let {items} = data;

            items = await Promise.all(items.map(async (member) => {
                const {id, avatar_url, first_name, last_name} = member;

                member.avatar_field = null;
                data.initials = 'N/A';

                if (avatar_url) {
                    let {data} = await MembersService.fetchAvatar(id, associationId, authenticatedUser?.user_id);

                    member.avatar_field = URL.createObjectURL(data);
                }

                let firstNameLetter = first_name.charAt(0).toUpperCase();
                let lastNameLetter = last_name.charAt(0).toUpperCase();

                member.initials = `${firstNameLetter}${lastNameLetter}`;

                return member;
            }));

            await commit(MemberMutations.SetMembers, items);

            return items;
        }).catch((e) => {
            throw new Error(e.response.data.message);
        });
    },
    async [MemberActions.FetchMember]({rootGetters, commit}, id) {
        const associationId = rootGetters[`user/${UserGetters.AssociationId}`];
        const authenticatedUser = rootGetters[`user/${UserGetters.User}`];

        return MembersService.getMember(associationId, id).then(async ({data}) => {
            const {id, avatar_url, first_name, last_name} = data;

            data.avatar_field = null;
            data.initials = 'N/A';

            if (avatar_url) {
                let response = await MembersService.fetchAvatar(id, associationId, authenticatedUser?.user_id);

                data.avatar_field = URL.createObjectURL(response.data);
            }

            let firstNameLetter = first_name.charAt(0).toUpperCase();
            let lastNameLetter = last_name.charAt(0).toUpperCase();

            data.initials = `${firstNameLetter}${lastNameLetter}`;

            commit(MemberMutations.SetMember, data);

            return data;
        }).catch((e) => {
            throw new Error(e.response.data.message);
        });
    },
    async [MemberActions.UpdateAvatar](context, {memberId, avatarId}) {
        return MembersService.updateAvatar(memberId, avatarId).then(({data}) => {
            return data;
        }).catch((e) => {
            throw new Error(e.response.data.message);
        });
    },
    async [MemberActions.FetchAvatar]({rootGetters}, memberId) {
        const associationId = rootGetters[`user/${UserGetters.AssociationId}`];
        const authenticatedUser = rootGetters[`user/${UserGetters.User}`];

        return MembersService.fetchAvatar(memberId, associationId, authenticatedUser?.user_id).then(({data}) => {
            return data;
        }).catch((e) => {
            throw new Error(e.response.data.message);
        });
    },
    async [MemberActions.SaveAvatar]({rootGetters}, {memberId, avatar}) {
        const associationId = rootGetters[`user/${UserGetters.AssociationId}`];
        const authenticatedUser = rootGetters[`user/${UserGetters.User}`];

        return MembersService.saveAvatar(memberId, associationId, authenticatedUser?.user_id, avatar).then(({data}) => {
            return data;
        }).catch((e) => {
            throw new Error(e.response.data.message);
        });
    },
    async [MemberActions.DeleteAvatar]({rootGetters}, memberId) {
        const associationId = rootGetters[`user/${UserGetters.AssociationId}`];
        const authenticatedUser = rootGetters[`user/${UserGetters.User}`];

        return MembersService.deleteAvatar(memberId, associationId, authenticatedUser?.user_id).then(({data}) => {
            return data;
        }).catch((e) => {
            throw new Error(e.response.data.message);
        });
    }
};


export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
