import moment from "moment";
import i18next from "i18next";

export default {
    methods: {
        isToday(dateString) {
            const inputDate = moment(dateString);
            return moment().isSame(inputDate, 'day');
        },
        isPast(dateString) {
            const today = moment().startOf('day');
            return moment(dateString).isBefore(today);
        },

        hasTimePassed(timeString) {
            const currentTime = moment();
            const deadlineToday = moment(timeString, 'HH:mm');

            return currentTime.isAfter(deadlineToday);
        },
        formatDate(value) {
            const date = moment(value).locale(i18next.language);
            return date.format(this.$t('dayOfWeekAndDayFormat'));
        },
    },
};
