<template>
  <v-form @submit.prevent="$emit('submit')">
    <validation-observer ref="observer" tag="div">
      <v-container class="px-0">
        <v-row>
          <v-col cols="12">
            <div class="editable-avatar-container d-flex justify-center">
              <v-avatar size="96" class="overflow-visible" color="IconsLightBlue">
                <img v-if="contact.avatar_field" :src="contact.avatar_field" alt="">

                <v-icon v-else size="55" color="SystemWhite">
                  mdi-account
                </v-icon>

                <v-btn
                    v-if="!isFormDisabled"
                    color="primary"
                    depressed
                    fab
                    x-small
                    @click="initChoseFile"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>

              </v-avatar>

              <AvatarUploadDialog
                  hide-btn
                  @confirm="confirmAvatarUploading"
                  :return-original="true"
                  :title="$t('editPhoto')"
              />
            </div>
          </v-col>

          <v-col cols="12" v-if="type === 'create'">
            <v-alert dense color="SystemBG" rounded="0" class="mb-0">
              <template v-slot:prepend>
                <v-icon class="align-self-baseline mt-1 mr-3" color="IconsBlue">mdi-alert-circle</v-icon>
              </template>

              <span class="text-md-body-2 text-caption Text01--text">
                {{$t('membersAdditionalContactsPrivacyHelpMessage') }}
              </span>
            </v-alert>
          </v-col>

          <v-col cols="12" md="6">
            <validation-provider
                v-slot="{ errors }"
                :name="$t('registerFirstName')"
                vid="first_name"
                rules="required|max:50"
            >
              <v-text-field
                  v-model="contact.first_name"
                  :error-messages="errors"
                  :label="$t('registerFirstName')"
                  class="required"
                  required
                  outlined
                  hide-details="auto"
                  :disabled="isFormDisabled"
              />
            </validation-provider>
          </v-col>

          <v-col cols="12" md="6">
            <validation-provider
                v-slot="{ errors }"
                :name="$t('registerLastName')"
                vid="last_name"
                rules="required|max:50"
            >
              <v-text-field
                  v-model="contact.last_name"
                  :error-messages="errors"
                  :label="$t('registerLastName')"
                  class="required"
                  required
                  outlined
                  hide-details="auto"
                  :disabled="isFormDisabled"
              />
            </validation-provider>
          </v-col>

          <v-col cols="12">
            <validation-provider
                v-slot="{ errors }"
                vid="is_allowed_to_pick_up"
                :rules="{required: !checkboxes.length}"
                :custom-messages="{required: $t('newsResponseErrorSelect')}"
            >
              <v-list class="py-0" :disabled="isFormDisabled">
                <v-list-item-group
                    v-model="checkboxes"
                    multiple
                >
                  <v-subheader class="px-0 text-body-1 Text01--text" :class="{'AlertRed--text': errors.length}">
                    {{ $t('chooseAtLeastOneOptionBelow') }}*
                  </v-subheader>
                  <v-list-item value="is_allowed_to_pick_up" class="px-0" :ripple="false">
                    <template v-slot:default="{ active }">
                      <v-list-item-content>
                        <v-list-item-title class="text-body-1 Text01--text" :class="{'AlertRed--text': errors.length}">
                          {{ $t('membersAdditionalContactsAllowedToPickUp') }}
                        </v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-checkbox :disabled="isFormDisabled" :error-messages="errors" :input-value="active"/>
                      </v-list-item-action>
                    </template>
                  </v-list-item>

                  <v-divider/>

                  <v-list-item value="is_emergency_contact" class="px-0" :ripple="false">
                    <template v-slot:default="{ active }">
                      <v-list-item-content>
                        <v-list-item-title class="text-body-1 Text01--text" :class="{'AlertRed--text': errors.length}">
                          {{ $t('membersAdditionalContactsEmergencyContact') }}
                        </v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-checkbox :disabled="isFormDisabled" :error-messages="errors" :input-value="active"/>
                      </v-list-item-action>
                    </template>
                  </v-list-item>

                  <v-divider/>

                </v-list-item-group>
              </v-list>

              <custom-error-messages :errors="errors"/>
            </validation-provider>
          </v-col>

          <v-col cols="12" v-if="isMobileVisible">
            <v-alert v-if="type === 'create'" dense color="SystemBG" rounded="0">
              <template v-slot:prepend>
                <v-icon class="align-self-baseline mt-1 mr-3" color="IconsBlue">mdi-alert-circle</v-icon>
              </template>

              <span class="text-md-body-2 text-caption Text01--text">
                {{$t('membersAdditionalContactsPhoneHelpMessage') }}
              </span>
            </v-alert>
            <validation-provider
                v-slot="{ errors }"
                :name="$t('phoneNumber')"
                vid="mobile"
                :rules="{required: contact.is_emergency_contact, max: 50, phoneNumber: true}"
            >
              <v-text-field
                  v-model="contact.mobile"
                  :error-messages="errors"
                  :label="$t('phoneNumber')"
                  :class="{required: contact.is_emergency_contact}"
                  outlined
                  hide-details="auto"
                  :disabled="isFormDisabled"
              />
            </validation-provider>
          </v-col>

          <v-col cols="12" v-if="isCommentVisible">
            <validation-provider
                v-slot="{ errors }"
                :name="$t('comment')"
                vid="comment"
                rules="max:300"
            >
              <v-textarea
                  :label="$t('comment')"
                  :error-messages="errors"
                  v-model="contact.comment"
                  rows="1"
                  outlined
                  auto-grow
                  counter
                  :disabled="isFormDisabled"
              >
                <template v-slot:counter="{ props: { value } }">
                  <div class="Text03--text text-caption">
                    {{ value }} / 300
                  </div>
                </template>
              </v-textarea>
            </validation-provider>
          </v-col>

          <v-col cols="12" v-if="type === 'edit' && (formattedMembers.length >= 2 || isFormDisabled)">
            <validation-provider
                v-slot="{ errors }"
                vid="children_ids"
                :rules="{required: !contact.children_ids.length}"
                :custom-messages="{required: $t('membersAdditionalContactsPleaseSelectAtLeastOne', {memberType: isAppTypeCare() ? $t('resident') : $t('child')})}"
            >
              <v-list class="py-0" two-line>
                <v-list-item-group
                    v-model="contact.children_ids"
                    multiple
                >
                  <v-subheader class="px-0 text-body-1 Text01--text">
                    {{ $t('membersAdditionalContactsThisPersonAdditionalContactFor') }}
                  </v-subheader>

                  <template v-for="{id, initials, avatar_field, first_name, last_name} in formattedMembers">
                    <v-list-item :value="id" :key="id" class="px-0" :ripple="false">
                      <template v-slot:default="{ active }">
                        <v-list-item-avatar size="40">
                          <avatar
                              :size="40"
                              :initials="initials"
                              :src="avatar_field"
                              color="white"
                              backgroundColor="var(--v-IconsBlue-base)"
                          />
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title class="text-body-1 Text01--text">
                            {{ first_name }} {{ last_name }}
                          </v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-checkbox :input-value="active"/>
                        </v-list-item-action>
                      </template>
                    </v-list-item>

                    <v-divider :key="`${id}-divider`"/>
                  </template>

                </v-list-item-group>
              </v-list>

              <custom-error-messages :errors="errors"/>

            </validation-provider>
          </v-col>

          <v-col cols="12" v-if="type === 'create' && members.length >= 2">
            <v-row>
              <v-col cols="10" class="d-flex align-center">
                <div class="text-body-1 Text01--text mr-auto">
                  {{ $t('membersAdditionalContactsForAllChildrenToggle') }}
                </div>
              </v-col>
              <v-col class="d-flex justify-end" cols="2">
                <v-switch
                    class="mt-0 mb-1 mr-md-n2"
                    v-model="membersSwitch"
                    inset
                    hide-details
                />
              </v-col>
            </v-row>
            <v-row id="members-list" v-if="membersSwitch">
              <v-col cols="12">
                <v-list two-line>
                  <v-list-item-group
                      v-model="contact.children_ids"
                      multiple
                  >
                    <template v-for="{id, initials, avatar_field, first_name, last_name} in members">
                      <v-list-item :disabled="id === member.id" :value="id" :key="id" class="px-0" :ripple="false">
                        <template v-slot:default="{ active }">
                          <v-list-item-avatar size="40">
                            <avatar
                                :size="40"
                                :initials="initials"
                                :src="avatar_field"
                                color="white"
                                backgroundColor="var(--v-IconsBlue-base)"
                            />
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-title class="text-body-1 Text01--text">
                              {{ first_name }} {{ last_name }}
                            </v-list-item-title>
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-checkbox :input-value="active"/>
                          </v-list-item-action>
                        </template>
                      </v-list-item>

                      <v-divider :key="`${id}-divider`"/>
                    </template>

                  </v-list-item-group>
                </v-list>
              </v-col>
            </v-row>
          </v-col>

        </v-row>
      </v-container>
    </validation-observer>
  </v-form>
</template>

<script>
import {mapGetters} from "vuex";
import {AdditionalContactsGetters} from "@/store/additionalContacts";
import {MemberGetters} from "@/store/member";
import {Avatar} from "vue-avatar";
import AvatarUploadDialog from "@/components/pages/MemberPage/components/AvatarUploadDialog";
import CustomErrorMessages from "@/components/CustomErrorMessages";
import appTypeMixin from "@/mixins/appTypeMixin";
import {UserGetters} from "@/store/user";

export default {
  name: "BaseForm",

  mixins: [appTypeMixin],

  props: {
    type: {
      type: String,
      default() {
        return 'create';
      }
    }
  },

  data() {
    return {
      membersSwitch: false
    }
  },

  watch: {
    membersSwitch(value) {
      if (value) {
        this.contact.children_ids = this.members.map(member => member.id);

        this.$nextTick(() => {
          document.getElementById("members-list").scrollIntoView({behavior: "smooth"});
        });
      } else {
        this.contact.children_ids = [this.member.id];
      }
    }
  },

  components: {
    Avatar,
    AvatarUploadDialog,
    CustomErrorMessages,
  },

  methods: {
    initChoseFile() {
      this.$root.$refs.avatarUploadDialog.initChoseFile();
    },

    confirmAvatarUploading({cropped, original}) {
      this.contact.avatar_field = URL.createObjectURL(cropped);
      this.contact.avatar = new File([cropped], original.name, {type: cropped.type});
      this.contact.image = original;

      this.$root.$refs.avatarUploadDialog.close();
    },
  },

  computed: {
    ...mapGetters({
      contact: `additionalContacts/${AdditionalContactsGetters.Model}`,
      members: `member/${MemberGetters.Members}`,
      member: `member/${MemberGetters.Member}`,
      childrenIds: `additionalContacts/${AdditionalContactsGetters.ChildrenIds}`,
      authUser: `user/${UserGetters.User}`,
    }),

    formattedMembers() {
      const {available_children_ids} = this.contact;

      return this.members.filter((member) => {
        const {id} = member;
        return available_children_ids.includes(id);
      });
    },

    isMobileVisible() {
      if (this.type === 'create') {
        return true;
      }

      return !(this.isFormDisabled && !this.contact.mobile);
    },

    isCommentVisible() {
      if (this.type === 'create') {
        return true;
      }

      return !(this.isFormDisabled && !this.contact.comment);
    },

    isFormDisabled() {
      const {created_id} = this.contact;
      const {user_id} = this.authUser;

      if (this.type === 'create') {
        return false;
      }

      return created_id !== user_id;
    },

    checkboxes: {
      get() {
        let value = [];

        if (this.contact.is_allowed_to_pick_up) {
          value.push('is_allowed_to_pick_up');
        }

        if (this.contact.is_emergency_contact) {
          value.push('is_emergency_contact');
        }

        return value;
      },
      set(value) {
        this.contact.is_allowed_to_pick_up = Boolean(value.find(item => item === 'is_allowed_to_pick_up'));

        this.contact.is_emergency_contact = Boolean(value.find(item => item === 'is_emergency_contact'));
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-list {
  .v-item-group {
    .v-list-item {
      &:hover {
        &:before {
          background-color: transparent;
        }
      }

      &.v-list-item--disabled {
        .v-list-item__title {
          color: var(--v-Text03-base) !important;
        }

        .v-list-item__action {
          i {
            color: var(--v-IconsLightBlue-base) !important;
          }
        }
      }

      &.v-list-item--active {
        &:before {
          background-color: transparent;
        }
      }
    }
  }
}
</style>