import {mapActions, mapGetters} from "vuex";
import {NewsActions, NewsGetters} from "@/store/news";

export default {

    data() {
        return {
            type: "all",
            statuses: [],
            recipients: [],
            isDirectNews: false,
            groups: [],
            panel: [0, 2],
        }
    },

    computed: {
        ...mapGetters({
            filters: `news/${NewsGetters.Filters}`,
            hasDirectNews: `news/${NewsGetters.HasDirectNews}`,
            lastPinnedNewsItems: `news/${NewsGetters.LastPinnedNewsItems}`,
            pinnedNewsCount: `news/${NewsGetters.PinnedNewsCount}`,
        }),

        recipientsCounter() {
            let counter = 0;

            if (this.recipients.length) {
                counter = counter + this.recipients.length;
            }

            if (this.isDirectNews) {
                counter = counter + 1;
            }

            return counter;
        },

        resetButtonCounter() {
            let counter = 0;

            if (this.type !== "all") {
                counter = counter + 1;
            }

            if (this.statuses.length) {
                counter = counter + this.statuses.length;
            }

            counter = counter + this.recipientsCounter;

            return counter;
        },

        receiverType() {
            if (!this.hasDirectNews) {
                return "all";
            }

            if (this.isDirectNews && !this.recipients.length) {
                return "news_direct";
            }

            if (!this.isDirectNews && this.recipients.length) {
                return "news";
            }

            return "all";
        }
    },

    async mounted() {
        await this.initFetchData();

       this.setFilters()
    },

    methods: {
        ...mapActions({
            fetchNewsGroups: `news/${NewsActions.FetchNewsGroups}`,
            fetchLastPinnedNews: `news/${NewsActions.FetchLastPinnedNews}`,
        }),

        saveFilters() {
            this.filters.filter_by_type = this.type;
            this.filters.filter_by_statuses = this.statuses;
            this.filters.filter_by_groups_ids = this.recipients;
            this.filters.filter_by_receiver_type = this.receiverType;
        },

        setFilters() {
            const {filter_by_type, filter_by_statuses, filter_by_groups_ids} = this.filters;

            this.type = filter_by_type;
            this.statuses = filter_by_statuses;
            this.recipients = filter_by_groups_ids;
        },

        resetFilters() {
            this.type = "all";
            this.isDirectNews = false;
            this.statuses = [];
            this.recipients = [];

            this.saveFilters();
        },

        async initFetchData() {
            await this.fetchLastPinnedNews();

            await this.fetchNewsGroups()
                .then((data) => {
                    if (data.length > 0) {
                        this.groups.push(...data);
                    }
                });
        }
    }
}