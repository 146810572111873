<template>
  <v-container class="py-0 py-sm-8 px-0 px-sm-2 px-md-6 align-self-start h-100" :class="{'fill-height': isFillHeight}" fluid>
    <transition
        name="fade"
        mode="out-in"
    >
      <router-view/>
    </transition>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      isFillHeight: false,
    }
  },
  created() {
    if (this.$router.currentRoute.name === 'dashboard') {
      this.$router.replace({name: 'news'});
    }

    this.fillHeight();
  },
  beforeUpdate() {
    this.fillHeight();
  },
  methods: {
    fillHeight() {
      this.isFillHeight = this.$router.currentRoute.meta.fillHeight === true;
    }
  }
}
</script>