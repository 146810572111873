<template>
  <v-layout align-center justify-center>
    <v-flex class="mb-sm-15" :style="{maxWidth: maxWidth + 'px'}">
      <SkeletonLoader v-if="loading"/>

      <v-card tile :outlined="outlined" elevation="0" v-else>
        <v-card-text class="pa-sm-6">
          <slot/>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: Number,
      default: 792,
    },
    outlined: {
      type: Boolean,
      default: true,
    }
  }
}
</script>