<template>
  <div class="mb-5">
    <v-row>
      <v-col cols="12">
        <div class="text-subtitle-1 Text01--text mb-2">
          {{ isAppTypeCare() ? $t('choose_residents') : $t('choose_children') }}
        </div>
      </v-col>

      <v-col cols="12" v-for="(child, index) in children" :key="index" class="py-0">
        <v-checkbox
            v-model="mutableValue"
            :value="child.id"
            :error="!isValid"
            :disabled="isDisabled(child)"
            hide-details
            class="child-checkbox mt-0"
            :class="{'rtl': $vuetify.rtl || isRTL}"
        >
          <template v-slot:label>
            <v-avatar size="40" :class="$vuetify.rtl ? 'ml-3' : 'mr-3'">
              <avatar
                  :size="40"
                  :initials="child.initials"
                  :src="child.avatar_field"
                  color="white"
                  backgroundColor="var(--v-IconsBlue-base)"
              />
            </v-avatar>
            <div :class="$vuetify.rtl ? 'text-right' : 'text-left'">
              <div class="text-body-1 Text01--text">{{ child.first_name }} {{ child.last_name }}</div>
              <div v-if="!isInTargetGroup(child)" class="py-1 text-body-2 Text02--text">
                {{ $t('newsResponseMemberGroupNotValid') }}
              </div>
              <div v-if="isAlreadyAnsweredBySomeoneElse(child)" class="py-1 text-body-2 Text02--text">
                {{ $t('othersAnswered') }}
              </div>
              <div v-if="isAlreadyAnswered(child)" class="py-1 text-body-2 Text02--text">
                {{ $t('newsResponseMemberAlreadyAnswered') }}
              </div>
            </div>
          </template>
        </v-checkbox>

        <v-divider class="my-3" v-if="index < children.length - 1" :key="child.id"/>

      </v-col>
      <v-col cols="12" v-if="!isValid">
        <small class="error--text">
          {{ isAppTypeCare() ? $t('choose_residents') : $t('choose_children') }}
        </small>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {isInTargetGroup} from "@/helpers/childrenConditions";
import appTypeMixin from "@/mixins/appTypeMixin";
import {Avatar} from "vue-avatar";
import {isInAppTranslationRequestedLangRTL} from "@/helpers/helpers";

export default {
  mixins: [appTypeMixin],

  components: {
    Avatar,
  },

  data() {
    return {
      isRTL: false,
    }
  },

  mounted() {
    this.isRTL = isInAppTranslationRequestedLangRTL(this.$route.params.translationLang);
  },

  props: {
    value: {
      required: true,
      type: Array,
    },
    children: {
      required: true,
      type: Array
    },
    targetGroups: {
      required: true,
      type: Array
    },
    answeredByOthersIds: {
      required: false,
      type: Array
    },
    answeredIds: {
      required: true,
      type: Array
    },
    validationResult: {
      required: false,
    }
  },
  computed: {
    mutableValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      }
    },
    isValid() {
      if (!this.validationResult.$dirty) {
        return true;
      }
      return !this.validationResult.$anyError;
    }
  },
  methods: {
    getAvatarSrc(child) {
      return `/images/avatars/avatars${child.avatar}.png`;
    },
    isAlreadyAnsweredBySomeoneElse(child) {
      return this.answeredByOthersIds.includes(child.id);
    },
    isAlreadyAnswered(child) {
      return this.answeredIds.includes(child.id);
    },
    isInTargetGroup(child) {
      return isInTargetGroup(child, this.targetGroups);
    },
    isDisabled(child) {
      return !this.isInTargetGroup(child) || this.isAlreadyAnswered(child);
    }
  }
}
</script>

<style scoped lang="scss">
.v-input {
  &.v-input--is-disabled {
    ::v-deep {
      .v-input__control {
        .v-input__slot {
          .v-input--selection-controls__input {
            display: none;
          }
        }
      }
    }
  }

  &.rtl {
    ::v-deep {
      .v-input__control {
        .v-input__slot {
          .v-input--selection-controls__input {
            order: 2;
            margin-left: 0;
          }
        }
      }
    }
  }

  ::v-deep {
    .v-input__control {
      .v-input__slot {
        .v-label {
          order: 1;
        }

        .v-input--selection-controls__input {
          order: 2;
          margin-right: 0;
        }
      }
    }
  }
}
</style>
