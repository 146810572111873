<template>
  <div>
    <v-menu tile offset-y left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs"
               v-on="on"
               icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list class="py-0">
        <v-list-item @click="initMarkAllAsReadConfirm">
          <v-list-item-icon :class="$vuetify.rtl ? 'ml-3' : 'mr-3'">
            <v-icon size="20" color="SystemCTA">mdi-check</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="Text01--text text-body-2">{{ $t('actionMarkAsRead') }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <confirmation-modal
        :max-width="544"
        v-model="confirmationDialog"
        :title="$t('newsMarkAllAsReadGeneral')"
        :label="$t('confirmInfo')"
        apply-button-color="primary"
        :apply-button-label="$t('markAll')"
        @apply="initMarkAllAsRead"
    />
  </div>
</template>

<script>
import {mapActions} from 'vuex';
import {NewsActions} from "@/store/news";
import ConfirmationModal from "@/components/ConfirmationModal";

export default {
  data() {
    return {
      confirmationDialog: false,
    }
  },
  components: {ConfirmationModal},
  methods: {
    ...mapActions({
      markAllAsRead: `news/${NewsActions.MarkAllAsRead}`,
    }),
    initMarkAllAsReadConfirm() {
      this.confirmationDialog = true;
    },
    async initMarkAllAsRead() {
      await this.markAllAsRead();

      this.confirmationDialog = false

      this.$toasted.info(this.$t('newsMarkAllReadInfo'));
    }
  },
}
</script>
