<template>
  <v-row>
    <v-col>
      <div class="main-content-container tight">
        <v-col cols="12" no-gutters v-for="hourPlan in hourPlans" :key="hourPlan.id">
          <v-card :loading="requestedSubstitutionId === hourPlan.id" tile outlined>
            <v-card-text class="pa-0">
              <v-list-item @click="openPdfOrHtml(hourPlan)" v-ripple="{ class: 'SystemCTA--text' }">
                <v-list-item-icon :class="$vuetify.rtl ? 'ml-4' : 'mr-4'">
                  <v-img :alt="$t('view')" :src="`/images/${hourPlan.type}.svg`"/>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-subtitle-1 Text01--text text-wrap">
                    {{ hourPlan.title }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-body-1 Text02--text">
                    {{ hourPlan.date|date($t('normalDateFormat')) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card-text>
          </v-card>
        </v-col>

        <infinite-loading :identifier="infiniteId" @infinite="loadHourPlans">
          <template v-slot:spinner>
            <SkeletonLoader/>
          </template>
          <template v-slot:no-results>
            <empty-state
                :title="$t('no_hour_plan_title')"
                :subtitle="$t('no_hour_plan_subtitle')"
                image="/images/empty-hour-plans.svg"
            />
          </template>
        </infinite-loading>

        <pdf-viewer-dialog
            v-model="pdfDialog"
            :title="pdfDialogTitle"
            :pdfData="pdfData"
            @close="requestedSubstitutionId = null"
        />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import {mapActions} from 'vuex';
import {HourPlansActions} from "@/store/hourPlans";
import PdfViewerDialog from "@/components/PdfViewerDialog";
import EmptyState from "@/components/EmptyState";

export default {
  components: {
    PdfViewerDialog,
    EmptyState
  },
  data() {
    return {
      loading: false,
      offset: 0,
      infiniteId: +new Date(),
      hourPlans: [],

      pdfDialog: false,
      confirmationDialog: false,
      pdfDialogTitle: null,
      pdfData: null,
      requestedSubstitutionId: null,
    }
  },

  methods: {
    ...mapActions({
      fetchHourPlans: `hourPlans/${HourPlansActions.FetchHourPlans}`,
      fetchHourPlanPdf: `hourPlans/${HourPlansActions.FetchHourPlanPdf}`,
    }),
    loadHourPlans($state) {
      this.fetchHourPlans(this.offset)
          .then((data) => {
            if (data.length > 0) {
              this.offset++;
              this.hourPlans.push(...data);
              $state.loaded();
            } else {
              $state.complete();
            }
          });
    },
    async openHourPlanPdf(hourPlanId, hourPlanTitle) {
      this.requestedSubstitutionId = hourPlanId;

      await this.fetchHourPlanPdf(hourPlanId)
          .then((data) => {
            this.pdfData = data;
            this.pdfDialogTitle = hourPlanTitle;
            this.pdfDialog = true;
            this.requestedSubstitutionId = null;
          })
          .catch((e) => {
            this.$toasted.error(e.message);
            this.requestedSubstitutionId = null;
          });
    },
    openPdfOrHtml(hourPlan) {
      if (hourPlan.type === 'pdf') {
        this.openHourPlanPdf(hourPlan.id, hourPlan.title);

        return;
      }

      if (hourPlan.type === 'url') {
        window.open(hourPlan.url, '_blank');
      }
    }
  }
}
</script>
