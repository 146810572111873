<template>
  <v-responsive class="video-container my-5" v-if="!isVideoShouldBePlayed" :aspect-ratio="21/9" :class="extraClasses">
    <v-img style="transform: scale(1.1);" :aspect-ratio="21/9" :src="thumbnailSrc"/>
    <v-btn
        @click="playVideo"
        class="video-play-btn"
        icon
        color="SystemWhite"
    >
      <v-icon size="64">
        $play
      </v-icon>
    </v-btn>
  </v-responsive>
  <v-responsive class="my-5" v-else-if="isIFrameVideo" :aspect-ratio="16/9">
    <iframe
        width="100%"
        height="100%"
        class="video-iframe"
        :src="url"
        frameborder="0"
        :allow="allow"
        :allowfullscreen="allowfullscreen"
    />
  </v-responsive>
  <v-responsive class="my-5" v-else :aspect-ratio="16/9">
    <video
        width="100%"
        height="100%"
        controls
        autoplay
    >
      <source :src="url">
    </video>
  </v-responsive>
</template>

<script>

const TYPE_YOUTUBE = 1;
const TYPE_VIMEO = 2;
const TYPE_TWITCH = 3;
const TYPE_DIRECT_LINK = 4;
const TYPE_NEXTCLOUD = 5;

export default {
  props: {
    videoThumbnail: {
      type: String,
      required: false,
    },
    videoUrl: {
      type: String,
      required: true,
    },
    videoType: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      isVideoShouldBePlayed: false,
    }
  },
  computed: {
    thumbnailSrc() {
      if (this.videoThumbnail) {
        return 'data:image/jpg;base64,' + this.videoThumbnail;
      }
      return '/images/thumbnail-placeholder.svg';
    },
    url() {
      if (this.videoType === TYPE_TWITCH) {
        return (this.videoUrl).replace('parent=twitch.com', 'parent=' + location.hostname)
      }
      const autoplay = (this.videoUrl.indexOf('?') === -1 ? '?' : '&') + 'autoplay=1';
      return [this.videoUrl, autoplay].join('');
    },
    allow() {
      switch (this.videoType) {
        case TYPE_YOUTUBE:
          return 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture';
        case TYPE_VIMEO:
        case TYPE_DIRECT_LINK:
        default:
          return 'autoplay; fullscreen';
      }
    },
    allowfullscreen() {
      return this.videoType === TYPE_YOUTUBE;
    },
    extraClasses() {
      switch (this.videoType) {
        case TYPE_YOUTUBE:
          return 'youtube-video';
        case TYPE_TWITCH:
          return 'twitch-video';
        case TYPE_DIRECT_LINK:
          return 'direct-video';
        default:
          return '';
      }
    },
    isIFrameVideo() {
      return this.videoType !== TYPE_NEXTCLOUD;
    }
  },
  methods: {
    playVideo() {
      this.isVideoShouldBePlayed = true;
    }
  }
}
</script>