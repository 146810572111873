<template>
  <v-card tile outlined class="mb-5">
    <v-list-item :to="{name: 'member.show', params: {id: member.id}}">
      <v-list-item-avatar size="84">
        <avatar
            :size="82"
            :initials="member.initials"
            :src="member.avatar_field"
            color="white"
            backgroundColor="var(--v-IconsBlue-base)"
        />
      </v-list-item-avatar>

      <v-list-item-content class="pt-0">
        <v-list-item-title class="headline mt-3">
          <v-flex class="d-flex flex-column flex-md-row text-subtitle-1 Text01--text">
            <span class="text-truncate" :class="$vuetify.rtl ? 'ml-1' : 'mr-1'">{{ member.first_name }}</span>
            <span class="text-truncate">{{ member.last_name }}</span>
          </v-flex>
        </v-list-item-title>
        <v-list-item-subtitle class="text-body-2 Text02--text mt-2">
          {{ groups }}
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-action>
        <v-icon size="25" color="primary">
          {{$vuetify.rtl ? 'mdi-chevron-left' : 'mdi-chevron-right'}}
        </v-icon>
      </v-list-item-action>
    </v-list-item>
  </v-card>
</template>

<script>
import {prettyChildGroups} from "@/helpers/childrenConditions";
import {Avatar} from "vue-avatar";

export default {
  props: {
    member: {
      type: Object,
      required: true
    }
  },

  components: {
    Avatar
  },

  computed: {
    groups() {
      return prettyChildGroups(this.member.groups);
    }
  }
}
</script>