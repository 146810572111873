<template>
  <v-row class="h-100">
    <v-col>
      <div class="main-content-container wide h-100">
        <v-row sticky-container no-gutters class="justify-center h-100 align-content-start align-content-md-stretch">
          <TabsList/>

          <v-col cols="12" md="8" class="position-relative">
            <SkeletonLoader v-if="loading"/>

            <template v-else>
              <v-row>
                <v-col cols="12">
                  <privacy-policy-dialog class="mt-md-5 mt-15"/>
                </v-col>
              </v-row>
            </template>
          </v-col>

          <v-col
              :class="$vuetify.rtl ? 'ml-md-auto border-left' : 'mr-md-auto border-right'"
              class="d-none d-md-block"
              cols="12"
              md="3"
          />
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import TabsList from "@/components/pages/AccountSettingsPage/components/TabsList";
import PrivacyPolicyDialog from '@/components/PrivacyPolicyDialog';

export default {
  name: "LegalPage",

  data() {
    return {
      loading: true,
    }
  },

  components: {
    TabsList,
    PrivacyPolicyDialog,
  },

  mounted() {
    this.loading = false;
  }
}
</script>