<template>
  <div
      v-if="$vuetify.breakpoint.smAndUp"
      v-sticky
      :sticky-z-index="2"
      sticky-offset="{top: 80}"
  >
    <v-sheet class="pa-3 d-flex align-center Text01--text text-subtitle-2" color="SystemBG">
      {{ $t('filterBy') }}
    </v-sheet>

    <v-radio-group
        hide-details
        v-model="filters"
        mandatory
    >
      <template v-for="(availableFilter, index) in availableFilters">
        <v-radio :key="availableFilter.value"
                 :label="availableFilter.label"
                 :value="availableFilter.value"
                 :ripple="false"
        />
        <v-divider
            class="mb-4 mt-2"
            :key="`filter-divider-${index}`"
        />
      </template>

    </v-radio-group>
  </div>
  <v-menu v-else min-width="100%" attach tile offset-y left>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          height="56"
          :ripple="false"
          class="justify-start NewsSkyBlue"
          color="NewsSkyBlue"
          depressed
          text
          block
          v-bind="attrs"
          v-on="on"
      >
        <div class="text-subtitle-2 text-capitalize d-flex w-100">
          <span class="Text01--text">{{ $t('filterBy') }}</span>

          <v-spacer/>

          <span class="SystemCTA--text" :class="$vuetify.rtl ? 'ml-3' : 'mr-3'">{{ currentLabel }}</span>

          <v-icon color="SystemCTA">mdi-chevron-down</v-icon>

        </div>
      </v-btn>
    </template>
    <v-card tile elevation="0">
      <v-card-text class="px-0">
        <v-radio-group
            class="mt-0"
            hide-details
            v-model="filters"
            mandatory
        >
          <template v-for="(availableFilter, index) in availableFilters">

            <v-radio :key="availableFilter.value"
                     :label="availableFilter.label"
                     :value="availableFilter.value"
                     :ripple="false"
                     :class="$vuetify.rtl ? 'pl-3 pr-2' : 'pr-3 pl-2'"
            />

            <v-divider
                class="mb-4 mt-2"
                v-if="index < availableFilters.length - 1"
                :key="index"
            />
          </template>

        </v-radio-group>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import {EventFilterType, EventActions, EventGetters, EventMutations} from "@/store/event";
import {mapActions} from "vuex";

export default {
  name: "EventFilters",

  data() {
    return {
      filterMenu: false,
      availableFilters: [
        {value: EventFilterType.FilterNew, label: this.$t('eventsFilterNew')},
        {value: EventFilterType.FilterUpdated, label: this.$t('eventsFilterUpdated')},
        {value: EventFilterType.FilterDeleted, label: this.$t('eventsFilterDeleted')},
        {value: EventFilterType.FilterAll, label: this.$t('eventsFilterAll')},
      ]
    }
  },

  methods: {
    ...mapActions({
      fetchEvents: `event/${EventActions.FetchEvents}`,
    })
  },

  computed: {
    [EventGetters.Filters]: {
      get() {
        return this.$store.getters[`event/${EventGetters.Filters}`];
      },
      set(value) {
        this.$store.commit(`event/${EventMutations.SetFilters}`, value);
      }
    },

    currentLabel() {
      const item = this.availableFilters.find(item => item.value === this.filters);

      return item ? item.label : null;
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .v-radio {
  .v-input--selection-controls__input {
    order: 2;
    margin-right: 0;
  }

  .v-label {
    order: 1;
    font-size: 16px;
    color: #0A1F44;
    margin-left: 10px;
  }
}

.v-menu {
  ::v-deep .v-menu__content {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);

    .v-list-item {
      min-height: 56px;
    }
  }
}

.v-btn {
  &.justify-start {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
  }

  &[aria-expanded="true"] {
    ::v-deep .v-icon {
      transform: rotate(-180deg);
    }
  }

  ::v-deep .v-btn__content {
    max-width: 100%;
  }

  ::v-deep .v-icon {
    transition: .3s cubic-bezier(.25, .8, .5, 1), visibility 0s;
  }
}
</style>