export const ErrorsMutations = {
    SetErrors: 'setErrors',
    SetError: 'setError',
};

export const ErrorsGetters = {
    Errors: 'errors',
    Error: 'error',
};

const initialState = () => ({
    validationErrors: {},
    error: null
});

const state = initialState();

const getters = {
    [ErrorsGetters.Errors](state) {
        return state.validationErrors;
    },
    [ErrorsGetters.Error](state) {
        return state.error;
    },
};

const mutations = {
    [ErrorsMutations.SetErrors](state, payload) {
        state.validationErrors = payload;
    },
    [ErrorsMutations.SetError](state, payload) {
        state.error = payload;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
};