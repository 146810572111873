<template>
  <v-row>
    <v-col>
      <div class="main-content-container">
        <v-list two-line class="internal-content-container">
          <SkeletonLoader v-if="loading"/>

          <template v-else>
            <news-list-item
                v-for="newsItem in pinnedNewsItems"
                :newsItem="newsItem" :key="newsItem.id"
                @unHideNewsItem="unHideNewsItem"
                @togglePinNewsItem="togglePinNewsItem"
                :is-hidden="false"
                :is-pinned="true"
            />
            <infinite-loading :identifier="infiniteId" @infinite="loadPinnedNews">
              <template v-slot:spinner>
                <SkeletonLoader/>
              </template>
              <template v-slot:no-results>
                <v-card class="py-16">
                  <v-card-text class="d-flex flex-column align-center ">
                    <v-img
                        max-width="250"
                        src="/images/empty-news.svg"
                    />
                    <v-card-title class="mt-8">{{ $t('empty_list') }}</v-card-title>
                  </v-card-text>
                </v-card>
              </template>
            </infinite-loading>
          </template>
        </v-list>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {NewsActions} from '@/store/news';
import {UserActions} from "@/store/user";
import NewsListItem from '@/components/pages/NewsListPage/components/NewsListItem';
import {GlobalActions, GlobalGetters} from "@/store/global";

export default {
  name: "PinnedNewsListPage",

  components: {
    NewsListItem,
  },
  mounted() {
    this.initFetchMembers();
  },
  data() {
    return {
      loading: true,
      offset: 0,
      infiniteId: +new Date(),
      pinnedNewsItems: [],
    }
  },
  computed: {
    ...mapGetters({
      query: `global/${GlobalGetters.Query}`,
    }),
  },
  watch: {
    query() {
      this.resetList();
    },
  },
  methods: {
    ...mapActions({
      fetchPinnedNews: `news/${NewsActions.FetchPinnedNews}`,
      fetchMembers: `user/${UserActions.FetchMembers}`,
      updateQuery: `global/${GlobalActions.UpdateQuery}`
    }),
    async initFetchMembers() {
      this.fetchMembers();
      this.loading = false;
    },
    loadPinnedNews($state) {
      this.fetchPinnedNews({
        offset: this.offset,
        query: this.query,
      }).then((data) => {
        if (data.length > 0) {
          this.offset++;
          this.pinnedNewsItems.push(...data);
          $state.loaded();
        } else {
          $state.complete();
        }
      });
    },
    resetList() {
      this.pinnedNewsItems = [];
      this.offset = 0;
      this.infiniteId += 1;
    },
    unHideNewsItem(newsId) {
      this.removeNewsItemAndResetList(newsId);
    },
    togglePinNewsItem({id, isPinned}) {
      if (isPinned) {
        return;
      }

      this.removeNewsItemAndResetList(id);
    },
    removeNewsItemAndResetList(newsId) {
      const newsItemIndex = this.pinnedNewsItems.findIndex(newsItem => newsItem.id === newsId);
      if (newsItemIndex > -1) {
        this.pinnedNewsItems.splice(newsItemIndex, 1);
        if (this.pinnedNewsItems.length === 0) {
          this.resetList();
        }
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.updateQuery('');
    next();
  }
}
</script>

<style scoped/>