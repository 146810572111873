<template>
  <div>
    <absent-header :sickListEntry="event" v-if="!prevHasSameMonth && !isCalendarView"/>

    <div v-if="!prevHasSameMonth && !isCalendarView" class="sicklist-image mb-4">
      <div class="text-subtitle-1 calendarRichBlack--text text-capitalize">{{ event.start | date('MMMM') }}</div>
      <v-img :eager="true" :src="`/images/calendar/${currentMonth}.svg`"/>
    </div>

    <v-row no-gutters>
      <v-col cols="2" sm="1" v-if="!isCalendarView">
        <v-chip v-if="!prevHasSameDay" label x-large class="my-1 px-0 event-day-chip" color="transparent">
          <div class="d-flex flex-column align-center w-100 calendarRichBlack--text">
            <span class="text-subtitle-1">{{ event.eventDay | date('ddd') }}</span>
            <span class="text-subtitle-1">{{ event.eventDay | date('D') }}</span>
          </div>
        </v-chip>
      </v-col>

      <v-col :cols="isCalendarView ? 12 : 10" :sm="isCalendarView ? 12 : 11">
        <v-list :class="$vuetify.rtl ? 'ml-0' : 'mr-0'" two-line class="flex-grow-1 align-self-sm-center pt-0 my-1 w-100">
          <v-card
              class="w-100 event-card"
              rounded="4"
              v-ripple="{ class: 'calendarRichBlack--text' }"
              :style="{ background: eventBgColor }"
              :to="{name:'events.show', params: {id: event.id}}"
          >
            <div class="d-flex w-100">

              <div class="event-type-bar" :style="{background: eventBarColor}"/>
              <v-list-item three-line class="w-100" :class="$vuetify.rtl ? 'pr-4 pl-2' : 'pl-4 pr-2'">
                <v-list-item-content>
                  <event-chips :event-item="event"/>

                  <div v-if="event.groups.length" class="text-body-2 calendarRichBlack--text mb-2 text-truncate">
                    {{ event.groups.map(group => group.name).join(" • ") }}
                  </div>

                  <v-list-item-title
                      class="text-subtitle-1 calendarRichBlack--text mb-1"
                      :class="{'text-decoration-line-through': event.deleted}"
                  >
                    {{ event.title }}
                  </v-list-item-title>

                  <v-flex class="d-flex align-baseline my-1 w-100">
                    <span
                        class="calendarRichBlack--text text-body-2 text-no-wrap"
                    >
                      {{ date }}
                    </span>

                    <span
                        :class="$vuetify.rtl ? 'mr-2' : 'ml-2'"
                        v-if="event.venue"
                        class="Text02--text text-body-2 text-truncate"
                    >
                      | {{ event.venue }}
                      </span>
                  </v-flex>

                </v-list-item-content>

                <v-list-item-action>
                  <v-menu tile offset-y v-if="!event.deleted">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          v-on:click.prevent
                          v-bind="attrs"
                          v-on="on"
                          color="calendarRichBlack"
                          icon
                      >
                        <v-icon>mdi-calendar-plus</v-icon>
                      </v-btn>
                    </template>
                    <v-list class="py-0">
                      <v-list-item @click.stop.prevent="addToAppleCalendar">
                        <v-list-item-icon :class="$vuetify.rtl ? 'ml-3' : 'mr-3'">
                          <v-img src="/images/apple-icon.svg"/>
                        </v-list-item-icon>
                        <v-list-item-title class="Text01--text text-body-2">{{ $t('appleCalendar') }}</v-list-item-title>
                      </v-list-item>

                      <v-divider/>

                      <a :href="googleCalendarLink"
                         target="_blank"
                         class="v-list-item v-list-item--link theme--light">
                        <v-list-item-icon :class="$vuetify.rtl ? 'ml-3' : 'mr-3'">
                          <v-img src="/images/google-icon.svg"/>
                        </v-list-item-icon>
                        <v-list-item-title class="Text01--text text-body-2">
                          {{ $t('googleCalendar') }}
                        </v-list-item-title>
                      </a>
                    </v-list>
                  </v-menu>
                </v-list-item-action>
              </v-list-item>
            </div>
          </v-card>
        </v-list>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from 'moment';
import {EventsViewType} from "@/store/event";
import EventChips from "@/components/pages/EventsListPage/components/EventChips";
import AbsentHeader from "@/components/pages/AbsentEntriesListPage/components/AbsentHeader";
import {
  bgColorByIndex,
  cancelledColor,
  dotColorByIndex
} from "@/components/pages/EventsListPage/components/EventColors";
import {generateAppleCalendarIcsContent, generateGoogleCalendarLink} from "@/helpers/calendars";
import {downloadBlob} from "@/helpers/helpers";

export default {
  name: "EventsListItem",

  components: {
    EventChips,
    AbsentHeader,
  },

  methods: {
    addToAppleCalendar() {
      let icsContent = generateAppleCalendarIcsContent(this.event);
      downloadBlob(icsContent, 'ext/calendar', this.event.title + '.ics')
    },
  },

  props: {
    event: {
      type: Object,
      required: true
    },
    prevEvent: {
      required: true,
      validator: (value) => typeof value === 'object' || value === undefined
    },
    viewType: {
      type: String,
      required: false,
    },
  },

  computed: {
    currentMonth() {
      return moment(this.event.start).locale('en').format('MMMM');
    },

    googleCalendarLink() {
      return generateGoogleCalendarLink(this.event);
    },

    isCalendarView() {
      return this.viewType && this.viewType === EventsViewType.Calendar;
    },

    prevHasSameMonth() {
      if (!this.prevEvent) {
        return false;
      }

      return moment.utc(this.event.start).isSame(moment.utc(this.prevEvent.start), 'month');
    },

    prevHasSameDay() {
      if (!this.prevEvent) {
        return false;
      }

      return moment.utc(this.event.start).isSame(moment.utc(this.prevEvent.start), 'day');
    },

    eventBgColor() {
      if (this.event.deleted) {
        return cancelledColor();
      }
      return bgColorByIndex(this.event.colour);
    },

    eventBarColor() {
      if (this.event.deleted) {
        return cancelledColor();
      }
      return dotColorByIndex(this.event.colour);
    },

    date() {
      if (this.event.allDay) {
        return this.$t('eventAllDay');
      }
      return moment.utc(this.event.start).format(this.$t('normalTimeFormat'))
          + ' - '
          + moment.utc(this.event.end).format(this.$t('normalTimeFormat'));
    },

    eventPoster() {
      return '<strong>' + this.event.poster + '</strong>';
    },

    showPostedBy() {
      return (this.event.poster && this.event.poster.length > 0);
    }
  }
}
</script>

<style scoped lang="scss">
.sicklist-image {
  position: relative;

  .text-subtitle-1 {
    position: absolute;
    z-index: 1;
    top: 20px;
    left: 20px;
  }
}
.v-sheet {
  .v-card {
    box-shadow: none;
  }
}

.event-type-bar {
  width: 8px;
  min-width: 8px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
</style>