<template>
  <v-layout class="white" justify-center>
    <slot/>
  </v-layout>
</template>

<script>

export default {
  name: "BlankPageLayout"
}
</script>