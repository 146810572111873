<template>
  <v-card tile elevation="0" class="py-16">
    <v-card-text class="d-flex flex-column align-center">
      <v-card-title class="text-subtitle-1 Text02--text pb-2 text-break text-center">
        {{title}}
      </v-card-title>
      <v-card-title v-if="subtitle" class="text-body-1 Text03--text pt-2 text-break text-center">
        {{subtitle}}
      </v-card-title>

      <v-img class="mt-8" width="310" :src="image"/>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "EmptyState",

  props: {
    title: {
      required: true,
      type: String
    },
    subtitle: {
      required: false,
      type: String,
      default: null
    },
    image: {
      required: true,
      type: String
    },
  },
}
</script>