<template>
  <div class="mb-3">
    <div class="mb-2" v-if="readonly">
      <answer-header
          :children-names="responseChildrenNames"
          :response="response"
          :news-item="newsItem"
          v-if="hasResponse"
      />
    </div>

    <div class="text-subtitle-1 Text01--text" v-else-if="!isLimitReached">
      {{ $t('newsResponseInstSingleOption') }}
    </div>

    <p class="text-subtitle-1 Text01--text mt-5" v-if="hasResponse">
      {{ $t('newsResponseInstSingleOptionAnswered') }}
    </p>

    <v-radio-group
        v-model="mutableValue"
        :error-messages="fieldErrors"
        :class="{'direction-rtl': isRTL}"
        hide-details="auto"
    >
      <v-row no-gutters :class="{'has-response': hasResponse}">
        <v-col
            :class="{'selected': mutableValue === option.id}"
            cols="12"
            v-for="(option, index) in newsItem.options"
            :key="index"
        >
          <v-radio
              :value="option.id"
              class="mt-0 options-radio"
              :class="{'rtl': $vuetify.rtl || isRTL}"
              :disabled="isDisabled(option)"
          >
            <template v-slot:label>
              <span class="text-body-1 Text01--text" :class="$vuetify.rtl ? 'text-right' : 'text-left'">
                {{ option.text }}
              </span>
            </template>
          </v-radio>

          <div :class="$vuetify.rtl ? 'ml-8' : 'mr-8'" class="mb-4" v-if="option.limited && !readonly">
            <v-progress-linear
                background-color="grey"
                height="6"
                :color="getProgressColor(option)"
                rounded
                :value="getProgressValue(option)"
            />
            <div class="mt-2">
              <span class="Text01--text text-body-2">
                {{ $t('contributed', {options_chosen: getChoicesDone(option)}) }}
              </span>
              •
              <span class="Text01--text text-body-2 font-weight-medium">
                {{ $t('still_needs_webapp', {options_available: option.max_choices - getChoicesDone(option)}) }}
              </span>
            </div>
          </div>

          <v-divider class="my-3" v-if="index < newsItem.options.length - 1" :key="option.id"/>
        </v-col>
      </v-row>
    </v-radio-group>
  </div>
</template>
<script>
import CheckboxOptions from "@/components/pages/NewsItemPage/components/CheckboxOptions";
import AnswerHeader from "@/components/pages/NewsItemPage/components/AnswerHeader";
import {isInAppTranslationRequestedLangRTL} from "@/helpers/helpers";

export default {
  name: "RadioButtonsOptions",

  components: {
    AnswerHeader
  },

  extends: CheckboxOptions,

  props: {
    value: {
      required: true,
      validator: (value) => typeof value === 'string' || value === null
    },
  },

  data() {
    return {
      isRTL: false,
    }
  },

  mounted() {
    this.isRTL = isInAppTranslationRequestedLangRTL(this.$route.params.translationLang);
  },

  computed: {
    mutableValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      }
    }
  },

  methods: {
    getChoicesDone(optionItem) {
      let choicesDone = parseInt(optionItem.choices_done);
      if (this.value === optionItem.id) {
        choicesDone++;
      }
      return choicesDone;
    },
  }
}
</script>

<style scoped lang="scss">
.row {
  .options-radio {
    &.v-radio--is-disabled {
      ::v-deep {
        .v-input--selection-controls__input {
          display: none;
        }
      }
    }
  }

  &.has-response {
    > div {
      &:not(.selected) {
        display: none;
      }

      .v-radio {
        ::v-deep {
          .v-input--selection-controls__input {
            display: none;
          }
        }
      }

      hr {
        display: none;
      }
    }
  }
}

.v-radio {
  &.rtl {
    ::v-deep {
      .v-input--selection-controls__input {
        order: 2;
        margin-left: 0;
      }

      .v-label {
        order: 1;
        margin-right: 0;
      }
    }
  }

  ::v-deep {
    .v-input--selection-controls__input {
      order: 2;
      margin-right: 0;
    }

    .v-label {
      order: 1;
      margin-right: 10px;
    }
  }
}
</style>