<template>
  <v-banner
      color="SystemWhite"
      height="40"
      v-sticky
      :sticky-z-index="2"
      on-stick="onStick"
      :class="{sticked}"
  >
    <div class="d-flex align-center">
      <div class="Text01--text text-subtitle-1">
        {{ sickListEntry.start | date('MMMM, YYYY') }}
      </div>
      <v-spacer/>
      <v-btn color="SystemCTA" icon @click="changeView">
        <v-icon>
          {{isListView ? 'mdi-calendar' : 'mdi-view-list'}}
        </v-icon>
      </v-btn>
    </div>
  </v-banner>
</template>

<script>
import EventBus from '@/services/EventBus';

export default {
  name: "AbsentHeader",

  data() {
    return {
      sticked: false,
      isListView: true
    }
  },

  methods: {
    onStick({sticked}) {
      this.sticked = sticked;
    },

    changeView() {
      this.isListView = !this.isListView;

      EventBus.$emit('isListView', this.isListView);
    }
  },

  props: {
    sickListEntry: {
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.v-banner {
  visibility: hidden;

  &.sticked {
    visibility: visible;
    top: 66px !important;
  }

  ::v-deep .v-banner__wrapper {
    padding: 2px 0 2px 0;
    border-bottom: none !important;
  }
}
</style>