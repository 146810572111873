<script>
import {prettyChildrenNames} from "@/helpers/childrenConditions";
import {mapGetters} from "vuex";
import {UserGetters} from "@/store/user";
import validationMixin from "@/mixins/validationMixin";

export default {
  mixins: [validationMixin],
  props: {
    newsItem: {
      required: true,
      type: Object
    },
    readonly: {
      required: false,
      default: false
    },
    response: {
      required: false,
      default: () => {
      }
    },
    validationResult: {
      required: false,
    }
  },
  computed: {
    ...mapGetters({
      members: `user/${UserGetters.Members}`,
    }),
    responseChildrenNames() {
      return this.hasResponse ? prettyChildrenNames(this.members, this.response.member_ids) : '';
    },
    hasResponse() {
      return typeof this.response !== "undefined";
    },

    isLimitReached() {
      const {options} = this.newsItem;

      let filteredOptions = options.filter((option) => {
        const {limited, choices_done, max_choices} = option;

        return limited && (choices_done === max_choices);
      });

      return filteredOptions.length === options.length;
    }
  },
  methods: {
    getAvatarSrc(child) {
      return `/images/avatars/avatars${child.avatar}.png`;
    },
  }
}
</script>