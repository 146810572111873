<template>
  <v-row :class="[$vuetify.breakpoint.smAndDown ? '' : 'h-100']">
    <v-col cols="12" md="7" order="2" order-md="1" :class="$vuetify.rtl ? 'ml-0 ml-md-auto' : 'mr-0 mr-md-auto'">
      <v-toolbar
          :class="[mutableViewType === 'schedule' ? 'mb-n7 mt-7 mt-md-0' : '']"
          dense
          flat
      >
        <v-toolbar-title class="text-subtitle-1 Text01--text" v-if="mutableViewType === 'calendar'">
          {{ selectedDay | date($t("dateDayFullDate")) }}
        </v-toolbar-title>

        <v-toolbar-title class="text-subtitle-1 Text01--text" v-if="mutableViewType === 'schedule'">
          {{ selectedDay | date($t("dateMonthYear")) }}
        </v-toolbar-title>

        <v-spacer/>

        <v-btn color="SystemCTA" icon @click="mutableViewType = 'schedule'" v-if="mutableViewType === 'calendar'">
          <v-icon>
            mdi-view-list
          </v-icon>
        </v-btn>

        <v-btn color="SystemCTA" icon @click="mutableViewType = 'calendar'" v-if="mutableViewType === 'schedule'">
          <v-icon>
            mdi-calendar
          </v-icon>
        </v-btn>
      </v-toolbar>

      <div class="px-4" v-if="viewType === 'calendar'">
        <v-list two-line color="flex-grow-1 internal-content-container align-self-sm-center">
          <template v-if="selectedDayEvents.length > 0">
            <events-list-item
                v-for="(event, index) in selectedDayEvents"
                :key="index"
                :event="event"
                :prev-event="null"
                :view-type="viewType"
                type="image"
            />
          </template>
          <empty-state
              v-else
              :title="$t('no_event_title')"
              :subtitle="$t('no_event_subtitle')"
              image="/images/empty-events.svg"
          />
        </v-list>
      </div>

      <div sticky-container class="d-flex flex-column w-100 px-4 test" v-if="viewType === 'schedule'">
        <template v-if="selectedDayEvents.length > 0">

          <events-list-item
              v-for="(event, index) in selectedDayEvents" :key="index"
              :event="event"
              :prev-event="selectedDayEvents[index-1]"
              type="image"
          />

        </template>
        <empty-state
            class="mt-9"
            v-else
            :title="$t('no_event_title')"
            :subtitle="$t('no_event_subtitle')"
            image="/images/empty-events.svg"
        />
      </div>
    </v-col>

    <v-divider vertical class="order-md-2 d-none d-md-inline-flex"/>

    <v-col
        sticky-container
        cols="12"
        md="4"
        order="1"
        order-md="3"
        :class="$vuetify.rtl ? 'mr-0 mr-md-auto' : 'ml-0 ml-md-auto'"
    >
      <event-filters v-if="viewType === 'schedule'"/>

      <calendar-date-picker
          v-if="viewType === 'calendar'"
          v-model="selectedDay"
          :events="events"
          class="mb-md-4"
      />
    </v-col>

  </v-row>
</template>

<script>
import moment from 'moment';
import EventsListItem from '@/components/pages/EventsListPage/components/EventsListItem';
import CalendarDatePicker from '@/components/pages/EventsListPage/components/CalendarDatePicker';
import EmptyState from "@/components/EmptyState";
import EventFilters from "@/components/pages/EventsListPage/components/EventFilters";
import {mapGetters, mapActions, mapMutations} from 'vuex';
import {EventGetters, EventActions, EventsViewType, EventMutations, EventFilterType} from "@/store/event";
import EventBus from '@/services/EventBus';

export default {
  name: "EventsCalendar",

  props: {
    events: {
      type: Array
    }
  },

  mounted() {
    EventBus.$on('isListView', (value) => {
      this.mutableViewType = value ? 'calendar' : 'schedule';
    });
  },

  components: {
    EventsListItem,
    CalendarDatePicker,
    EventFilters,
    EmptyState,
  },

  data() {
    return {
      selectedDay: moment.utc(),
    }
  },

  computed: {
    ...mapGetters({
      viewType: `event/${EventGetters.ViewType}`
    }),

    mutableViewType: {
      get() {
        return this.viewType;
      },
      set(newValue) {
        this.updateFilters(EventFilterType.FilterAll);

        this.updateViewType(newValue);
      }
    },

    selectedDayEvents() {
      if (this.viewType === EventsViewType.Schedule) {
        let eventsToShow = [];
        const idsMap = new Map();

        this.events.forEach((day) => {
          day.events.forEach((event) => {
            if (!idsMap.has(event.id)) {
              idsMap.set(event.id, true);
              event.eventDay = moment.utc(day.date);
              eventsToShow.push(event);
            }
          })

        });

        return eventsToShow;
      }

      let day = this.events.find((day) => {
        const date = moment(day.date);

        return date.date() === this.selectedDay.date()
            && date.month() === this.selectedDay.month()
            && date.year() === this.selectedDay.year();
      });

      if (day) {
        return day.events || [];
      }

      return [];
    },
  },
  methods: {
    ...mapActions({
      updateViewType: `event/${EventActions.UpdateViewType}`,
    }),

    ...mapMutations({
      updateFilters: `event/${EventMutations.SetFilters}`,
    })
  }
}
</script>