<template>
  <v-dialog
      v-model="dialog"
      :max-width="maxWidth"
      scrollable
      @click:outside="close"
      overlay-color="Text01"
      overlay-opacity="1"
  >
    <v-card :disabled="loading" :loading="loading" tile>
      <v-toolbar flat color="transparent">
        <span class="text-subtitle-1 Text01--text">{{ title }}</span>
        <v-spacer/>
        <v-btn :class="$vuetify.rtl ? 'ml-0' : 'mr-0'" icon color="Text03" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-divider/>

      <v-card-text v-if="groupedMembers.length > 0" ref="container" class="px-2">
        <v-list v-for="(memberObject, index) in groupedMembers" :key="index">
          <v-list-item>
            <span class="text-subtitle-1 Text01--text">{{ memberObject.title }}</span>
          </v-list-item>

          <v-list-item v-for="member in memberObject.members" :key="member._id" class="mb-3">
            <div class="response-avatar-container">
              <avatar
                  :initials="member.initials"
                  :src="member.avatar_field"
                  color="white"
                  backgroundColor="var(--v-AvatarsBlue-base)"
                  :style="`--borderColor: var(--v-${member.borderColor}-base)`"
                  class="border-color"
              />
              <v-btn
                depressed
                fab
                x-small
                :color="`${member.logoBackgroundColor}`"
                :style="`width:${member.btnSize}; height: ${member.btnSize};`"
              >
                <v-icon
                    :color="`${member.logoColor}`"
                    :size="`${member.iconSize}`"
                >
                  {{ member.logo }}
                </v-icon>
              </v-btn>
            </div>
            <v-list-item-content>
              <v-list-item-title class="ml-3">
                {{ member.first_name }} {{ member.last_name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider v-if="index !== groupedMembers.length - 1"/>

        </v-list>
      </v-card-text>

      <v-divider/>

      <v-card-actions class="justify-end px-6 py-4">
        <v-btn
            v-if="cancelButtonVisible"
            :min-width="73"
            color="primary"
            outlined
            @click="close"
        >
          {{ cancelButtonLabel || $t("okay") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";
import {UserGetters} from "@/store/user";
import ConfirmationModal from "@/components/ConfirmationModal"
import {isInTargetGroup} from "@/helpers/childrenConditions";
import {Avatar} from "vue-avatar";

export default {
  name: "ResponseMembersDialog",

  extends: ConfirmationModal,

  components: {
    Avatar
  },

  props: {
    newsItem: {
      required: true,
      type: Object
    },
    value: {
      type: Boolean,
    },
  },

  computed: {
    ...mapGetters({
      readableType: `user/${UserGetters.AppType}`,
      members: `user/${UserGetters.Members}`,
    }),

    filteredMembers() {
      return this.members.filter(member => this.isInTargetGroup(member));
    },

    membersAnsweredByCurrentUser() {
      return this.filteredMembers.filter(member => this.isAlreadyAnsweredByCurrentUser(member));
    },

    membersAnsweredBySomeoneElse() {
      return this.filteredMembers.filter(member => this.isAlreadyAnsweredBySomeoneElse(member));
    },

    membersAnswersRequired() {
      return this.filteredMembers.filter(member => (
          !this.isAlreadyAnsweredByCurrentUser(member) &&
          !this.isAlreadyAnsweredBySomeoneElse(member) &&
          !this.newsItem.is_response_no_longer_possible
      ));
    },

    membersAnswersNoLongerPossible() {
      return this.filteredMembers.filter(member => (!this.isAlreadyAnsweredByCurrentUser(member) && this.newsItem.is_response_no_longer_possible));
    },

    groupedMembers() {
      const answeredByCurrentUser = {
        logo: 'mdi-check-all',
        logoColor: 'SystemWhite',
        logoBackgroundColor: 'LabelGreen',
        borderColor: 'LabelGreen',
        btnSize: '20px',
        iconSize: '16'
      };

      const answeredBySomeoneElse = {
        logo: 'mdi-check-decagram',
        logoColor: 'LabelYellow',
        logoBackgroundColor: 'LabelBlack',
        borderColor: 'LabelYellow',
        btnSize: '18px',
        iconSize: '25'
      };

      const answerRequired = {
        logo: 'mdi-alert',
        logoColor: 'LabelBlack',
        logoBackgroundColor: 'LabelYellow',
        borderColor: 'LabelYellow',
        btnSize: '20px',
        iconSize: '15'
      };

      const answerNoLongerPossible = {
        logo: 'mdi-alert-remove-outline',
        logoColor: 'SystemWhite',
        logoBackgroundColor: 'Text03',
        borderColor: 'Text03',
        btnSize: '20px',
        iconSize: '16'
      };

      let groupedMembers = [];
      if (this.membersAnsweredByCurrentUser.length) {
        groupedMembers.push({
          title: this.$t('newsResponseReceived'),
          members: this.membersAnsweredByCurrentUser.map(member => Object.assign({}, member, answeredByCurrentUser))
        });
      }

      if (this.membersAnswersRequired.length) {
        groupedMembers.push({
          title: this.$t('responseRequired'),
          members: this.membersAnswersRequired.map(member => Object.assign({}, member, answerRequired))
        });
      }

      if (this.membersAnsweredBySomeoneElse.length) {
        groupedMembers.push({
          title: this.$t('newsAnotherParentReplied'),
          members: this.membersAnsweredBySomeoneElse.map(member => Object.assign({}, member, answeredBySomeoneElse))
        });
      }

      if (this.membersAnswersNoLongerPossible.length) {
        groupedMembers.push({
          title: this.$t('newsResponseNoLongerPossible'),
          members: this.membersAnswersNoLongerPossible.map(member => Object.assign({}, member, answerNoLongerPossible))
        });
      }

      return groupedMembers;
    },
  },
  methods: {
    isAlreadyAnsweredByCurrentUser(member) {
      return this.newsItem.answeredMemberIds?.includes(member.id);
    },

    isAlreadyAnsweredBySomeoneElse(member) {
      return this.newsItem.answeredMemberOtherIds?.includes(member.id);
    },

    isInTargetGroup(member) {
      return isInTargetGroup(member, this.newsItem.groups);
    }
  }
}
</script>

<style lang="scss" scoped>
.response-avatar-container {
  position: relative;

  .v-btn {
    position: absolute;
    align-items: baseline;
    cursor: auto;
    bottom: 2px;
    right: 2px;
  }

  .border-color {
    border: 3px solid var(--borderColor) !important;
  }
}
</style>