<template>
  <v-dialog
      v-model="dialog"
      scrollable
      fullscreen
  >
    <v-card color="rgba(6, 31, 75, 0.8)" tile elevation="0" @click="close" :ripple="false">
      <v-app-bar height="66" flat color="transparent" class="px-md-5">
        <v-btn color="SystemWhite" icon @click.stop.prevent="close">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-spacer/>

        <v-toolbar-title class="text-subtitle-1 SystemWhite--text">
          {{ title }}
        </v-toolbar-title>

        <v-spacer/>

        <v-btn color="SystemWhite" icon @click.stop.prevent="download()">
          <v-icon>mdi-download</v-icon>
        </v-btn>

        <v-btn color="SystemWhite" icon @click.stop.prevent="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>


      <v-card-text style="max-width: 800px;" class="align-self-center pa-0" @click.stop.prevent>
        <img :alt="title" width="100%" :src="imgURL"/>
      </v-card-text>

    </v-card>
  </v-dialog>
</template>

<script>

import {downloadBlob} from "@/helpers/helpers";

export default {
  props: {
    value: {
      type: Boolean,
    },
    title: {
      type: String
    },
    imageData: {
      type: ArrayBuffer
    },
    imageType: {
      type: String
    }
  },
  computed: {
    dialog: {
      get: function () {
        return this.value;
      },
      set: function (newValue) {
        if (!newValue) {
          this.overlay = true;
        }

        this.$emit('input', newValue);
      }
    },
    imgURL() {
      const image = this.convertArrayBufferToImageUrl(this.imageData);
      return "data:" + this.imageType + ";base64," + image;
    }
  },
  methods: {
    download() {
      downloadBlob(this.imageData, this.imageType, this.title)
    },

    convertArrayBufferToImageUrl(arrayBuffer) {
      return btoa(new Uint8Array(arrayBuffer).reduce(function (data, byte) {
        return data + String.fromCharCode(byte);
      }, ''));
    },

    close() {
      this.$emit('close');
      this.dialog = false;
    },
  }
}
</script>
