<template>
  <v-sheet
    v-if="shouldDisplay"
    class="ml-2"
    color="GreyLine"
    outlined
  >
    <v-sheet class="body-2 px-1 d-flex justify-center text-decoration-line-through Text01--text">
      {{ text }}
    </v-sheet>
  </v-sheet>
</template>

<script>
export default {
  name: "SquareText",

  props: {
    shouldDisplay: {
      type: Boolean,
      default: true,
    },
    text: {
      type: String,
      default: '',
    },
  },
};
</script>
