<template>
  <div class="mt-15 mt-md-0">
    <v-sheet
        tile
        height="40"
        class="d-flex align-center mb-6"
    >
      <v-toolbar flat>
        <v-btn
            icon
            color="SystemCTA"
            @click="toPrev"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <v-toolbar-title class="Text01--text text-subtitle-1 px-3">
          {{ selectedDay.format('MMMM, YYYY') }}
        </v-toolbar-title>

        <v-btn :class="$vuetify.rtl ? 'mr-2' : 'ml-2'" small color="SystemCTA" icon v-if="renderType === 'week'"
               @click="setRenderType('month')">
          <v-icon>mdi-menu-down</v-icon>
        </v-btn>

        <v-btn :class="$vuetify.rtl ? 'mr-2' : 'ml-2'" small color="SystemCTA" icon v-else
               @click="setRenderType('week')">
          <v-icon>mdi-menu-up</v-icon>
        </v-btn>

        <v-btn
            icon
            color="SystemCTA"
            @click="toNext"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>

        <v-spacer/>

        <v-btn
            :class="$vuetify.rtl ? 'ml-2 ml-sm-0' : 'mr-2 mr-sm-0'"
            color="SystemCTA"
            icon
            @click="changeView"
        >
          <v-icon>
            {{ isListView ? 'mdi-calendar' : 'mdi-view-list' }}
          </v-icon>
        </v-btn>
      </v-toolbar>
    </v-sheet>

    <div class="d-flex flex-row align-center justify-space-between px-2">

      <div class="w-100">
        <div class="d-flex flex-row mb-4" key="weekDaysTitles">
          <v-flex class="d-flex flex-basis-0 justify-center text-subtitle-2 Text01--text"
                  v-for="(weekDay, i) in weekDays" :key="'weekTitle_' + i">
            {{ weekDay }}
          </v-flex>
        </div>
        <div class="d-flex flex-row mb-2" v-for="(week, i) in days" :key="getWeekKey(week, i)">
          <v-flex class="d-flex flex-basis-0 justify-center" v-for="(day, j) in week"
                  :key="getWeekKey(week, i) + '_' + j">
            <div class="d-flex flex-column" v-if="day">
              <v-btn
                  depressed
                  class="event-day-picker-button v-btn--round"
                  :color="isToday(day) && !isSelectedDay(day) ? 'IconsLightBlue' : 'primary'"
                  :text="!isSelectedDay(day) && !isToday(day)"
                  @click="setSelectedDay(day)"
              >
                <div class="text-body-1" :class="{'Text01--text': !isSelectedDay(day)}">
                  {{ day.format('D') }}
                </div>
              </v-btn>
              <div class="d-flex flex-row justify-center mt-2">
                <div
                    v-for="(eventColour, k) in getEventsDotsPerDay(day)"
                    :key="getWeekKey(week, i) + '_' + j + '_' + k"
                    :style="{background: eventColour}"
                    class="event-dot"
                />
              </div>
            </div>
          </v-flex>
        </div>
      </div>

    </div>

    <v-list class="mt-md-5" three-line v-if="items.length">
      <v-list-item class="NewsSkyBlue mb-3" v-for="sickListEntry in items" :key="sickListEntry.id">
        <v-list-item-content>
          <v-list-item-title class="text-body-2 Text02--text">
            {{ date(sickListEntry) }}
          </v-list-item-title>
          <v-list-item-subtitle v-if="sickListEntry.description" class="text-body-1 Text01--text mt-2">
            {{ sickListEntry.description }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action v-if="showEdit(sickListEntry) && sickListEntry.user_is_creator">
          <v-menu min-width="220" tile offset-y offset-x>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list class="py-0">
              <v-list-item @click="initEditAbsent(sickListEntry)">
                <v-list-item-icon :class="$vuetify.rtl ? 'ml-3' : 'mr-3'">
                  <v-icon size="25" color="SystemCTA">mdi-pencil</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
              </v-list-item>
              <v-divider/>
              <v-list-item @click="initDeleteConfirm(sickListEntry)">
                <v-list-item-icon :class="$vuetify.rtl ? 'ml-3' : 'mr-3'">
                  <v-icon size="25" color="AlertRed">mdi-delete</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item-action>
      </v-list-item>
    </v-list>

    <empty-state
        v-else
        class="pt-0 pb-0"
        :title="$t('no_absent_entries_title')"
        :subtitle="$t('no_absent_entries_subtitle')"
        image="/images/empty-absent-entries.svg"
    />

    <confirmation-modal
        :max-width="544"
        :loading="submitLoading"
        v-model="deleteConfirmationDialog"
        :title="$t('deleteAbsentMessage')"
        :label="$t('confirmInfo')"
        :apply-button-label="$t('delete')"
        apply-button-color="error"
        @apply="initDeleteAbsentEntry"
    />
  </div>
</template>

<script>
import EventBus from '@/services/EventBus';
import moment from "moment";
import {mapActions, mapGetters} from "vuex";
import {MemberGetters} from "@/store/member";
import {SickListActions} from "@/store/sicklist";
import EmptyState from "@/components/EmptyState";
import ConfirmationModal from "@/components/ConfirmationModal";
import absenceMixin from "@/mixins/absenceMixin";

export default {
  name: "AbsentCalendar",

  components: {
    EmptyState,
    ConfirmationModal,
  },

  mixins: [absenceMixin],

  async mounted() {
    await this.loadSickListEntries();

    EventBus.$on('isAbsenceListChanged', async () => {
      await this.loadSickListEntries();
    });

    if (this.$vuetify.breakpoint.smAndDown) {
      this.renderType = 'week';
    }
  },

  computed: {
    ...mapGetters({
      member: `member/${MemberGetters.Member}`,
    }),

    selectedMonth() {
      return this.selectedDay.month() + 1;
    },

    items() {
      return this.sickListEntries.filter((item) => {
        const {start, end} = item;

        return this.enumerateDaysBetweenDates(start, end).includes(this.selectedDay.format('YYYY-MM-DD'));
      });
    },

    weekDays() {
      return [this.$t('mon'), this.$t('tue'), this.$t('wed'), this.$t('thu'), this.$t('fri'), this.$t('sat'), this.$t('sun')];
    },

    days() {
      let days = [];

      if (this.renderType === 'week') {
        let day = moment(this.selectedDay).startOf('isoWeek');
        let week = [];
        for (let i = 0; i < 7; i++) {
          week.push(day.clone());
          day.add(1, 'day');
        }
        days.push(week);

        return days;
      }

      let day = this.selectedDay.clone().startOf('month');
      let selectedMonth = day.month();
      while (selectedMonth === day.month()) {
        let preFilledCells = day.isoWeekday() - 1;
        let week = new Array(preFilledCells);
        week.fill(null);
        let weekNumber = day.isoWeek();
        while (weekNumber === day.isoWeek() && selectedMonth === day.month()) {
          week.push(day.clone());
          day.add(1, 'day');
        }
        if (selectedMonth !== day.month()) {
          let extraCells = new Array(7 - week.length);
          extraCells.fill(null);
          week.push(...extraCells);
        }
        days.push(week);
      }

      return days;
    }
  },

  watch: {
    async selectedMonth() {
      await this.loadSickListEntries();
    }
  },

  methods: {
    ...mapActions({
      fetchSickListEntries: `sicklist/${SickListActions.FetchSickListEntries}`,
      deleteAbsentEntry: `sicklist/${SickListActions.DeleteAbsentEntry}`
    }),

    initEditAbsent(sickListEntry) {
      this.$emit('editAbsentEntry', sickListEntry);
    },

    initDeleteConfirm(sickListEntry) {
      this.deleteConfirmationDialog = true;
      this.sickListEntry = sickListEntry;
    },

    isStarted(sickListEntry) {
      return moment.utc(sickListEntry.start).isBefore(moment.utc());
    },

    isToday(day) {
      return day.isSame(moment(), 'day');
    },

    isSelectedDay(day) {
      return day.isSame(this.selectedDay, 'day');
    },

    setSelectedDay(day) {
      this.selectedDay = day.clone();
    },

    setRenderType(type) {
      this.renderType = type;
    },

    getWeekKey(week, index) {
      for (let i = 0; i < week.length; i++) {
        if (week[i]) {
          return week[i].isoWeek();
        }
      }
      return index;
    },

    toPrev() {
      const accuracy = this.renderType === 'month' ? 'month' : 'isoWeek';
      this.selectedDay = this.selectedDay.clone().subtract(1, accuracy);
    },

    toNext() {
      const accuracy = this.renderType === 'month' ? 'month' : 'isoWeek';
      this.selectedDay = this.selectedDay.clone().add(1, accuracy);
    },

    getEventsDotsPerDay(requestedDay) {
      if (!this.sickListEntries) {
        return [];
      }

      let dayWithAbsences = this.sickListEntries.filter((day) => {
        return this.enumerateDaysBetweenDates(day.start, day.end).includes(requestedDay.format('YYYY-MM-DD'));
      });

      return dayWithAbsences.map(() => "#1B44FF");
    },

    enumerateDaysBetweenDates(startDate, endDate) {
      let currDate = moment(startDate).startOf('day');
      let lastDate = moment(endDate).startOf('day');

      let dates = [currDate.clone().format('YYYY-MM-DD')];

      while (currDate.add(1, 'days').diff(lastDate) < 0) {
        dates.push(currDate.clone().format('YYYY-MM-DD'));
      }

      dates.push(lastDate.clone().format('YYYY-MM-DD'));

      return dates;
    },

    date({whole_day, start, end}) {
      start = moment.utc(start);
      end = moment.utc(end);

      if (whole_day) {
        if (start.isSame(end, 'day')) {
          return start.format(this.$t('normalDateFormat'));
        } else {
          return start.format(this.$t('normalDateFormat')) + ' - ' + end.format(this.$t('normalDateFormat'));
        }
      }

      if (start.isSame(end, 'day')) {
        return start.format(this.$t('normalTimeFormat')) + ' - ' + end.format(this.$t('normalTimeFormat'));
      }

      return start.format(this.$t('normalDateFormat') + ' ' + this.$t('normalTimeFormat'))
          + ' - '
          + end.format(this.$t('normalDateFormat') + ' ' + this.$t('normalTimeFormat'));
    },

    initDeleteAbsentEntry() {
      this.submitLoading = true;
      this.deleteAbsentEntry({
        memberId: this.$route.params.id,
        absentEntryId: this.sickListEntry.id
      }).then(async () => {
        this.$toasted.error(this.$t('absenceDeletedMessage'));
        this.submitLoading = false;
        this.deleteConfirmationDialog = false;
        this.sickListEntry = false;
        await this.loadSickListEntries();
      }).catch((e) => {
        this.$toasted.error(e.message);
        this.submitLoading = false;
      });
    },

    async loadSickListEntries() {
      this.fetchSickListEntries({
        memberId: this.member.id,
        year: this.selectedDay.year(),
        month: this.selectedMonth,
      }).then(({items}) => {
        items.forEach((item) => {
          item.colour = "#1B44FF";
        });

        this.sickListEntries = items;
      });
    },

    changeView() {
      this.isListView = !this.isListView;

      EventBus.$emit('isListView', this.isListView);
    }
  },

  data() {
    return {
      selectedDay: moment.utc(),
      renderType: 'month',
      isListView: false,
      sickListEntries: [],
      submitLoading: false,
      deleteConfirmationDialog: false,
      sickListEntry: null
    }
  }
}
</script>

<style lang="scss" scoped>
.v-toolbar {
  ::v-deep .v-toolbar__content {
    padding: 0;
  }
}
</style>