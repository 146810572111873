<template>
  <div class="d-flex flex-column">
    <template v-if="isAuthenticated">
      <a
          class="mb-2 text-body-1"
          href="#"
          v-if="Boolean(appUsersPrivacyPolicy)"
          @click.prevent="initFetchPdf('app_users_privacy_policy', appUsersPrivacyPolicy)"
      >
        {{ $t('privacy_policy_title') }}
      </a>
      <a
          target="_blank"
          class="mb-2 text-body-1"
          :href="globalPrivacyPolicyUrl"
          v-else-if="Boolean(globalPrivacyPolicyUrl)"
      >
        {{ $t('privacy_policy_title') }}
      </a>

      <a
          class="mb-2 text-body-1"
          href="#"
          v-if="Boolean(appUsersTermsAndConditions)"
          @click.prevent="initFetchPdf('app_users_terms_and_conditions', appUsersTermsAndConditions)"
      >
        {{ $t('termsConditions') }}
      </a>
      <a
          class="text-body-1"
          target="_blank"
          :href="globalTermsAndConditions"
          v-else-if="Boolean(globalTermsAndConditions)"
      >
        {{ $t('termsConditions') }}
      </a>
    </template>

    <template v-else>
      <a target="_blank" :class="$vuetify.rtl ? 'ml-sm-3' : 'mr-sm-3'" class="mb-3 mb-sm-0" :href="globalPrivacyPolicyUrl"
         v-if="Boolean(globalPrivacyPolicyUrl)">
        {{ $t('privacy_policy_title') }}
      </a>
      <a target="_blank" :href="globalTermsAndConditions" v-if="Boolean(globalTermsAndConditions)">
        {{ $t('termsConditions') }}
      </a>
    </template>

    <pdf-viewer-dialog
        v-model="pdfDialog"
        :title="pdfDialogTitle"
        :pdfData="pdfData"
    />

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {LegalActions} from "@/store/legalsDocuments";
import {UserGetters} from "@/store/user";
import PdfViewerDialog from "@/components/PdfViewerDialog";

export default {
  name: "PrivacyPolicyDialog",

  components: {
    PdfViewerDialog
  },

  data() {
    return {
      globalPrivacyPolicyUrl: null,
      globalTermsAndConditions: null,
      appUsersPrivacyPolicy: null,
      appUsersTermsAndConditions: null,
      pdfDialogTitle: null,
      pdfData: null
    }
  },

  async mounted() {
    await this.initLoadingPageData();
  },

  computed: {
    ...mapGetters({
      isAuthenticated: `user/${UserGetters.IsAuthenticated}`,
      associationMainId: `user/${UserGetters.AssociationMainId}`
    }),

    pdfDialog: {
      get() {
        return Boolean(this.pdfData);
      },
      set() {
        this.pdfData = null;
        this.pdfDialogTitle = null;
      }
    }
  },

  methods: {
    ...mapActions({
      fetchLegalItems: `legalsDocuments/${LegalActions.GetLegalLinks}`,
      getListOfLegalDocuments: `legalsDocuments/${LegalActions.GetListOfLegalDocuments}`,
      getPdf: `legalsDocuments/${LegalActions.GetPdf}`
    }),

    async initFetchPdf(type, name) {
      this.getPdf(type).then((data) => {
        this.pdfData = data;
        this.pdfDialogTitle = name;
      });
    },

    async initLoadingPageData() {
      await this.fetchLegalItems()
          .then(async ({data}) => {
            const {global_privacy_policy_url, global_terms_and_conditions} = data;

            this.globalPrivacyPolicyUrl = global_privacy_policy_url;
            this.globalTermsAndConditions = global_terms_and_conditions;
          })
          .catch(e => {
            this.$toasted.error(e.message);
          });

      if (this.associationMainId) {
        await this.getListOfLegalDocuments()
            .then(async ({data}) => {
              const {app_users_privacy_policy, app_users_terms_and_conditions} = data;

              if (app_users_privacy_policy) {
                const {name} = app_users_privacy_policy;

                this.appUsersPrivacyPolicy = name;
              }

              if (app_users_terms_and_conditions) {
                const {name} = app_users_terms_and_conditions;

                this.appUsersTermsAndConditions = name;
              }
            }).catch(e => {
              this.$toasted.error(e.message);
            });
      }
    }
  }
}
</script>
