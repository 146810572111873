import md5 from "crypto-js/md5";
import Vue from 'vue';
import { ClientsService } from '@/services/ApiServices';
import {AppConfig} from "@/helpers/AppConfig";

export default class AuthPayload {

    static generateApiPayload(publicToken, privateToken) {
        const timestamp = Math.floor(Date.now() / 1000);

        return {
            public: publicToken,
            access: md5(privateToken + timestamp).toString(),
            timestamp: timestamp
        }
    }
    static generateRequestAuthTokens() {
        const publicToken = Vue.cookie.get('public_token');
        const privateToken = Vue.cookie.get('private_token');

        return AuthPayload.generateApiPayload(publicToken, privateToken);
    }

    static async refreshTokens() {
        const {data: {clientpass}} = await ClientsService.getClientPass();
        const {data: {token1, token2}} = await ClientsService.getTokensForRefresh(clientpass);

        AuthPayload.setAuthCookies(token1, token2);
    }

    static setAuthCookies(publicToken, privateToken) {
        const expires = process.env.VUE_APP_SESSION_LIFETIME_MIN || 40;
        const expiresPublic = process.env.VUE_APP_REMEMBER_ME_LIFETIME_DAYS || 90;
        Vue.cookie.set('public_token', publicToken, {expires: expiresPublic + 'D', secure: AppConfig.productionMode, samesite: AppConfig.sameSiteCookieConfig});
        Vue.cookie.set('private_token', privateToken, {expires: expires + 'm', secure: AppConfig.productionMode, samesite: AppConfig.sameSiteCookieConfig});
    }

    static deleteAuthCookies() {
        Vue.cookie.delete('public_token');
        Vue.cookie.delete('private_token');
        Vue.cookie.delete('user_id');
        Vue.cookie.delete('association_id');
        Vue.cookie.delete('association_main_id');
        Vue.cookie.delete('remember_me_token');
        Vue.cookie.delete('encryption_secret');
    }
}