<template>
  <v-row>
    <v-col>
      <div class="main-content-container tight">
        <v-col cols="12" no-gutters v-for="timetable in timetables" :key="timetable.id">
          <v-card :loading="requestedTimetableId === timetable.id" tile outlined>
            <v-card-text class="pa-0">
              <v-list-item @click="openTimetablePdf(timetable.id, timetable.title)" v-ripple="{ class: 'SystemCTA--text' }">
                <v-list-item-icon :class="$vuetify.rtl ? 'ml-4' : 'mr-4'">
                  <v-img :alt="$t('view')" src="/images/pdf.svg"/>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-subtitle-1 Text01--text text-wrap">
                    {{ timetable.title }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-body-1 Text02--text">
                    {{ timetable.start|date($t('normalDateFormat')) }}
                    -
                    {{ timetable.end|date($t('normalDateFormat')) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card-text>
          </v-card>
        </v-col>

        <infinite-loading :identifier="infiniteId" @infinite="loadTimetables">
          <template v-slot:spinner>
            <SkeletonLoader/>
          </template>
          <template v-slot:no-results>
            <empty-state
                :title="$t('no_time_table_title')"
                :subtitle="$t('no_time_table_subtitle')"
                image="/images/empty-timetables.svg"
            />
          </template>
        </infinite-loading>

        <pdf-viewer-dialog
            v-model="pdfDialog"
            :title="pdfDialogTitle"
            :pdfData="pdfData"
            @close="requestedTimetableId = null"
        />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import PdfViewerDialog from "@/components/PdfViewerDialog";
import {UserGetters} from "@/store/user";
import {TimetablesActions} from "@/store/timetables";
import EmptyState from "@/components/EmptyState";

export default {
  name: "TimetablesPage",

  components: {
    PdfViewerDialog,
    EmptyState,
  },

  data() {
    return {
      loading: false,
      offset: 0,
      infiniteId: +new Date(),
      timetables: [],
      pdfDialog: false,
      confirmationDialog: false,
      pdfDialogTitle: null,
      pdfData: null,
      requestedTimetableId: null,
    }
  },

  mounted() {
    if (!this.showTimetable) {
      this.$router.replace({name: '404'});
    }
  },

  computed: {
    ...mapGetters({
      accountConfiguration: `user/${UserGetters.AccountConfiguration}`,
    }),

    showTimetable() {
      if (!this.accountConfiguration) {
        return false;
      }
      return this.accountConfiguration.showTimetable;
    }
  },

  methods: {
    ...mapActions({
      fetchTimetables: `timetables/${TimetablesActions.FetchTimetables}`,
      fetchTimetablePdf: `timetables/${TimetablesActions.FetchTimetablePdf}`,
    }),

    loadTimetables($state) {
      this.fetchTimetables(this.offset)
        .then((data) => {
          if (data.length > 0) {
            this.offset++;
            this.timetables.push(...data);
            $state.loaded();
          } else {
            $state.complete();
          }
        })
        .catch((e) => {
          if (e.message === 'Forbidden') {
            this.$router.replace({name: 'news'}, () => {
              this.$toasted.error(this.$t('accessToPageIsForbidden'));
            })
          } else {
              this.$toasted.error(e.message);
            }
        });
    },

    openTimetablePdf(timetableId, timetableTitle) {
      this.requestedTimetableId = timetableId;
      this.fetchTimetablePdf(timetableId)
          .then((data) => {
            if (timetableId === this.requestedTimetableId) {
              this.pdfData = data;
              this.pdfDialogTitle = timetableTitle;
              this.pdfDialog = true;
              this.requestedTimetableId = null;
            }
          })
          .catch((e) => {
            if (e.message === 'Forbidden') {
              this.$router.replace({name: 'news'}, () => {
                this.$toasted.error(this.$t('accessToPageIsForbidden'));
              })
            } else {
              this.$toasted.error(e.message);
            }

            this.requestedTimetableId = null;
          });
    },
  }
}
</script>
