import i18next from 'i18next';

import ar from 'stayinformed-chat-app/src/translations/ar';
import cs from 'stayinformed-chat-app/src/translations/cs';
import da from 'stayinformed-chat-app/src/translations/da';
import de_DE from 'stayinformed-chat-app/src/translations/de_DE';
import en_GB from 'stayinformed-chat-app/src/translations/en_GB';
import es from 'stayinformed-chat-app/src/translations/es';
import fa from 'stayinformed-chat-app/src/translations/fa';
import fr from 'stayinformed-chat-app/src/translations/fr';
import it from 'stayinformed-chat-app/src/translations/it';
import nl from 'stayinformed-chat-app/src/translations/nl';
import pl from 'stayinformed-chat-app/src/translations/pl';
import pt from 'stayinformed-chat-app/src/translations/pt';
import ro from 'stayinformed-chat-app/src/translations/ro';
import ru from 'stayinformed-chat-app/src/translations/ru';
import sq from 'stayinformed-chat-app/src/translations/sq';
import tr from 'stayinformed-chat-app/src/translations/tr';
import uk from 'stayinformed-chat-app/src/translations/uk';

export const CHAT_AVAILABLE_LANGUAGES = [
  {short: 'ar', translations: ar},
  {short: 'cs', translations: cs},
  {short: 'da', translations: da},
  {short: 'de', long: 'de-DE', translations: de_DE},
  {short: 'en', long: 'en-GB', translations: en_GB},
  {short: 'es', translations: es},
  {short: 'fa', translations: fa},
  {short: 'fr', translations: fr},
  {short: 'it', translations: it},
  {short: 'nl', translations: nl},
  {short: 'pl', translations: pl},
  {short: 'pt', translations: pt},
  {short: 'ro', translations: ro},
  {short: 'ru', translations: ru},
  {short: 'sq', translations: sq},
  {short: 'tr', translations: tr},
  {short: 'uk', translations: uk},
];

export default function () {
  for (const language of CHAT_AVAILABLE_LANGUAGES) {
    i18next.addResourceBundle(language.short, 'translation', language.translations, true);

    if (Object.hasOwn(language, 'long')) {
      i18next.addResourceBundle(language.long, 'translation', language.translations, true);
    }
  }
}