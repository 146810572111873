<template>
  <v-banner color="NewsSkyBlue" class="mb-5">
    <v-avatar slot="icon" color="BtnDisable" size="44">
      <v-icon color="SystemWhite">
        mdi-eye-off
      </v-icon>
    </v-avatar>

    <span class="Text01--text text-body-1">{{ $t('hiddenNewsMessage') }}</span>

    <template v-slot:actions>
      <v-btn color="primary" text small @click="$emit('initUnHideNewsItem')">
        <span class="text-capitalize text-subtitle-2">{{ $t('unhide') }}</span>
      </v-btn>
    </template>
  </v-banner>
</template>

<script>
export default {
  name: "HiddenBanner"
}
</script>

<style scoped lang="scss">
.v-banner {
  ::v-deep {
    .v-banner__wrapper {
      border-bottom: none !important;
    }
  }
}
</style>