<template>
  <full-width-page-form-layout :max-width="588">
    <template v-slot:default>
      <div class="text-sm-h2 text-subtitle-1 text-center mb-10">
        {{ $t('mobilePasswordResetSuccess') }}
      </div>

      <v-btn class="mb-6" block color="primary" outlined :to="{name: 'login'}">
        {{ $t('loginBtnLogin') }}
      </v-btn>
    </template>
  </full-width-page-form-layout>
</template>

<script>
import FullWidthPageFormLayout from "@/components/FullWidthPageFormLayout";

export default {
  components: {
    FullWidthPageFormLayout
  }
}
</script>
