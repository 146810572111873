<template>
  <v-row>
    <v-col>
      <div class="main-content-container tight">
        <v-list two-line class="internal-content-container">
          <SkeletonLoader v-if="loading"/>

          <template v-else>
            <member-list-item
                v-for="member in members"
                :key="member.id"
                :member="member"
            />
            <infinite-loading :identifier="infiniteId" @infinite="loadMembers">
              <template v-slot:spinner>
                <SkeletonLoader/>
              </template>
              <template v-slot:no-results>
                <empty-state
                    :title="isAppTypeCare() ? $t('no_residents_title') : $t('no_children_title')"
                    :subtitle="isAppTypeCare() ? $t('no_residents_subtitle') : $t('no_children_subtitle')"
                    image="/images/empty-children.svg"
                />
              </template>
            </infinite-loading>
          </template>
        </v-list>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {MemberActions} from "@/store/member";
import MemberListItem from "@/components/pages/MembersPage/components/MemberListItem"
import EmptyState from "@/components/EmptyState";
import appTypeMixin from "@/mixins/appTypeMixin";
import {UserGetters} from "@/store/user";

export default {
  mixins: [appTypeMixin],

  components: {
    MemberListItem,
    EmptyState
  },

  data() {
    return {
      loading: false,
      offset: 0,
      infiniteId: +new Date(),
      members: [],
    }
  },

  computed: {
    ...mapGetters({
      accountConfiguration: `user/${UserGetters.AccountConfiguration}`,
    }),
  },

  mounted() {
    const {showMembers} = this.accountConfiguration;

    if (!showMembers) {
      return this.$router.push('/404')
    }
  },

  methods: {
    ...mapActions({
      fetchMembers: `member/${MemberActions.FetchMembers}`,
    }),

    loadMembers($state) {
      this.fetchMembers(this.offset)
          .then((data) => {
            if (data.length > 0) {
              this.offset++;
              this.members.push(...data);
              $state.loaded();
            } else {
              $state.complete();
            }
          });
    },

    resetList() {
      this.members = [];
      this.offset = 0;
      this.infiniteId += 1;
    },

  },

}
</script>
