<template>
  <div class="text-body-2 pb-2">
    <span class="calendarRichBlack--text">
      {{ eventItem.type === EventType.Normal ? $t('event') : $t('meeting') }}
    </span>
    <span class="calendarRichBlack--text" v-if="eventItem.deleted">
      <span class="calendarRichBlack--text">|</span> {{ $t('cancelled') }}
    </span>
  </div>
</template>

<script>
import {EventType} from "@/store/event";

export default {
  name: "EventChips",

  props: {
    eventItem: {
      required: true,
      type: Object
    }
  },

  computed: {
    EventType() {
      return EventType;
    }
  }
}
</script>
