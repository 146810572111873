<template>
  <full-width-page-form-layout :show-logo="false" :show-privacy="false" :show-lang-switch="false" :outlined="false">
    <template v-slot:default>
      <div class="d-flex justify-center w-100 align-center flex-grow-1 container-404-wrapper">
        <div class="container-404 d-flex flex-column align-center justify-center px-5">

          <v-img src="/images/logo.svg"/>

          <div class="text-sm-h0 text-h1 Text01--text mt-6 mb-4">{{ $t('page_not_found') }}</div>

          <div class="text-center text-sm-subtitle-1 text-body-2 font-weight-regular mb-10"
               v-html="$t('page_not_found_info')"/>

          <div class="d-flex d-sm-block flex-column">
            <v-btn
                v-if="hasHistory"
                min-width="180"
                :class="$vuetify.rtl ? 'ml-sm-7' : 'mr-sm-7'"
                class="rounded-0"
                x-large
                outlined
                color="primary"
                @click="$router.back()"
            >
              {{ $t('go_back') }}
            </v-btn>
            <v-btn min-width="180" class="rounded-0 mt-7 mt-sm-0" depressed x-large color="primary"
                   :to="{name: 'dashboard'}">
              {{ $t('take_me_home') }}
            </v-btn>
          </div>

        </div>

      </div>
    </template>
  </full-width-page-form-layout>
</template>

<script>
import FullWidthPageFormLayout from "@/components/FullWidthPageFormLayout";

const routesWithRedirect = ['register'];

export default {
  components: {
    FullWidthPageFormLayout
  },
  computed: {
    hasHistory() {
      if (routesWithRedirect.includes(this.$route.redirectedFrom)) {
        return false;
      }

      return window.history.length > 2;
    }
  },
}
</script>