<template>
  <v-list-item class="px-0" v-if="groups.length">
    <v-list-item-content>
      <v-list-item-title class="text-body-2 Text02--text" :class="{'text-wrap': isMoreGroupVisible}">
        <template v-if="isMoreGroupVisible">
          <span :key="index" v-for="({name}, index) in groups">
            {{ name }}<span v-if="index + 1 !== groups.length"> • </span>
          </span>

          <v-btn
              color="primary"
              text
              x-small
              @click.stop.prevent="isMoreGroupVisible = false"
          >
          <span class="text-body-2 font-weight-medium text-lowercase">
            {{ $t("showLess") }}
          </span>
          </v-btn>
        </template>
        <template v-else-if="cutsGroups.length">
          <span :key="index" v-for="({name}, index) in cutsGroups">
            {{ name }}<span v-if="index + 1 !== cutsGroups.length"> • </span>
          </span>
        </template>
      </v-list-item-title>
    </v-list-item-content>
    <v-list-item-action v-if="moreGroups.length && !isMoreGroupVisible">
      <v-btn
          color="primary"
          text
          x-small
          class="px-0"
          @click.stop.prevent="isMoreGroupVisible = true"
      >
          <span class="text-body-2 font-weight-medium text-lowercase">
            + {{ moreGroups.length }} {{ $t("more") }}
          </span>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  name: "MoreGroups",

  data() {
    return {
      isMoreGroupVisible: false
    };
  },

  props: {
    groups: {
      type: Array,
      required: true
    }
  },

  computed: {
    cutsGroups() {
      return this.groups.filter((group, index) => index < 4);
    },

    moreGroups() {
      return this.groups.filter((group, index) => index >= 4);
    }
  }
};
</script>

<style scoped/>
