<template>
  <v-row class="h-100">
    <v-col>
      <div class="main-content-container wide h-100">
        <v-row sticky-container no-gutters class="justify-center h-100 align-content-start align-content-md-stretch">
          <TabsList/>

          <v-col cols="12" md="8">
            <SkeletonLoader v-if="loading"/>

            <template v-else>
              <div class="editable-avatar-container d-flex justify-center mt-15 mt-md-5">
                <v-avatar size="100" class="overflow-visible">
                  <avatar
                      :size="100"
                      :initials="member.initials"
                      :src="member.avatar_field"
                      color="white"
                      backgroundColor="var(--v-IconsBlue-base)"
                  />

                  <v-btn
                      v-if="isEditAvatarButtonVisible"
                      color="primary"
                      depressed
                      fab
                      x-small
                      @click="initChoseFile"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>

                  <v-btn
                      v-else-if="isRemoveAvatarButtonVisible"
                      color="error"
                      depressed
                      fab
                      small
                      @click="removeAvatarModal = true"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>

                </v-avatar>

                <AvatarUploadDialog
                    hide-btn
                    :loading="loading"
                    @confirm="confirmAvatarUploading"
                />

                <ConfirmationModal
                    v-model="removeAvatarModal"
                    :title="isAppTypeCare() ? $t('removeResidentAvatarTitle') : $t('removeChildAvatarTitle')"
                    :label="isAppTypeCare() ? $t('removeResidentAvatarInfo') : $t('removeChildAvatarInfo')"
                    :apply-button-label="$t('remove')"
                    icon="mdi-alert-circle"
                    :loading="loading"
                    :max-width="544"
                    @apply="initRemoveAvatar"
                />

              </div>

              <v-list two-line class="mt-5">
                <v-list-item>
                  <v-list-item-icon :class="$vuetify.rtl ? 'ml-3' : 'mr-3'">
                    <v-icon size="24" color="Text03">
                      mdi-clipboard-account
                    </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title class="text-body-1 Text01--text">
                      {{ member.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-body-2 Text02--text">
                      {{ $t("fullName") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>

                </v-list-item>

                <v-divider/>

                <v-list-item>
                  <v-list-item-icon :class="$vuetify.rtl ? 'ml-3' : 'mr-3'">
                    <v-icon size="24" color="Text03">
                      mdi-sitemap
                    </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title class="text-body-1 Text01--text text-wrap">
                      <MoreGroups class="w-100" :groups="member.groups"/>
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-body-2 Text02--text">
                      {{ $t("groups") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>

                </v-list-item>
              </v-list>
            </template>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {MemberActions, MemberGetters} from "@/store/member";
import {prettyChildGroups} from "@/helpers/childrenConditions";
import appTypeMixin from "@/mixins/appTypeMixin";
import {Avatar} from "vue-avatar";
import {UserGetters} from "@/store/user";
import AvatarUploadDialog from "@/components/pages/MemberPage/components/AvatarUploadDialog";
import ConfirmationModal from "@/components/ConfirmationModal";
import TabsList from "@/components/pages/MemberPage/components/TabsList";
import MoreGroups from "@/components/pages/MemberPage/components/MoreGroups";

export default {
  mixins: [appTypeMixin],

  components: {
    Avatar,
    AvatarUploadDialog,
    ConfirmationModal,
    TabsList,
    MoreGroups,
  },

  data() {
    return {
      loading: true,
      removeAvatarModal: false,
      weekdays: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
    }
  },

  async mounted() {
    await this.initLoadingPageData();

    this.loading = false;
  },

  computed: {
    ...mapGetters({
      memberAvatarStatus: `user/${UserGetters.MemberAvatarStatus}`,
      member: `member/${MemberGetters.Member}`,
    }),

    groups() {
      return prettyChildGroups(this.member.groups);
    },

    isEditAvatarButtonVisible() {
      return ['all', 'app_users'].includes(this.memberAvatarStatus);
    },

    isRemoveAvatarButtonVisible() {
      return this.memberAvatarStatus === 'admins' && Boolean(this.member.avatar_field);
    },
  },

  methods: {
    ...mapActions({
      fetchMember: `member/${MemberActions.FetchMember}`,
      updateAvatar: `member/${MemberActions.SaveAvatar}`,
      deleteAvatar: `member/${MemberActions.DeleteAvatar}`,
    }),

    async initLoadingPageData() {
      await this.fetchMember(this.$route.params.id);
    },

    initChoseFile() {
      this.$root.$refs.avatarUploadDialog.initChoseFile();
    },

    async blobToBase64(blob) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    },

    async initRemoveAvatar() {
      this.loading = true;

      const memberId = this.member.id;

      await this.deleteAvatar(memberId);

      this.removeAvatarModal = false;

      await this.initLoadingPageData();

      this.loading = false;

      this.$toasted.error(this.$t('avatarRemoved'));
    },

    async confirmAvatarUploading(blob) {
      this.loading = true;

      const memberId = this.member.id;
      const avatar = await this.blobToBase64(blob);

      await this.updateAvatar({memberId, avatar}).then(() => {
        this.$root.$refs.avatarUploadDialog.close();
      });

      await this.initLoadingPageData();

      this.loading = false;

      this.$toasted.success(this.$t('avatarUpdated'));
    }
  }
}
</script>
