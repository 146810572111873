export const GlobalEvent = {
  ASSOCIATION_WAS_CHANGED: 'associationWasChanged',
  LOGOUT: 'logout'
}

export const ChatUserActions = {
  ShowMe: 'showMe',
  UpdateMe: 'updateMe',
  FetchBlob: 'fetchBlob',
};

export const InAppTranslationLangKey = 'inAppTranslationLang';

export const DatePickerFormat = 'YYYY-MM-DD';
export const TimePickerFormat = 'HH:mm';