var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.$emit('submit')}}},[_c('validation-observer',{ref:"observer",attrs:{"tag":"div"}},[_c('v-container',{staticClass:"px-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"editable-avatar-container d-flex justify-center"},[_c('v-avatar',{staticClass:"overflow-visible",attrs:{"size":"96","color":"IconsLightBlue"}},[(_vm.contact.avatar_field)?_c('img',{attrs:{"src":_vm.contact.avatar_field,"alt":""}}):_c('v-icon',{attrs:{"size":"55","color":"SystemWhite"}},[_vm._v(" mdi-account ")]),(!_vm.isFormDisabled)?_c('v-btn',{attrs:{"color":"primary","depressed":"","fab":"","x-small":""},on:{"click":_vm.initChoseFile}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e()],1),_c('AvatarUploadDialog',{attrs:{"hide-btn":"","return-original":true,"title":_vm.$t('editPhoto')},on:{"confirm":_vm.confirmAvatarUploading}})],1)]),(_vm.type === 'create')?_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{staticClass:"mb-0",attrs:{"dense":"","color":"SystemBG","rounded":"0"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{staticClass:"align-self-baseline mt-1 mr-3",attrs:{"color":"IconsBlue"}},[_vm._v("mdi-alert-circle")])]},proxy:true}],null,false,3093296551)},[_c('span',{staticClass:"text-md-body-2 text-caption Text01--text"},[_vm._v(" "+_vm._s(_vm.$t('membersAdditionalContactsPrivacyHelpMessage'))+" ")])])],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('registerFirstName'),"vid":"first_name","rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"required",attrs:{"error-messages":errors,"label":_vm.$t('registerFirstName'),"required":"","outlined":"","hide-details":"auto","disabled":_vm.isFormDisabled},model:{value:(_vm.contact.first_name),callback:function ($$v) {_vm.$set(_vm.contact, "first_name", $$v)},expression:"contact.first_name"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('registerLastName'),"vid":"last_name","rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"required",attrs:{"error-messages":errors,"label":_vm.$t('registerLastName'),"required":"","outlined":"","hide-details":"auto","disabled":_vm.isFormDisabled},model:{value:(_vm.contact.last_name),callback:function ($$v) {_vm.$set(_vm.contact, "last_name", $$v)},expression:"contact.last_name"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"vid":"is_allowed_to_pick_up","rules":{required: !_vm.checkboxes.length},"custom-messages":{required: _vm.$t('newsResponseErrorSelect')}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-list',{staticClass:"py-0",attrs:{"disabled":_vm.isFormDisabled}},[_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.checkboxes),callback:function ($$v) {_vm.checkboxes=$$v},expression:"checkboxes"}},[_c('v-subheader',{staticClass:"px-0 text-body-1 Text01--text",class:{'AlertRed--text': errors.length}},[_vm._v(" "+_vm._s(_vm.$t('chooseAtLeastOneOptionBelow'))+"* ")]),_c('v-list-item',{staticClass:"px-0",attrs:{"value":"is_allowed_to_pick_up","ripple":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-body-1 Text01--text",class:{'AlertRed--text': errors.length}},[_vm._v(" "+_vm._s(_vm.$t('membersAdditionalContactsAllowedToPickUp'))+" ")])],1),_c('v-list-item-action',[_c('v-checkbox',{attrs:{"disabled":_vm.isFormDisabled,"error-messages":errors,"input-value":active}})],1)]}}],null,true)}),_c('v-divider'),_c('v-list-item',{staticClass:"px-0",attrs:{"value":"is_emergency_contact","ripple":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-body-1 Text01--text",class:{'AlertRed--text': errors.length}},[_vm._v(" "+_vm._s(_vm.$t('membersAdditionalContactsEmergencyContact'))+" ")])],1),_c('v-list-item-action',[_c('v-checkbox',{attrs:{"disabled":_vm.isFormDisabled,"error-messages":errors,"input-value":active}})],1)]}}],null,true)}),_c('v-divider')],1)],1),_c('custom-error-messages',{attrs:{"errors":errors}})]}}])})],1),(_vm.isMobileVisible)?_c('v-col',{attrs:{"cols":"12"}},[(_vm.type === 'create')?_c('v-alert',{attrs:{"dense":"","color":"SystemBG","rounded":"0"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{staticClass:"align-self-baseline mt-1 mr-3",attrs:{"color":"IconsBlue"}},[_vm._v("mdi-alert-circle")])]},proxy:true}],null,false,3093296551)},[_c('span',{staticClass:"text-md-body-2 text-caption Text01--text"},[_vm._v(" "+_vm._s(_vm.$t('membersAdditionalContactsPhoneHelpMessage'))+" ")])]):_vm._e(),_c('validation-provider',{attrs:{"name":_vm.$t('phoneNumber'),"vid":"mobile","rules":{required: _vm.contact.is_emergency_contact, max: 50, phoneNumber: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{class:{required: _vm.contact.is_emergency_contact},attrs:{"error-messages":errors,"label":_vm.$t('phoneNumber'),"outlined":"","hide-details":"auto","disabled":_vm.isFormDisabled},model:{value:(_vm.contact.mobile),callback:function ($$v) {_vm.$set(_vm.contact, "mobile", $$v)},expression:"contact.mobile"}})]}}],null,false,641982311)})],1):_vm._e(),(_vm.isCommentVisible)?_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('comment'),"vid":"comment","rules":"max:300"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":_vm.$t('comment'),"error-messages":errors,"rows":"1","outlined":"","auto-grow":"","counter":"","disabled":_vm.isFormDisabled},scopedSlots:_vm._u([{key:"counter",fn:function(ref){
var value = ref.props.value;
return [_c('div',{staticClass:"Text03--text text-caption"},[_vm._v(" "+_vm._s(value)+" / 300 ")])]}}],null,true),model:{value:(_vm.contact.comment),callback:function ($$v) {_vm.$set(_vm.contact, "comment", $$v)},expression:"contact.comment"}})]}}],null,false,2062432318)})],1):_vm._e(),(_vm.type === 'edit' && (_vm.formattedMembers.length >= 2 || _vm.isFormDisabled))?_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"vid":"children_ids","rules":{required: !_vm.contact.children_ids.length},"custom-messages":{required: _vm.$t('membersAdditionalContactsPleaseSelectAtLeastOne', {memberType: _vm.isAppTypeCare() ? _vm.$t('resident') : _vm.$t('child')})}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-list',{staticClass:"py-0",attrs:{"two-line":""}},[_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.contact.children_ids),callback:function ($$v) {_vm.$set(_vm.contact, "children_ids", $$v)},expression:"contact.children_ids"}},[_c('v-subheader',{staticClass:"px-0 text-body-1 Text01--text"},[_vm._v(" "+_vm._s(_vm.$t('membersAdditionalContactsThisPersonAdditionalContactFor'))+" ")]),_vm._l((_vm.formattedMembers),function(ref){
var id = ref.id;
var initials = ref.initials;
var avatar_field = ref.avatar_field;
var first_name = ref.first_name;
var last_name = ref.last_name;
return [_c('v-list-item',{key:id,staticClass:"px-0",attrs:{"value":id,"ripple":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-avatar',{attrs:{"size":"40"}},[_c('avatar',{attrs:{"size":40,"initials":initials,"src":avatar_field,"color":"white","backgroundColor":"var(--v-IconsBlue-base)"}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-body-1 Text01--text"},[_vm._v(" "+_vm._s(first_name)+" "+_vm._s(last_name)+" ")])],1),_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active}})],1)]}}],null,true)}),_c('v-divider',{key:(id + "-divider")})]})],2)],1),_c('custom-error-messages',{attrs:{"errors":errors}})]}}],null,false,3172401643)})],1):_vm._e(),(_vm.type === 'create' && _vm.members.length >= 2)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"10"}},[_c('div',{staticClass:"text-body-1 Text01--text mr-auto"},[_vm._v(" "+_vm._s(_vm.$t('membersAdditionalContactsForAllChildrenToggle'))+" ")])]),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"2"}},[_c('v-switch',{staticClass:"mt-0 mb-1 mr-md-n2",attrs:{"inset":"","hide-details":""},model:{value:(_vm.membersSwitch),callback:function ($$v) {_vm.membersSwitch=$$v},expression:"membersSwitch"}})],1)],1),(_vm.membersSwitch)?_c('v-row',{attrs:{"id":"members-list"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-list',{attrs:{"two-line":""}},[_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.contact.children_ids),callback:function ($$v) {_vm.$set(_vm.contact, "children_ids", $$v)},expression:"contact.children_ids"}},[_vm._l((_vm.members),function(ref){
var id = ref.id;
var initials = ref.initials;
var avatar_field = ref.avatar_field;
var first_name = ref.first_name;
var last_name = ref.last_name;
return [_c('v-list-item',{key:id,staticClass:"px-0",attrs:{"disabled":id === _vm.member.id,"value":id,"ripple":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-avatar',{attrs:{"size":"40"}},[_c('avatar',{attrs:{"size":40,"initials":initials,"src":avatar_field,"color":"white","backgroundColor":"var(--v-IconsBlue-base)"}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-body-1 Text01--text"},[_vm._v(" "+_vm._s(first_name)+" "+_vm._s(last_name)+" ")])],1),_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active}})],1)]}}],null,true)}),_c('v-divider',{key:(id + "-divider")})]})],2)],1)],1)],1):_vm._e()],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }