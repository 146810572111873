import {UserGetters} from "@/store/user";
import {ContactsService} from '@/services/ApiServices';

const initialState = {
    contacts: [],
};

export const ContactsGetters = {
    Contacts: 'contacts',
};

export const ContactsActions = {
    FetchContacts: 'fetchContacts',
};

export const ContactsMutations = {
    SetContacts: 'setContacts',
};

const state = {...initialState};

const getters = {
    [ContactsGetters.Contacts](state) {
        return state.contacts;
    },
};

const actions = {
    async [ContactsActions.FetchContacts]({rootGetters, commit}) {
        const associationId = rootGetters[`user/${UserGetters.AssociationId}`];
        const authenticatedUser = rootGetters[`user/${UserGetters.User}`];

        return ContactsService.getContacts(associationId, authenticatedUser.user_id).then(({data}) => {
            commit(ContactsMutations.SetContacts, data.items);
        }).catch((e) => {
            throw new Error(e.response.data.message);
        });
    },
};

const mutations = {
    [ContactsMutations.SetContacts](state, contacts) {
        state.contacts = contacts;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};