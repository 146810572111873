<template>
  <v-row>
    <v-col>
      <div class="main-content-container tight">
        <SkeletonLoader v-if="loading"/>

        <template v-else>
          <template v-if="contacts.length">
            <div v-for="contact in contacts" :key="contact.id" class="contact-card-container">
              <v-card tile outlined class="my-4 pa-6">
                <v-card-title>
                  <div class="d-flex flex-column align-center w-100">
                    <div class="text-center mt-3">
                      <v-avatar size="108">
                        <avatar :username="contact.name" :size="100" color="white" backgroundColor="var(--v-IconsBlue-base)"/>
                      </v-avatar>
                      <div class="Text01--text mt-3 text-subtitle-1">{{ contact.name }}</div>
                      <div class="text-body-1 Text02--text mt-1">{{ contact.position }}</div>
                    </div>
                  </div>
                </v-card-title>

                <v-card-text class="contact-card">
                  <v-list>
                    <v-list-item class="px-0" v-if="contact.address.length > 0">
                      <v-list-item-icon :class="$vuetify.rtl ? 'ml-3' : 'mr-3'" class="pt-1">
                        <v-icon color="Text03">mdi-map-marker</v-icon>
                      </v-list-item-icon>

                      <v-list-item-content class="text-md-body-1 contact-content py-4">
                        <div class="text-body-1 Text01--text"
                             v-html="$options.filters.new_line_to_br(contact.address)"/>
                        <span class="text-body-2 Text02--text">{{ $t('contactsAddress') }}</span>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item class="px-0" v-if="contact.phone.length > 0">
                      <v-list-item-icon :class="$vuetify.rtl ? 'ml-3' : 'mr-3'" class="pt-1">
                        <v-icon color="Text03">mdi-phone</v-icon>
                      </v-list-item-icon>

                      <v-list-item-content class="text-md-body-1 py-4">
                        <a :href="`tel:${contact.phone}`" class="contact-link text-body-1 Text01--text">
                          {{ contact.phone }}
                        </a>
                        <span class="text-body-2 Text02--text">{{ $t('contactsPhone') }}</span>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item class="px-0" v-if="contact.mobile.length">
                      <v-list-item-icon :class="$vuetify.rtl ? 'ml-3' : 'mr-3'" class="pt-1">
                        <v-icon color="Text03">mdi-cellphone</v-icon>
                      </v-list-item-icon>

                      <v-list-item-content class="text-md-body-1 py-4">
                        <a :href="`tel:${contact.mobile}`"
                           class="contact-link text-body-1 Text01--text">{{ contact.mobile }}</a>
                        <span class="text-body-2 Text02--text">{{ $t('contactsMobile') }}</span>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item class="px-0" v-if="contact.fax.length > 0">
                      <v-list-item-icon :class="$vuetify.rtl ? 'ml-3' : 'mr-3'" class="pt-1">
                        <v-icon color="Text03">mdi-printer</v-icon>
                      </v-list-item-icon>

                      <v-list-item-content class="text-md-body-1 py-4">
                        <a :href="`fax:${contact.fax}`" class="contact-link text-body-1 Text01--text">
                          {{ contact.fax }}
                        </a>
                        <span class="text-body-2 Text02--text">{{ $t('contactsFax') }}</span>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item class="px-0" v-if="contact.email.length > 0">
                      <v-list-item-icon :class="$vuetify.rtl ? 'ml-3' : 'mr-3'" class="pt-1">
                        <v-icon color="Text03">mdi-email</v-icon>
                      </v-list-item-icon>

                      <v-list-item-content class="text-md-body-1 py-4">
                        <a :href="`mailto:${contact.email}`" target="_blank"
                           class="text-body-1">{{ contact.email }}</a>
                        <span class="text-body-2 Text02--text">{{ $t('contactsEmail') }}</span>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </div>
          </template>
          <empty-state
              v-else
              :title="$t('no_contacts_title')"
              :subtitle="$t('no_contacts_subtitle')"
              image="/images/empty-contacts.svg"
          />
        </template>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {ContactsActions, ContactsGetters} from '@/store/contacts';
import EmptyState from "@/components/EmptyState";
import {Avatar} from "vue-avatar";

export default {
  name: "ContactsPage",

  components: {
    EmptyState,
    Avatar,
  },

  mounted() {
    this.initFetchContacts();
  },

  data() {
    return {
      loading: true,
    }
  },

  computed: {
    ...mapGetters({
      contacts: `contacts/${ContactsGetters.Contacts}`,
    }),
  },

  methods: {
    ...mapActions({
      fetchContacts: `contacts/${ContactsActions.FetchContacts}`,
    }),

    async initFetchContacts() {
      await this.fetchContacts();
      this.loading = false;
    }
  }
}
</script>
