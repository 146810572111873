<template>
  <v-banner app sticky>
    <div class="overflow-y-auto news-filter" style="max-height: 60vh;">

      <template v-if="pinnedNewsCount">
        <v-sheet
            class="text-subtitle-2 Text01--text py-3 d-flex justify-space-between align-center"
            :class="$vuetify.rtl ? 'pr-3 pl-1' : 'pl-3 pr-1'"
            color="SystemBG"
        >
          {{ $t('pinnedNewsTitle') }}

          <v-btn color="primary" small text :to="{name: 'news.pinned'}"
                 class="text-body-2 font-weight-medium text-capitalize">
            {{ $t('showAll') }}
            <template v-if="pinnedNewsCount">({{ pinnedNewsCount }})</template>
          </v-btn>
        </v-sheet>

        <v-list class="py-0">
          <v-list-item :to="{name: 'news.show', params: {id}}" v-for="{id, title} in lastPinnedNewsItems" :key="id">
            <v-list-item-content>
              <v-list-item-title class="text-subtitle-2 Text01--text">
                {{ title }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn small icon color="primary">
                <v-icon>
                  mdi-chevron-right
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </template>

      <v-sheet class="text-subtitle-2 Text01--text pa-3" color="SystemBG">
        {{ $t("filter_news") }}
        <template v-if="resetButtonCounter">({{ resetButtonCounter }})</template>
      </v-sheet>

      <v-expansion-panels multiple accordion v-model="panel">
        <v-expansion-panel>
          <v-expansion-panel-header hide-actions class="text-subtitle-2 Text01--text pa-3">

            <template v-slot:default="{ open }">
              <div class="d-flex align-center justify-space-between">
                {{ $t('filterByType') }}
                <template v-if="type !== 'all'">(1)</template>

                <v-btn small icon color="primary">
                  <v-icon>
                    {{ open ? "mdi-chevron-down" : "mdi-chevron-right" }}
                  </v-icon>
                </v-btn>
              </div>
            </template>

          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-radio-group
                hide-details
                class="mt-0 px-3"
                v-model="type"
                mandatory
            >
              <v-radio
                  :label="$t('all')"
                  value="all"
              />
              <v-divider class="mb-4 mt-2"/>
              <v-radio
                  :label="$t('info')"
                  value="article"
              />
              <v-divider class="mb-4 mt-2"/>
              <v-radio
                  :label="$t('responses')"
                  value="response"
              />
              <v-divider class="mb-4 mt-2"/>
            </v-radio-group>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header hide-actions class="text-subtitle-2 Text01--text pa-3">

            <template v-slot:default="{ open }">
              <div class="d-flex align-center justify-space-between">
                {{ $t('filterByStatus') }}
                <template v-if="statuses.length">({{ statuses.length }})</template>

                <v-btn small icon color="primary">
                  <v-icon>
                    {{ open ? "mdi-chevron-down" : "mdi-chevron-right" }}
                  </v-icon>
                </v-btn>
              </div>
            </template>

          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-list flat class="pt-0">
              <v-list-item-group v-model="statuses" multiple>

                <v-list-item value="unread" :ripple="false" class="px-3">
                  <template v-slot:default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title>{{ $t('unread') }}</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-checkbox
                          :input-value="active"
                          color="primary"
                      />
                    </v-list-item-action>
                  </template>

                </v-list-item>

                <v-divider class="mx-3"/>

                <v-list-item value="favourite" :ripple="false" class="px-3">
                  <template v-slot:default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title>{{ $t('favourites') }}</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-checkbox
                          :input-value="active"
                          color="primary"
                      />
                    </v-list-item-action>
                  </template>
                </v-list-item>

                <v-divider class="mx-3"/>

                <v-list-item value="hidden" :ripple="false" class="px-3">
                  <template v-slot:default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title>{{ $t('hidden') }}</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-checkbox
                          :input-value="active"
                          color="primary"
                      />
                    </v-list-item-action>
                  </template>
                </v-list-item>

                <v-divider class="mx-3"/>

              </v-list-item-group>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header hide-actions class="text-subtitle-2 Text01--text pa-3">

            <template v-slot:default="{ open }">
              <div class="d-flex align-center justify-space-between">
                {{ $t('byGroups') }}
                <template v-if="recipientsCounter">({{ recipientsCounter }})</template>

                <v-btn small icon color="primary">
                  <v-icon>
                    {{ open ? "mdi-chevron-down" : "mdi-chevron-right" }}
                  </v-icon>
                </v-btn>
              </div>
            </template>

          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-list flat class="py-0" v-if="hasDirectNews">
              <v-list-item :ripple="false" class="px-3">
                <v-list-item-content class="cursor-pointer" @click="isDirectNews = !isDirectNews">
                  <v-list-item-title>{{ $t('displayIndividualNews') }}</v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-checkbox
                      color="primary"
                      v-model="isDirectNews"
                  />
                </v-list-item-action>
              </v-list-item>

              <v-divider class="mx-3"/>
            </v-list>

            <v-list flat class="pt-0">
              <v-list-item-group v-model="recipients" multiple>

                <div v-for="{id, name} in groups" :key="id">
                  <v-list-item :value="id" :ripple="false" class="px-3">
                    <template v-slot:default="{ active }">
                      <v-list-item-content>
                        <v-list-item-title>{{ name }}</v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-checkbox
                            :input-value="active"
                            color="primary"
                        />
                      </v-list-item-action>
                    </template>

                  </v-list-item>

                  <v-divider class="mx-3"/>
                </div>

              </v-list-item-group>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <v-row class="mt-5">
      <v-col>
        <v-btn
            color="primary"
            outlined
            tile
            block
            @click="resetFilters"
            :disabled="!resetButtonCounter"
        >
          {{ $t('reset') }}
          <template v-if="resetButtonCounter">({{ resetButtonCounter }})</template>
        </v-btn>
      </v-col>
      <v-col>
        <v-btn
            color="primary"
            depressed
            tile
            block
            @click="saveFilters"
        >
          {{ $t('apply') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-banner>
</template>

<script>
import newsFilterMixin from "@/mixins/newsFilterMixin";

export default {
  name: "NewsFilter",

  mixins: [newsFilterMixin],
}
</script>

<style lang="scss" scoped>
.v-banner {
  ::v-deep .v-banner__wrapper {
    border-bottom: none !important;
  }
}
</style>
