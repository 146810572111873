<template>
  <full-width-page-form-layout :show-privacy="false" :show-lang-switch="false" :show-logo="false" :max-width="588"
                               :loading="submitLoading">
    <v-form @submit.prevent="initResetPassword" class="single-column-form">
      <v-container>
        <v-row no-gutters>
          <v-col col="12" class="d-flex justify-space-between align-center">
            <div class="text-sm-h2 text-subtitle-1 Text01--text">{{ $t('resetPassword') }}</div>
            <language-changer/>
          </v-col>

          <v-col cols="12" class="mt-6">
            <v-text-field
                v-model="password"
                :error-messages="passwordErrors"
                :label="$t('registerNewPassword')"
                hide-details="auto"
                class="mb-5"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                outlined
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
                v-model="confirmPassword"
                :error-messages="confirmPasswordErrors"
                :label="$t('registerNewPasswordConfirm')"
                hide-details="auto"
                class="mb-5"
                :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showConfirmPassword ? 'text' : 'password'"
                @click:append="showConfirmPassword = !showConfirmPassword"
                outlined
            />
          </v-col>
          <v-col cols="12">
            <v-btn depressed :loading="submitLoading" block type="submit" color="primary">
              {{ $t('resetPassword') }}
            </v-btn>
          </v-col>

          <v-col cols="12" class="align-center d-flex justify-space-between mt-5">
            <a
                target="_blank"
                class="text-decoration-none text-body-2"
                :href="globalTermsAndConditions"
                v-if="Boolean(globalTermsAndConditions)"
            >
              {{ $t('termsConditions') }}
            </a>
            <a
                target="_blank"
                class="text-decoration-none text-body-2"
                :href="globalPrivacyPolicyUrl"
                v-if="Boolean(globalPrivacyPolicyUrl)"
            >
              {{ $t('privacy_policy_title') }}
            </a>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </full-width-page-form-layout>
</template>

<script>
import FullWidthPageFormLayout from "@/components/FullWidthPageFormLayout";
import LanguageChanger from "@/components/LanguageChanger";
import validationMixin from "@/mixins/validationMixin";
import {required, sameAs} from "vuelidate/lib/validators";
import {strongPassword} from "@/helpers/customValidationRules";
import {mapActions} from "vuex";
import {UserActions} from "@/store/user";
import {LegalActions} from "@/store/legalsDocuments";

export default {
  name: "ResetPasswordPage",

  async mounted() {
    await this.initLoadingPageData();
  },

  components: {
    FullWidthPageFormLayout,
    LanguageChanger
  },
  mixins: [validationMixin],
  data() {
    return {
      password: null,
      confirmPassword: null,
      submitLoading: false,
      showPassword: false,
      showConfirmPassword: false,
      globalPrivacyPolicyUrl: null,
      globalTermsAndConditions: null,
    }
  },
  validations: {
    password: {
      required,
      strongPassword
    },
    confirmPassword: {
      required,
      sameAsPassword: sameAs('password')
    },
  },
  computed: {
    passwordErrors() {
      return this.getErrorMessagesForFieldWithRules('password', {
        required,
        strongPassword,
      });
    },
    confirmPasswordErrors() {
      return this.getErrorMessagesForFieldWithRules('confirmPassword', {
        required,
        sameAsPassword: null
      });
    },
  },
  methods: {
    ...mapActions({
      resetPassword: `user/${UserActions.ResetPassword}`,
      fetchLegalItems: `legalsDocuments/${LegalActions.GetLegalLinks}`,
    }),

    async initLoadingPageData() {
      await this.fetchLegalItems()
          .then(async ({data}) => {
            const {global_privacy_policy_url, global_terms_and_conditions} = data;

            this.globalPrivacyPolicyUrl = global_privacy_policy_url;
            this.globalTermsAndConditions = global_terms_and_conditions;
          })
          .catch(e => {
            this.$toasted.error(e.message);
          });
    },

    initResetPassword() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.resetPassword({
        password: this.password,
        token: this.$route.params.token,
      }).then(({is_web}) => {
        this.submitLoading = false;
        const redirectTo = is_web ? 'login' : 'reset_password_success';
        this.$router.replace({name: redirectTo}, () => {
          this.$toasted.success(this.$t('updatePasswordSuccess'));
        });
      }).catch((e) => {
        this.$toasted.error(e.message);
        this.submitLoading = false;
      });
    }
  }
}
</script>
