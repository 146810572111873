<template>
  <v-row class="h-100">
    <v-col>
      <div class="main-content-container wide h-100">
        <v-row sticky-container no-gutters class="justify-center h-100 align-content-start align-content-md-stretch">
          <TabsList/>

          <v-col cols="12" md="8">
            <SkeletonLoader v-if="loading"/>

            <template v-else>
              <v-alert v-if="additionalContacts.length" tile color="SystemBG" class="Text02--text mb-5 mt-md-0 mt-sm-5 mt-13">
                {{ $t("additional_contacts_list_header") }}
              </v-alert>

              <v-card tile elevation="0">
                <v-card-text class="pa-0">
                  <additional-contact-item
                      v-for="additionalContact in additionalContacts"
                      :key="additionalContact.id"
                      :additional-contact="additionalContact"
                      @editAdditionalContact="editModal = true"
                      @deleteAdditionalContact="resetList"
                  />
                  <infinite-loading :identifier="infiniteId" @infinite="loadAdditionalContacts">
                    <template v-slot:spinner>
                      <div test-id="loader_status">
                        <SkeletonLoader/>
                      </div>
                    </template>
                    <template v-slot:no-results>
                      <empty-state
                          class="pt-0 pb-0 mt-10 mt-md-0"
                          :title="$t('membersAdditionalContactsEmptyTitle')"
                          :subtitle="$t('membersAdditionalContactsEmptyMessage')"
                          image="/images/empty-additional-contacts-entries.svg"
                      />
                    </template>
                  </infinite-loading>

                </v-card-text>
              </v-card>
            </template>
          </v-col>
        </v-row>
      </div>
    </v-col>

    <create-modal @createAdditionalContact="resetList" v-model="createModal"/>

    <edit-modal @updateAdditionalContact="resetList" v-model="editModal"/>

    <v-btn
        style="bottom: 70px"
        fab
        fixed
        bottom
        right
        color="primary"
        @click="showCreateContactModal"
    >
      <v-icon size="35">mdi-plus</v-icon>
    </v-btn>
  </v-row>
</template>

<script>
import TabsList from "@/components/pages/MemberPage/components/TabsList";
import AdditionalContactItem from "@/components/pages/MemberPage/components/AdditionalContacts/AdditionalContactItem";
import CreateModal from "@/components/pages/MemberPage/components/AdditionalContacts/CreateModal";
import EditModal from "@/components/pages/MemberPage/components/AdditionalContacts/EditModal";
import EmptyState from "@/components/EmptyState";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {MemberActions, MemberGetters} from "@/store/member";
import {
  AdditionalContactsActions,
  AdditionalContactsMutations,
  AdditionalContactsGetters
} from "@/store/additionalContacts";

export default {
  name: "AdditionalContacts",

  data() {
    return {
      loading: true,
      offset: 1,
      infiniteId: +new Date(),
      additionalContacts: [],
      createModal: false,
      editModal: false,
    }
  },

  components: {
    TabsList,
    EmptyState,
    AdditionalContactItem,
    CreateModal,
    EditModal,
  },

  computed: {
    ...mapGetters({
      member: `member/${MemberGetters.Member}`,
      members: `member/${MemberGetters.Members}`,
      contact: `additionalContacts/${AdditionalContactsGetters.Model}`,
    }),
  },

  async mounted() {
    await this.fetchMembers();

    await this.fetchMember(this.$route.params.id);

    const {config: {additionalcontacts}} = this.member;

    if (!additionalcontacts) {
      return this.$router.push('/404');
    }

    this.loading = false;
  },

  methods: {
    ...mapActions({
      fetchMember: `member/${MemberActions.FetchMember}`,
      fetchMembers: `member/${MemberActions.FetchMembers}`,
      fetchAdditionalContacts: `additionalContacts/${AdditionalContactsActions.FetchAdditionalContacts}`
    }),

    ...mapMutations({
      setLoading: `additionalContacts/${AdditionalContactsMutations.SetLoading}`
    }),

    async loadAdditionalContacts($state) {
      this.fetchAdditionalContacts({
        memberId: this.member.id,
        page: this.offset
      }).then((data) => {
        if (data.length > 0) {
          this.offset += 1;
          this.additionalContacts.push(...data);
          $state.loaded();
        } else {
          $state.complete();
        }
      });
    },

    async showCreateContactModal() {
      this.setLoading(true);

      this.contact.children_ids = [this.member.id];

      this.createModal = true;

      this.setLoading(false);
    },

    resetList() {
      this.additionalContacts = [];
      this.offset = 1;
      this.infiniteId += 1;
    }
  }
}
</script>

<style scoped/>