<template>
  <v-row class="h-100">
    <v-col>
      <SkeletonLoader v-if="loading"/>

      <events-calendar v-else :events="events"/>
    </v-col>
  </v-row>
</template>

<script>
import EventsCalendar from '@/components/pages/EventsListPage/components/EventsCalendar';
import {mapActions, mapGetters, mapMutations} from 'vuex';
import {EventGetters, EventActions, EventMutations} from '@/store/event';
import {GlobalGetters} from "@/store/global";

export default {
  name: "EventsListPage",

  components: {
    EventsCalendar
  },

  mounted() {
    this.initFetchEvents();
  },

  data() {
    return {
      loading: true,
    }
  },

  computed: {
    ...mapGetters({
      events: `event/${EventGetters.Events}`,
      query: `global/${GlobalGetters.Query}`,
      filters: `event/${EventGetters.Filters}`,
    }),
  },

  watch: {
    query() {
      this.initFetchEvents();
    },
    filters() {
      this.initFetchEvents();
    },
  },

  beforeRouteLeave(to, from, next) {
    this.setViewType('calendar');
    next();
  },

  methods: {
    ...mapActions({
      fetchEvents: `event/${EventActions.FetchEvents}`,
    }),

    ...mapMutations({
      setViewType: `event/${EventMutations.SetViewType}`,
    }),

    async initFetchEvents() {
      this.loading = true;
      await this.fetchEvents();
      this.loading = false;
    }
  }
}
</script>