<template>
  <div class="d-flex flex-wrap">
    <div v-if="newsItem.attachment">
      <v-chip class="rounded-0 py-5 mb-2" :class="$vuetify.rtl ? 'ml-2' : 'mr-2'" color="SystemBG">
        <v-icon color="SystemCTA" class="mdi-rotate-45">mdi-paperclip</v-icon>
      </v-chip>
    </div>

    <div v-if="newsItem.type === 'article'">
      <v-chip
          tag="div"
          class="rounded-0 SystemCTA--text py-5 mb-2"
          :class="$vuetify.rtl ? 'ml-2' : 'mr-2'"
          color="SystemBG"
      >
        <span class="text-capitalize text-subtitle-2">
          {{ $t('newsTypeInfo').toLowerCase() }}
        </span>
      </v-chip>
    </div>

    <div
      v-if="newsItem.type === 'response'"
      class="d-flex flex-fill mb-2"
      :class="$vuetify.rtl ? 'ml-2' : 'mr-2'"
    >
      <v-tooltip
          :disabled="!isTooltipShown || !isTooltipMembersShown"
          content-class="text-break text-caption"
          max-width="400"
          bottom
          color="Text04"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-chip
                v-bind="attrs"
                v-on="on"
                class="rounded-0 py-5 flex-fill"
                :color="responseBackground"
            >
              <div class="d-flex align-items-center">
                <v-icon :color="responseIconColor">
                  {{ responseIcon }}
                </v-icon>

                <span
                    class="text-subtitle-2"
                    :class="[responseLabelColor, 'mx-2']"
                >
                  {{ responseChipLabel }}
                </span>
              </div>

              <div
                  v-if="isTooltipShown"
                  class="d-flex r-0 members-section"
                  :class="$vuetify.rtl ? 'ml-4' : 'mr-4'"
              >
                <template v-if="isTooltipMembersShown"
                >
                  <div
                      v-for="(member, index) in cutsMembers" :key="member.id"
                      class="member-container"
                  >
                    <avatar
                        :initials="member.initials"
                        :src="member.avatar_field"
                        color="white"
                        :size="28"
                        class="caption font-weight-bold member-avatar"
                        backgroundColor="var(--v-AvatarsBlue-base)"
                        :style="`border: 3px solid var(--v-LabelYellow-base); left: ${index * 18}px;`"
                    />
                  </div>

                  <avatar
                      v-if="displayMoreMembers"
                      class="caption font-weight-bold member-avatar"
                      :size="28"
                      :initials="moreMembersInitials"
                      color="var(--v-LabelYellow-base)"
                      backgroundColor="var(--v-LabelBlack-base)"
                      :style="`border: 3px solid var(--v-LabelYellow-base); left: ${cutsMembers.length * 18}px;`"
                  />
                </template>
                <div v-else>
                  <v-icon :color="responseIconColor">
                    {{ $vuetify.rtl ? 'mdi-chevron-left' : 'mdi-chevron-right' }}
                  </v-icon>
                </div>
              </div>

            </v-chip>
        </template>

        {{ sortedMembers.map(member => member.name).join(', ') }}
      </v-tooltip>
    </div>

    <div v-if="newsItem.is_hidden">
      <v-chip
          class="rounded-0 py-5 mb-2"
          color="LabelGray"
      >
        <span class="text-subtitle-2 Text02--text">{{ $t('hidden') }}</span>
      </v-chip>
    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {UserGetters} from "@/store/user";
import {isInTargetGroup} from "@/helpers/childrenConditions";
import {Avatar} from "vue-avatar";

export default {
  name: "NewsChips",

  components: {
    Avatar
  },

  props: {
    newsItem: {
      required: true,
      type: Object
    },
    showTooltip: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    isAlreadyAnswered(member) {
      return this.newsItem.answeredMemberIds?.includes(member.id);
    },

    isInTargetGroup(member) {
      return isInTargetGroup(member, this.newsItem.groups);
    },

    isDisabled(member) {
      return !this.isInTargetGroup(member) || this.isAlreadyAnswered(member);
    },

    isAlreadyAnsweredByCurrentUser(member) {
      return this.newsItem.answeredMemberIds?.includes(member.id);
    },

    isAlreadyAnsweredBySomeoneElse(member) {
      return this.newsItem.answeredMemberOtherIds?.includes(member.id);
    },

    membersCountWithResponse() {
      return this.membersAnsweredByCurrentUser.length + this.membersAnsweredBySomeoneElse.length;
    },
  },

  computed: {
    ...mapGetters({
      members: `user/${UserGetters.Members}`,
    }),

    isTooltipShown() {
      return this.showTooltip;
    },

    isTooltipMembersShown() {
      return (
          this.filteredMembers.length &&
          this.newsItem.receiver_type !== 'news_direct' &&
          !this.newsItem.is_response_no_longer_possible &&
          (this.membersAnswersRequired.length || this.membersAnsweredBySomeoneElse.length)
      );
    },

    sortedMembers() {
      return [
        ...(this.membersAnsweredByCurrentUser.length ? this.membersAnsweredByCurrentUser : []),
        ...(this.membersAnswersRequired.length ? this.membersAnswersRequired : []),
        ...(this.membersAnswersNoLongerPossible.length ? this.membersAnswersNoLongerPossible : []),
      ]
    },

    membersList() {
      return this.members.filter(member => this.isInTargetGroup(member));
    },

    filteredMembers() {
      return this.members.filter(member => !this.isDisabled(member));
    },

    cutsMembers() {
      return this.sortedMembers.filter((member, index) => index < 4);
    },

    moreMembers() {
      return this.sortedMembers.filter((member, index) => index >= 4);
    },

    membersAnsweredByCurrentUser() {
      return this.membersList.filter(member => this.isAlreadyAnsweredByCurrentUser(member));
    },

    membersAnsweredBySomeoneElse() {
      return this.filteredMembers.filter(member => this.isAlreadyAnsweredBySomeoneElse(member));
    },

    membersAnswersRequired() {
      return this.membersList.filter(member => (
          !this.isAlreadyAnsweredByCurrentUser(member) &&
          !this.isAlreadyAnsweredBySomeoneElse(member) &&
          !this.newsItem.is_response_no_longer_possible
      ));
    },

    membersAnswersNoLongerPossible() {
      return this.membersList.filter(member => (!this.isAlreadyAnsweredByCurrentUser(member) && this.newsItem.is_response_no_longer_possible));
    },

    responseReceivedConditions() {
      return this.newsItem.answered && (
          // direct news Or when in group news the user doesn't have available members
          !this.membersList.length ||
          // available user members exist, I responded and no available response options exist
          (this.membersList.length && !this.filteredMembers.length) ||
          // I answered on direct news, I have members, and they are equal to filtered members (were untouched)
          (this.membersList.length === this.filteredMembers.length)
      );
    },

    responseIcon() {
      if (this.responseReceivedConditions) {
        return `$news_response_received`;
      }

      if (this.newsItem.is_response_no_longer_possible) {
        return 'mdi-alert-remove-outline';
      }

      if (this.newsItem.answeredMemberOtherIds.length) {
        return 'mdi-check-decagram';
      }

      return 'mdi-alert';
    },

    responseIconColor() {
      if (this.responseReceivedConditions || this.newsItem.is_response_no_longer_possible) {
        return 'SystemWhite';
      }

      return 'LabelBlack';
    },

    responseLabelColor() {
      if (this.responseReceivedConditions || this.newsItem.is_response_no_longer_possible) {
        return 'SystemWhite--text';
      }

      return 'LabelBlack--text';
    },

    responseBackground() {
      if (this.responseReceivedConditions) {
        return 'LabelGreen';
      }

      if (this.newsItem.is_response_no_longer_possible) {
        return 'Text03';
      }

      return 'LabelYellow';
    },

    responseChipLabel() {
      if (this.responseReceivedConditions) {
        return this.$t('newsTypeResponseReceived');
      }

      if (this.newsItem.is_response_no_longer_possible) {
        return this.$t('newsTypeResponseNoLongerPossible');
      }

      if (
          this.newsItem.answeredMemberOtherIds.length &&
          (
              this.membersList.length == this.membersCountWithResponse()
          )
      ) {
        return this.$t('newsTypeResponseAnotherParentReplied');
      }

      return this.$t('responseRequired');
    },

    displayMoreMembers() {
      return this.moreMembers.length > 0;
    },

    moreMembersInitials() {
      return `${this.moreMembers.length}+`;
    }
  }
}
</script>

<style lang="scss" scoped>
.members-section {
  position: absolute;
  right: 0;
}
.member-container {
  height: 30px;
  width: 30px;

  .member-avatar {
    position: absolute;
  }
}
.v-chip {
  &:hover {
    cursor: pointer;
  }
}
</style>


