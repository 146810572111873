<template>
  <v-alert
      rounded="0"
      color="error"
      outlined
      type="error"
      icon="mdi-alert-circle"
      class="mb-0"
  >
    <span class="text-body-2 Text01--text">
      {{ $t('newsLimitReached') }}
    </span>
  </v-alert>
</template>

<script>
export default {
  name: "LimitReachedAlert"
}
</script>