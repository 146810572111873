<template>
  <div class="main-content-container wide">
    <v-row
        class="justify-space-between align-content-start align-content-md-stretch"
        sticky-container
        no-gutters
    >
      <TabsList/>

      <SkeletonLoader v-if="loading"/>

      <v-col cols="12" md="8" class="mt-14 mt-md-0 mt-sm-3">

        <PickupTimeLunchDetails
            :show-pickup="true"
        />


      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {MemberActions, MemberGetters} from "@/store/member";
import TabsList from "@/components/pages/MemberPage/components/TabsList";
import PickupTimeLunchDetails from "@/components/pages/MemberPage/components/PickupTimeLunchDetails.vue";

export default {
  name: "PickupTimesLunch",

  components: {
    PickupTimeLunchDetails,
    TabsList
  },

  data() {
    return {
      loading: true,
    }
  },

  async mounted() {
    await this.fetchMember(this.$route.params.id);

    const {config: {lunchinfo, pickupinfo, showCheckInCheckOut}} = this.member;

    if (!lunchinfo && !pickupinfo && !showCheckInCheckOut) {
      return this.$router.push('/404');
    }

    this.loading = false;
  },

  computed: {
    ...mapGetters({
      member: `member/${MemberGetters.Member}`,
    })
  },

  methods: {
    ...mapActions({
      fetchMember: `member/${MemberActions.FetchMember}`,
    }),
  }
}
</script>