<template>
  <v-textarea
    :value="value"
    :label="label"
    :maxlength="maxlength"
    :error-messages="errors"
    @input="handleInput"
    counter
    outlined
    auto-grow
    :disabled="disabled"
  >
    <template v-slot:counter="{ props: { value: count } }">
      <div class="Text03--text text-caption font-weight-medium">
        {{ count }}/{{ maxlength }}
      </div>
    </template>
  </v-textarea>
</template>

<script>
export default {
  name: 'TextareaWithCounter',

  props: {
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    errors: {
      type: Array,
      default: () => []
    },
    maxlength: {
      type: [Number, String],
      default: 300
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    handleInput(value) {
      this.$emit('input', value);
    }
  }
}
</script>