import axios from 'axios';
import Vue from 'vue';
import SecureLS from "secure-ls";
import { LoginsService, ClientsService } from '@/services/ApiServices';
import AuthPayload from "../models/AuthPayload";
import eventBus from './../services/EventBus'
import {GlobalEvent} from '../constants';
import {AppConfig} from "@/helpers/AppConfig";
import {MembersService} from "@/services/ApiServices";
import {simpleLocaleName} from "@/i18nInit";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_API_HOST;

const initialState = {
    isAuthenticated: false,
    avatar: null,
    user: null,
    associationId: null,
    associationMainId: null,
    preSelectedAssociationId: null,
    userActiveAssociations: [],
    accountDetails: null,
    accountConfiguration: null,
    members: [],
    appType: null,
    associationLogo: null,
    associationName: null,
    associationsList: [],
};

export const UserGetters = {
    IsAuthenticated: 'isAuthenticated',
    User: 'user',
    Avatar: 'avatar',
    AssociationId: 'associationId',
    AssociationMainId: 'associationMainId',
    PreSelectedAssociationId: 'preSelectedAssociationId',
    AssociationName: 'associationName',
    AssociationLogo: 'associationLogo',
    UserActiveAssociations: 'userActiveAssociations',
    AccountDetails: 'accountDetails',
    AccountConfiguration: 'accountConfiguration',
    Members: 'members',
    AppType: 'appType',
    AssociationsList: 'associationsList',
    IsIndividualChatActive: 'isIndividualChatActive',
    IsGroupChatActive: 'isGroupChatActive',
    IsNoticeboardActive: 'IsNoticeboardActive',
    MemberAvatarStatus: "memberAvatarStatus"
};

export const UserActions = {
    Login: 'login',
    Logout: 'logout',
    CheckIfAuthenticated: 'checkIfAuthenticated',
    FetchUserInfoByToken: 'fetchUserInfoByToken',
    RegisterUser: 'registerUser',
    ForgotPassword: 'forgotPassword',
    ResetPassword: 'resetPassword',
    UpdateAccountDetails: 'updateAccountDetails',
    SaveAvatar: 'saveAvatar',
    FetchAvatar: 'fetchAvatar',
    RestoreDataFromLS: 'restoreDataFromLS',
    FetchMembers: 'fetchMembers',
    UpdateAssociationInfo: 'updateAssociationInfo',
    FetchAccountDetails: 'fetchAccountDetails',
    UpdatePreSelectedAssociationId: 'updatePreSelectedAssociationId',
    UpdateAssociationsList: 'updateAssociationsList',
    AccountDeletionRequest: 'accountDeletionRequest',
    GetListOfLegalDocuments: 'getListOfLegalDocuments',
};

export const UserMutations = {
    SetIsAuthenticated: 'setIsAuthenticated',
    SetUser: 'setUser',
    SetAvatar: 'setAvatar',
    SetAssociationId: 'setAssociationId',
    SetAssociationMainId: 'setAssociationMainId',
    SetUserActiveAssociations: 'setUserActiveAssociations',
    SetAuthData: 'setAuthData',
    SetAccountDetails: 'setAccountDetails',
    SetAccountConfiguration: 'setAccountConfiguration',
    SetAppType: 'setAppType',
    SetMembers: 'setMembers',
    SetAssociationLogo: 'setAssociationLogo',
    SetAssociationName: 'setAssociationName',
    SetAssociationsList: 'setAssociationsList',
    SetPreSelectedAssociationId: 'setPreSelectedAssociationId',
    SetLegalDocuments: 'SetLegalDocuments',
};

export const AuthStatuses = {
    Authorized: 'Authorized',
    SelectAssociation: 'SelectAssociation',
    ActivateCaptcha: 'ActivateCaptcha',
};

export const ForgotPasswordStatuses = {
    Requested: 'Requested',
    SelectAssociation: 'SelectAssociation'
};

export const AppType = {
    Kita: 'kita', // 1
    SchuleHP: 'schuleHP', // 2 - school with hour plan
    Schule: 'schule', // 3
    SchuleAC: 'schuleAC', // 4
    Teams: 'teams', // 5
    Care: 'care', // 6
    Clubs: 'clubs', // 7
}

export const AppTypeNumber = {
    Kita: 1,
    School: 2,
    SchoolNoHourPlan: 3,
    AfterSchoolCareCenter: 4,
    Teams: 5,
    Care: 6,
    Clubs: 7,
}

function getSecureLS() {
    return new SecureLS({
        encodingType: 'des',
        isCompression: false,
        encryptionSecret: Vue.cookie.get('encryption_secret')
    });
}

function prepareAuthData(loginResponseData, encryptionSecret) {
    if (!encryptionSecret) {
        encryptionSecret = Vue.cookie.get('encryption_secret');
    }

    return {
        isAuthenticated: true,
        user: {
            user_id: loginResponseData.id
        },
        associationId: loginResponseData.appid,
        associationMainId: loginResponseData.association_id,
        rememberMeToken: loginResponseData.remember_me_token,
        encryptionSecret: encryptionSecret,
        associationName: loginResponseData.appname,
        associationLogo: loginResponseData.image,
        accountDetails: {
            first_name: loginResponseData.fname,
            last_name: loginResponseData.lname,
            app_language: loginResponseData.app_language
        },
        accountConfiguration: {
            useChildName: loginResponseData.usechildname,
            childName: loginResponseData.childname,
            useReceiveEmail: loginResponseData.usereceiveemail,
            receiveEmail: loginResponseData.receive_email,
            codeRequired: loginResponseData.coderequired,
            sickList: loginResponseData.sicklist,
            pickupInfo: loginResponseData.pickupinfo,
            lunchInfo: loginResponseData.lunchinfo,
            showCheckInCheckOut: loginResponseData.show_check_in_check_out,
            lunchRequiredUntil: loginResponseData.lunch_required_until,
            pickupTimeUntil: loginResponseData.pickup_time_until,
            absencesUntil: loginResponseData.absences_until,
            agbRequired: loginResponseData.agbrequired,
            agbAccepted: loginResponseData.agbaccepted,
            showHourPlan: loginResponseData.showhourplan,
            showFoodPlan: loginResponseData.showfoodplan,
            showTimetable: loginResponseData.showtimetable,
            showMembers: loginResponseData.showmembers,
            showAbsentComment: loginResponseData.showabsentcomment,
            showAbsentReason: loginResponseData.showabsentreason,
            individualChatActive: loginResponseData.individual_chat_active,
            groupChatActive: loginResponseData.group_chat_active,
            inAppTranslatorActive: loginResponseData.in_app_translator_is_active,
            isNoticeboardActive: loginResponseData.shownoticeboard,
            membersAvatarsStatus: loginResponseData.members_avatars_status,
            pickupTimeIsEditableByAppUsers: loginResponseData.pickup_time_is_editable_by_app_users,
            lunchIsEditableByAppUsers: loginResponseData.lunch_is_editable_by_app_users,
        },
        appType: loginResponseData.type,
        associationsList: loginResponseData.associations,
    }
}

function parseAppType(type) {
    if (!type) {
        return AppType.Schule;
    }

    switch (type) {
        case AppTypeNumber.Kita:
            return AppType.Kita;
        case AppTypeNumber.School:
            return AppType.SchuleHP;
        case AppTypeNumber.Care:
            return AppType.Care;
        case AppTypeNumber.Teams:
            return AppType.Teams;
        default:
            return AppType.Schule;
    }
}

const state = { ...initialState };

const getters = {
    [UserGetters.IsAuthenticated](state) {
        return state.isAuthenticated;
    },
    [UserGetters.User](state) {
        return state.user;
    },
    [UserGetters.Avatar](state) {
        return state.avatar;
    },
    [UserGetters.AssociationId](state) {
        return state.associationId;
    },
    [UserGetters.AssociationMainId](state) {
        return state.associationMainId;
    },
    [UserGetters.AssociationName]() {
        return state.associationName;
    },
    [UserGetters.AssociationLogo]() {
        return state.associationLogo;
    },
    [UserGetters.AccountDetails]() {
        const appLanguage = state?.accountDetails?.app_language;

        if (appLanguage) {
            state.accountDetails.app_language = simpleLocaleName(appLanguage);
        }

        return state.accountDetails;
    },
    [UserGetters.AccountConfiguration]() {
        return state.accountConfiguration;
    },
    [UserGetters.UserActiveAssociations](state) {
        return state.userActiveAssociations;
    },
    [UserGetters.Members]() {
        return state.members;
    },
    [UserGetters.AppType]() {
        return state.appType;
    },
    [UserGetters.AssociationsList]() {
        return state.associationsList;
    },
    [UserGetters.PreSelectedAssociationId]() {
        return state.preSelectedAssociationId;
    },
    [UserGetters.IsIndividualChatActive](state, getters) {
        return getters[UserGetters.AccountConfiguration] ? getters[UserGetters.AccountConfiguration].individualChatActive : false;
    },
    [UserGetters.IsGroupChatActive](state, getters) {
        return getters[UserGetters.AccountConfiguration] ? getters[UserGetters.AccountConfiguration].groupChatActive : false;
    },
    [UserGetters.IsNoticeboardActive](state, getters) {
        return getters[UserGetters.AccountConfiguration] ? getters[UserGetters.AccountConfiguration].isNoticeboardActive : false;
    },
    [UserGetters.MemberAvatarStatus](state, getters) {
        return getters[UserGetters.AccountConfiguration] ? getters[UserGetters.AccountConfiguration].membersAvatarsStatus : "disabled";
    }
};

const actions = {
    async [UserActions.Login]({ commit, dispatch }, {email, password, associationCode, keepMeLoggedIn, solution}) {

        const clientPassRes = await ClientsService.getClientPass();
        const tokensRes = await ClientsService.getTokens(clientPassRes.data.clientpass);
        AuthPayload.setAuthCookies(tokensRes.data.token1, tokensRes.data.token2);

        return LoginsService.login(email, password, associationCode, keepMeLoggedIn, solution).then(({ data }) => {
            if (data.success && data.success === '1') {

                commit(UserMutations.SetAuthData, prepareAuthData(data, tokensRes.data.token2));

                if (data?.avatarUrl) {
                    dispatch(UserActions.FetchAvatar);
                }

                return AuthStatuses.Authorized;
            } else {
                if (Object.prototype.hasOwnProperty.call(data, 'activate_captcha')) {
                    return AuthStatuses.ActivateCaptcha;
                }

                if (Object.prototype.hasOwnProperty.call(data, 'associations')) {
                    commit(UserMutations.SetUserActiveAssociations, data.associations);

                    return AuthStatuses.SelectAssociation;
                }

                throw new Error('Authentication error');
            }
        }).catch((e) => {
            commit(UserMutations.SetIsAuthenticated, false);
            commit(UserMutations.SetUser, null);
            commit(UserMutations.SetAssociationId, null);
            commit(UserMutations.SetAssociationMainId, null);
            commit(UserMutations.SetAssociationLogo, null);
            commit(UserMutations.SetAssociationName, null);
            commit(UserMutations.SetAppType, null);

            throw new Error(e.response.data.message);
        });
    },
    async [UserActions.FetchUserInfoByToken](context, token) {
      return LoginsService.getInvitationTokenInfo(token).then(({ data }) => {
          return data;
      }).catch((e) => {
          throw new Error(e.response.data.message);
      });
    },
    async [UserActions.FetchAccountDetails]({commit}) {
        return LoginsService.getAccountDetails().then(({ data }) => {
            if (data.success && data.success === '1') {
                const updatedAccountDetails = {
                    first_name: data.first_name,
                    last_name: data.last_name,
                    avatar: Boolean(data.avatarUrl),
                }
                commit(UserMutations.SetAccountDetails, updatedAccountDetails);
                if (Object.hasOwnProperty.call(data, 'image')) {
                    commit(UserMutations.SetAssociationLogo, data.image);
                }
                if (Object.hasOwnProperty.call(data, 'appname')) {
                    commit(UserMutations.SetAssociationName, data.appname);
                }
                return data;
            } else {
                throw new Error(data.message);
            }
        }).catch((e) => {
            throw new Error(e.response.data.message);
        });
    },

    async [UserActions.CheckIfAuthenticated]({commit, dispatch}) {
        const userId = Vue.cookie.get('user_id');
        const associationId = Vue.cookie.get('association_id');
        const associationMainId = Vue.cookie.get('association_main_id');
        const publicToken = Vue.cookie.get('public_token');
        const privateToken = Vue.cookie.get('private_token');
        const rememberMeToken = Vue.cookie.get('remember_me_token');

        if (userId && associationId && associationMainId && publicToken && privateToken) {
            commit(UserMutations.SetIsAuthenticated, true);
            commit(UserMutations.SetUser, {
                user_id: userId
            });
            commit(UserMutations.SetAssociationId, associationId);
            commit(UserMutations.SetAssociationMainId, associationMainId);
            AuthPayload.setAuthCookies(publicToken, privateToken);
        } else if (rememberMeToken && associationId && associationMainId && (!userId || !privateToken)) {
            const {data: {clientpass}} = await ClientsService.getClientPass();
            const {data: {token1, token2}} = await ClientsService.getTokensForRefresh(clientpass);

            AuthPayload.setAuthCookies(token1, token2);

            await LoginsService.rememberMeLogin(rememberMeToken, associationId).then(({data}) => {
                if (data.success && data.success === '1') {
                    commit(UserMutations.SetAuthData, prepareAuthData(data, token2));
                }
            }).catch((e) => {
                commit(UserMutations.SetIsAuthenticated, false);
                commit(UserMutations.SetUser, null);
                commit(UserMutations.SetAssociationId, null);
                commit(UserMutations.SetAssociationMainId, null);
                commit(UserMutations.SetAssociationLogo, null);
                commit(UserMutations.SetAssociationName, null);
                commit(UserMutations.SetAppType, null);

                throw new Error(e.response.data.message);
            });

            window.location.reload();
        } else if(associationId && associationMainId && (!userId || !privateToken)) {
            await dispatch(UserActions.Logout)
        }
    },
    async [UserActions.Logout]({ commit }) {
        const rememberMeToken = Vue.cookie.get('remember_me_token');
        if (rememberMeToken) {
            await LoginsService.rememberMeLogout(rememberMeToken, Vue.cookie.get('association_id'));
        }

        AuthPayload.deleteAuthCookies();

        commit(UserMutations.SetIsAuthenticated, false);
        commit(UserMutations.SetUser, null);
        commit(UserMutations.SetAssociationId, null);
        commit(UserMutations.SetAssociationMainId, null);
        commit(UserMutations.SetAssociationLogo, null);
        commit(UserMutations.SetAssociationName, null);
        commit(UserMutations.SetAppType, null);
        commit(UserMutations.SetAvatar, null);
        eventBus.$emit(GlobalEvent.LOGOUT);
        sessionStorage.clear();

        return true;
    },
    async [UserActions.RegisterUser](context, entity) {
        return LoginsService.registerUser(entity).then((data) => {
            return data;
        }).catch((e) => {
            if (typeof e.response.data.message === "object" && typeof e.response.data.message.password === "object") {
                throw new Error(Object.values(e.response.data.message.password).join(', '));
            } else {
                throw new Error(e.response.data.message);
            }
        });
    },
    async [UserActions.ForgotPassword]({commit}, {email, associationCode}) {
        return LoginsService.forgotPassword(email, associationCode).then(({data}) => {
            if (data.success && data.success === '1') {
                return ForgotPasswordStatuses.Requested
            } else {
                if (Object.prototype.hasOwnProperty.call(data, 'associations')) {
                    commit(UserMutations.SetUserActiveAssociations, data.associations);

                    return ForgotPasswordStatuses.SelectAssociation;
                }

                throw new Error('Forgot password error');
            }
        }).catch((e) => {
            throw new Error(e.response.data.message);
        });
    },
    async [UserActions.ResetPassword](context, {password, token}) {
        return LoginsService.resetPassword(password, token).then(({data}) => {
            if (data.success && data.success === '1') {
                return data;
            } else {
                throw new Error('Reset password error');
            }
        }).catch((e) => {
            if (typeof e.response.data.message === "object") {
                throw new Error(Object.values(e.response.data.message).join(', '));
            } else {
                throw new Error(e.response.data.message);
            }
        });
    },
    async [UserActions.FetchAvatar]({rootGetters, commit}) {
        const associationId =  rootGetters[`user/${UserGetters.AssociationId}`];
        const userId = Vue.cookie.get('user_id');

        return LoginsService.fetchAvatar(associationId, userId).then(({ data }) => {
            if (data) {
                commit(UserMutations.SetAvatar, URL.createObjectURL(data));

                return data;
            } else {
                throw new Error(data.message);
            }
        }).catch((e) => {
            throw new Error(e.response.data.message);
        });
    },
    async [UserActions.SaveAvatar]({rootGetters}, {avatar}) {
        const associationId =  rootGetters[`user/${UserGetters.AssociationId}`];
        const userId = Vue.cookie.get('user_id');

        return LoginsService.saveAvatar(associationId, userId, avatar)
            .then(({data}) => {
                if (data.success && data.success === '1') {
                    return data;
                } else {
                    throw new Error('Update Account Error');
                }
            }).catch((e) => {
                if (typeof e.response.data.message === "object" && typeof e.response.data.message.password === "object") {
                    throw new Error(Object.values(e.response.data.message.password).join(', '));
                } else {
                    throw new Error(e.response.data.message);
                }
            });
    },
    async [UserActions.UpdateAccountDetails]({rootGetters, commit}, {firstName, lastName, password, currentPassword, appLanguage}) {
        const associationId =  rootGetters[`user/${UserGetters.AssociationId}`];
        const userId = Vue.cookie.get('user_id');

        return LoginsService.updateAccountDetails(associationId, userId, firstName, lastName, '', password, currentPassword, appLanguage)
            .then(({data}) => {
                if (data.success && data.success === '1') {

                    if (firstName && lastName) {
                        const updatedAccountDetails = {
                            first_name: firstName,
                            last_name: lastName,
                        }

                        commit(UserMutations.SetAccountDetails, updatedAccountDetails);
                    }

                    return data;
                } else {
                    throw new Error('Update Account Error');
                }
            }).catch((e) => {
                if (typeof e.response.data.message === "object" && typeof e.response.data.message.password === "object") {
                    throw new Error(Object.values(e.response.data.message.password).join(', '));
                } else {
                    throw new Error(e.response.data.message);
                }
            });
    },
    async [UserActions.RestoreDataFromLS]({commit}) {
        let ls = getSecureLS();
        if (!ls) {
            return false;
        }
        try {
            commit(UserMutations.SetAppType, ls.get('appType'));
            return commit(UserMutations.SetAccountConfiguration, ls.get('accountConfiguration'));
        } catch (e) {
            return false;
        }
    },
    async [UserActions.AccountDeletionRequest]({rootGetters}) {
        const associationId =  rootGetters[`user/${UserGetters.AssociationId}`];
        const userId = Vue.cookie.get('user_id')

        return LoginsService.requestAccountDeletion(associationId, userId).catch((e) => {
            throw new Error(e.response.data.message);
        });
    },
    async [UserActions.FetchMembers]({rootGetters, commit}) {
        const associationId =  rootGetters[`user/${UserGetters.AssociationId}`];
        const memberAvatarStatus = rootGetters[`user/${UserGetters.MemberAvatarStatus}`];

        const userId = Vue.cookie.get('user_id');
        return LoginsService.getMembers(associationId, userId).then(async ({data}) => {

            data = await Promise.all(data.map(async (member) => {
                const {id, avatar_url, first_name, last_name} = member;

                member.avatar_field = null;
                data.initials = 'N/A';

                if (memberAvatarStatus !== 'disabled' && avatar_url) {
                    let {data} = await MembersService.fetchAvatar(id, associationId, userId);

                    member.avatar_field = URL.createObjectURL(data);
                }

                let firstNameLetter = first_name.charAt(0).toUpperCase();
                let lastNameLetter = last_name.charAt(0).toUpperCase();

                member.initials = `${firstNameLetter}${lastNameLetter}`;

                return member;
            }));

            return commit(UserMutations.SetMembers, data);
        }).catch((e) => {
            throw new Error(e.response.data.message);
        });
    },
    async [UserActions.UpdateAssociationInfo]({commit}, data) {
        commit(UserMutations.SetAuthData, prepareAuthData(data));
    },
    [UserActions.UpdatePreSelectedAssociationId]({commit}, preSelectedAssociationId) {
        return commit(UserMutations.SetPreSelectedAssociationId, preSelectedAssociationId);
    },
    async [UserActions.UpdateAssociationsList]({commit}, data) {
        commit(UserMutations.SetAssociationsList, data);
    },
    async [UserActions.GetListOfLegalDocuments]({commit}, data) {
        commit(UserMutations.SetLegalDocuments, data);
    },
};
const mutations = {
    [UserMutations.SetAuthData](state, {
        isAuthenticated,
        user,
        associationId,
        associationMainId,
        rememberMeToken,
        encryptionSecret,
        associationName,
        associationLogo,
        accountDetails,
        accountConfiguration,
        appType,
        associationsList
    }) {
        state.isAuthenticated = isAuthenticated;
        if (user && Object.prototype.hasOwnProperty.call(user, 'user_id')) {
            Vue.cookie.set('user_id', user.user_id, {secure: AppConfig.productionMode, samesite: AppConfig.sameSiteCookieConfig});
        }
        state.user = user;

        const expires = process.env.VUE_APP_REMEMBER_ME_LIFETIME_DAYS || 90;
        Vue.cookie.set('association_id', associationId, {expires: expires + 'D', secure: AppConfig.productionMode, samesite: AppConfig.sameSiteCookieConfig});
        Vue.cookie.set('association_main_id', associationMainId, {expires: expires + 'D', secure: AppConfig.productionMode, samesite: AppConfig.sameSiteCookieConfig});
        state.associationId = associationId;
        state.associationMainId = associationMainId;

        if (rememberMeToken) {
            Vue.cookie.set(
                'remember_me_token',
                rememberMeToken,
                {expires: expires + 'D', SameSite: 'Strict', secure: AppConfig.productionMode, samesite: AppConfig.sameSiteCookieConfig}
            );
        } else {
            Vue.cookie.delete('remember_me_token');
        }
        Vue.cookie.set('encryption_secret', encryptionSecret, {secure: AppConfig.productionMode, samesite: AppConfig.sameSiteCookieConfig});
        let ls = getSecureLS();

        state.associationName = associationName;

        state.associationLogo = associationLogo;

        if (accountDetails) {
            state.accountDetails = accountDetails;
        }

        state.accountConfiguration = accountConfiguration;
        ls.set('accountConfiguration', state.accountConfiguration);

        state.appType = parseAppType(appType);
        ls.set('appType', state.appType);

        state.associationsList = associationsList;
    },
    [UserMutations.SetIsAuthenticated](state, isAuthenticated) {
        state.isAuthenticated = isAuthenticated;
    },
    [UserMutations.SetUser](state, user) {
        if (user && Object.prototype.hasOwnProperty.call(user, 'user_id')) {
            Vue.cookie.set('user_id', user.user_id, {secure: AppConfig.productionMode, samesite: AppConfig.sameSiteCookieConfig});
        }
        state.user = user;
    },
    [UserMutations.SetAssociationId](state, associationId) {
        const expires = process.env.VUE_APP_REMEMBER_ME_LIFETIME_DAYS || 90;
        Vue.cookie.set('association_id', associationId, {expires: expires + 'D', secure: AppConfig.productionMode, samesite: AppConfig.sameSiteCookieConfig});
        state.associationId = associationId;
    },
    [UserMutations.SetAssociationMainId](state, associationMainId) {
        const expires = process.env.VUE_APP_REMEMBER_ME_LIFETIME_DAYS || 90;
        Vue.cookie.set('association_main_id', associationMainId, {expires: expires + 'D', secure: AppConfig.productionMode, samesite: AppConfig.sameSiteCookieConfig});
        state.associationMainId = associationMainId;
    },
    [UserMutations.SetUserActiveAssociations](state, userActiveAssociations) {
        state.userActiveAssociations = userActiveAssociations;
    },
    [UserMutations.SetAccountDetails](state, accountDetails) {
        state.accountDetails = accountDetails;
    },
    [UserMutations.SetAccountConfiguration](state, accountConfiguration) {
        state.accountConfiguration = accountConfiguration;
    },
    [UserMutations.SetMembers](state, members) {
        state.members = members;
    },
    [UserMutations.SetAppType](state, appType) {
        state.appType = appType;
    },
    [UserMutations.SetAssociationLogo](state, associationLogo) {
        state.associationLogo = associationLogo;
    },
    [UserMutations.SetAssociationName](state, associationName) {
        state.associationName = associationName;
    },
    [UserMutations.SetAssociationsList](state, associationsList) {
        state.associationsList = associationsList;
    },
    [UserMutations.SetPreSelectedAssociationId](state, preSelectedAssociationId) {
        state.preSelectedAssociationId = preSelectedAssociationId;
    },
    [UserMutations.SetLegalDocuments](state, documentsList) {
        state.documentsList = documentsList;
    },
    [UserMutations.SetAvatar](state, avatar) {
        state.avatar = avatar;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
