import axios from "axios";
import store from '@/store/index';
import {UserGetters, UserActions} from "@/store/user";
import AuthPayload from "./models/AuthPayload";
import router from './routes/index';
import i18next from 'i18next';
import Vue from "vue";

const customDataProperties = ['Client', 'News', 'Response', 'Contact', 'Login', 'Event', 'Member', 'Sicklist', 'Association', 'FoodPlan', 'HourPlan', 'Timetable', 'Noticeboard'];

function isExpiredError(error) {
    return Object.prototype.hasOwnProperty.call(error, 'response')
        && Object.prototype.hasOwnProperty.call(error.response, 'status')
        && error.response.status === 500
        && Object.prototype.hasOwnProperty.call(error.response.data, 'message')
        && error.response.data.message === 'EXPIRED';
}

function isInactiveOgranisation(error) {
    return error.response.status === 500
        && Object.prototype.hasOwnProperty.call(error.response.data, 'force_logout')
        && error.response.data.force_logout
        && Object.prototype.hasOwnProperty.call(error.response, 'status')
        && error.response.data.status === 'ERROR';
}

function isRefreshTokenError(error) {
    return Object.prototype.hasOwnProperty.call(error, 'isRefreshTokenError')
        && error.isRefreshTokenError;
}

function isSessionHasExpired(error) {
    return Object.prototype.hasOwnProperty.call(error, 'response')
        && Object.prototype.hasOwnProperty.call(error.response, 'status')
        && error.response.status === 422
        && Object.prototype.hasOwnProperty.call(error.response.data, 'message');
}

function isInvalidRequest(error) {
    return Object.prototype.hasOwnProperty.call(error, 'response')
        && Object.prototype.hasOwnProperty.call(error.response, 'status')
        && error.response.status === 422
        && Object.prototype.hasOwnProperty.call(error.response.data, 'message')
        && error.response.data.missing_user_id;
}

function customDataProperty(obj) {
    let dataProperty = null;
    for (let customDataProperty of customDataProperties) {
        if (Object.prototype.hasOwnProperty.call(obj, customDataProperty)) {
            dataProperty = customDataProperty;
        }
    }

    return dataProperty;
}

export default function () {
    axios.interceptors.request.use(
        function (config) {
            const isAuthenticated = store.getters[`user/${UserGetters.IsAuthenticated}`];
            const authenticatedUser = store.getters[`user/${UserGetters.User}`];
            const associationId = store.getters[`user/${UserGetters.AssociationId}`];

            if (Object.prototype.hasOwnProperty.call(config, 'data')) {
                let dataProperty = customDataProperty(config.data.data);
                const assignAdditionalData = (obj) => {
                    obj.is_web = true;
                    obj.version = process.env.VUE_APP_API_VERSION;
                    obj.language = i18next.language;
                    if (isAuthenticated && !config?.params?.withoutUserId) {
                        obj.userid = authenticatedUser.user_id;
                    }
                    if (!Object.prototype.hasOwnProperty.call(obj, 'objid') && associationId) {
                        obj.objid = associationId;
                    }
                }

                if (dataProperty) {
                    assignAdditionalData(config.data.data[dataProperty]);
                } else {
                    assignAdditionalData(config.data.data);
                }
            } else {
                config.data = {
                    data: {
                        version: process.env.VUE_APP_API_VERSION,
                        language: i18next.language
                    }
                }
            }

            return config;
        },
        function (error) {
            return Promise.reject(error);
        }
    );

    axios.interceptors.response.use(response => response, function (error) {
        if (isInvalidRequest(error)) {
            return Promise.reject(error);
        }

        if (isSessionHasExpired(error)) {
            store.dispatch(`user/${UserActions.Logout}`).then(() => {
                return router.push({name: 'login'});
            }).catch((error) => {
                return Promise.reject(error);
            })
        }

        if (error.request.responseType === 'arraybuffer' &&
            error.response.data instanceof ArrayBuffer &&
            error.response.headers['content-type'] === 'application/json'
        ) {
            const text = String.fromCharCode.apply(null, Array.from(new Uint8Array(error.response.data)));
            error.response.data = JSON.parse(text);
        }

        return Promise.reject(error);
    })

    axios.interceptors.response.use(response => response, function (error) {
        if (isInactiveOgranisation(error)) {
            store.dispatch(`user/${UserActions.Logout}`).then(() => {
                Vue.toasted.error(error?.response?.data?.message)

                return router.replace({name: 'login'});
            }).catch((error) => {
                return Promise.reject(error);
            })
        }

        if (!isExpiredError(error)) {
            return Promise.reject(error);
        }

        if (isRefreshTokenError(error)) {
            return Promise.reject(error);
        }

        return AuthPayload.refreshTokens().then(() => {
            const newTokens = AuthPayload.generateRequestAuthTokens();
            let payload = JSON.parse(error.response.config.data);
            const dataProperty = customDataProperty(payload.data);

            const assignTokens = (obj) => {
                obj.access = newTokens.access;
                obj.timestamp = newTokens.timestamp;
                obj.public = newTokens.public;
            }

            if (dataProperty) {
                assignTokens(payload.data[dataProperty]);
            } else {
                assignTokens(payload.data);
            }

            error.response.config.data = payload;

            return axios(error.response.config);

        }).catch((error) => {
            error.isRefreshTokenError = true;
            return Promise.reject(error);
        });
    })

}