<template>
  <v-row class="h-100 mt-10 mt-md-0">
    <v-col>
      <div class="main-content-container wide h-100">
        <v-row sticky-container no-gutters class="justify-end h-100">
          <TabsList/>

          <v-col cols="12" md="8" class="position-relative">
            <SkeletonLoader v-if="loading"/>

            <template v-else>
              <v-row class="mt-md-5">
                <v-col cols="12">
                  <v-text-field
                      v-model="currentPassword"
                      :error-messages="currentPasswordErrors"
                      :type="showCurrentPassword ? 'text' : 'password'"
                      :label="$t('confirmCurrentPassword')"
                      outlined
                      hide-details="auto"
                  >
                    <template v-slot:append>
                      <v-icon color="Text03" @click="showCurrentPassword = !showCurrentPassword">
                        {{ showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off' }}
                      </v-icon>
                    </template>
                    <template v-slot:append-outer>
                      <div :class="$vuetify.rtl ? 'mr-sm-2' : 'ml-sm-2'">
                        <v-icon v-if="!$v.currentPassword.$invalid" color="LabelGreen">
                          mdi-check-circle
                        </v-icon>
                        <v-icon v-else color="GreyBorder">
                          mdi-checkbox-blank-circle-outline
                        </v-icon>
                      </div>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      v-model="password"
                      autocomplete="new-password"
                      :error-messages="passwordErrors"
                      :type="showPassword ? 'text' : 'password'"
                      :label="$t('registerNewPassword')"
                      outlined
                      hide-details="auto"
                  >
                    <template v-slot:append>
                      <v-icon color="Text03" @click="showPassword = !showPassword">
                        {{ showPassword ? 'mdi-eye' : 'mdi-eye-off' }}
                      </v-icon>
                    </template>
                    <template v-slot:append-outer>
                      <div :class="$vuetify.rtl ? 'mr-sm-2' : 'ml-sm-2'">
                        <v-icon v-if="!$v.password.$invalid" color="LabelGreen">
                          mdi-check-circle
                        </v-icon>
                        <v-icon v-else color="GreyBorder">
                          mdi-checkbox-blank-circle-outline
                        </v-icon>
                      </div>
                    </template>
                  </v-text-field>

                  <v-list class="pb-0" dense>

                    <v-list-item :class="$vuetify.rtl ? 'pr-sm-0' : 'pl-sm-0'">
                      <v-list-item-icon :class="$vuetify.rtl ? 'ml-2' : 'mr-2'">
                        <v-icon v-if="isMinLength" color="LabelGreen">
                          mdi-check-circle
                        </v-icon>
                        <v-icon v-else color="GreyBorder">
                          mdi-checkbox-blank-circle-outline
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="text-body-2 font-weight-regular Text02--text">
                          {{ $t("minCharacters") }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item :class="$vuetify.rtl ? 'pr-sm-0' : 'pl-sm-0'">
                      <v-list-item-icon :class="$vuetify.rtl ? 'ml-2' : 'mr-2'">
                        <v-icon v-if="isAtLeastNumber" color="LabelGreen">
                          mdi-check-circle
                        </v-icon>
                        <v-icon v-else color="GreyBorder">
                          mdi-checkbox-blank-circle-outline
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="text-body-2 font-weight-regular Text02--text">
                          {{ $t("leastNumber") }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item :class="$vuetify.rtl ? 'pr-sm-0' : 'pl-sm-0'">
                      <v-list-item-icon :class="$vuetify.rtl ? 'ml-2' : 'mr-2'">
                        <v-icon v-if="isAtLeastLetter" color="LabelGreen">
                          mdi-check-circle
                        </v-icon>
                        <v-icon v-else color="GreyBorder">
                          mdi-checkbox-blank-circle-outline
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="text-body-2 font-weight-regular Text02--text">
                          {{ $t("leastLetter") }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item :class="$vuetify.rtl ? 'pr-sm-0' : 'pl-sm-0'">
                      <v-list-item-icon :class="$vuetify.rtl ? 'ml-2' : 'mr-2'">
                        <v-icon v-if="isAtLeastSpecialCharacter" color="LabelGreen">
                          mdi-check-circle
                        </v-icon>
                        <v-icon v-else color="GreyBorder">
                          mdi-checkbox-blank-circle-outline
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="text-body-2 font-weight-regular Text02--text">
                          {{ $t("leastCharacter") }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                  </v-list>

                </v-col>
                <v-col cols="12">
                  <v-text-field
                      v-model="confirmPassword"
                      autocomplete="new-password"
                      :error-messages="confirmPasswordErrors"
                      :type="showConfirmPassword ? 'text' : 'password'"
                      :label="$t('registerNewPasswordConfirm')"
                      outlined
                      hide-details="auto"
                  >
                    <template v-slot:append>
                      <v-icon color="Text03" @click="showConfirmPassword = !showConfirmPassword">
                        {{ showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off' }}
                      </v-icon>
                    </template>
                    <template v-slot:append-outer>
                      <div :class="$vuetify.rtl ? 'mr-sm-2' : 'ml-sm-2'">
                        <v-icon v-if="!$v.confirmPassword.$invalid" color="LabelGreen">
                          mdi-check-circle
                        </v-icon>
                        <v-icon v-else color="GreyBorder">
                          mdi-checkbox-blank-circle-outline
                        </v-icon>
                      </div>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </template>
          </v-col>

          <v-col
              :class="$vuetify.rtl ? 'ml-md-auto border-left' : 'mr-md-auto border-right'"
              class="d-none d-md-block"
              cols="12"
              md="3"
          />

          <action-buttons :loading="loading" @discardChanges="discardChanges" @initUpdate="initUpdateAccountDetails"/>

        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import TabsList from "@/components/pages/AccountSettingsPage/components/TabsList";
import ActionButtons from "@/components/pages/AccountSettingsPage/components/ActionButtons";
import validationMixin from "@/mixins/validationMixin";
import {required, sameAs} from "vuelidate/lib/validators";
import {strongPassword} from "@/helpers/customValidationRules";
import {mapActions} from "vuex";
import {UserActions} from "@/store/user";

export default {
  name: "PasswordPage",

  mixins: [validationMixin],

  data() {
    return {
      loading: true,
      password: null,
      confirmPassword: null,
      currentPassword: null,
      showPassword: false,
      showConfirmPassword: false,
      showCurrentPassword: false,
    }
  },

  computed: {
    isMinLength() {
      return String(this.password).length >= 12;
    },

    isAtLeastNumber() {
      const numbers = String(this.password).replace(/\D/g, "");

      return numbers.length >= 1;
    },

    isAtLeastLetter() {
      if (this.password) {
        return /[A-Za-z]/.test(this.password);
      }

      return false;
    },

    isAtLeastSpecialCharacter() {
      let characters = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;

      if (this.password) {
        return characters.test(this.password);
      }

      return false;
    },

    passwordErrors() {
      return this.getErrorMessagesForFieldWithRules('password', {
        strongPassword,
        required,
      });
    },

    confirmPasswordErrors() {
      return this.getErrorMessagesForFieldWithRules('confirmPassword', {
        sameAsPassword: this.$t('registerPasswordConfirmationNotSame'),
        required
      });
    },

    currentPasswordErrors() {
      return this.getErrorMessagesForFieldWithRules('currentPassword', {
        required
      });
    },
  },

  validations: {
    password: {required, strongPassword},
    confirmPassword: {required, sameAsPassword: sameAs('password')},
    currentPassword: {required},
  },

  mounted() {
    this.loading = false;
  },

  components: {
    TabsList,
    ActionButtons,
  },

  methods: {
    ...mapActions({
      updateAccountDetails: `user/${UserActions.UpdateAccountDetails}`,
      logout: `user/${UserActions.Logout}`,
    }),

    discardChanges() {
      this.password = null;
      this.confirmPassword = null;
      this.currentPassword = null;
    },

    async initUpdateAccountDetails() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        this.updateAccountDetails({
          password: this.password,
          currentPassword: this.currentPassword,
        }).then((data) => {
          this.$toasted.success(data.message);
          this.loading = false;

          if (this.password && this.password.length > 0) {
            this.logout()
                .then(() => {
                  return this.$router.replace({name: 'login'});
                });
          }
        }).catch((e) => {
          this.$toasted.error(e.message);
          this.loading = false;
        });
      }
    }
  }
}
</script>

<style scoped/>