<template>
  <div class="d-flex align-center">
    <v-avatar size="44" class="justify-center align-center rounded-circle mr-4">
      <v-icon size="24" :color="iconColor">{{ icon }}</v-icon>
    </v-avatar>
    <span
      class="Text01--text" :class="isTextBold ? 'text-subtitle-2' : 'body-1'"
      v-html="textContent"
    />
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "InfoItem",

  props: {
    icon: String,
    iconColor: {
      type: String,
      default: 'Text03'
    },
    textContent: String,
    isTextBold: {
      type: Boolean,
      default: false
    },
  },
};
</script>

<style scoped lang="scss">
.v-avatar {
  border: 1px solid var(--v-GreyLine-base);
}
.v-icon {
  border-radius: unset;
}
</style>