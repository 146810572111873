import {LegalService} from "@/services/ApiServices";
import {UserActions, UserGetters} from "@/store/user";

export const LegalActions = {
    GetListOfLegalDocuments: 'getListOfLegalDocuments',
    GetPdf: 'getPdf',
    GetLegalLinks: 'getLegalLinks',
};

const actions = {
    async [LegalActions.GetListOfLegalDocuments]({dispatch, rootGetters}) {
        const associationId = rootGetters[`user/${UserGetters.AssociationMainId}`];
        return LegalService.getListOfLegalDocuments(associationId).then(({data}) => {
            dispatch(`user/${UserActions.GetListOfLegalDocuments}`, data.data, {root: true});

            return data;
        }).catch((e) => {
            throw new Error(e.response.data.message);
        });
    },

    async [LegalActions.GetPdf]({rootGetters}, type) {
        const associationId = rootGetters[`user/${UserGetters.AssociationMainId}`];
        return LegalService.getPdf(associationId, type).then(({data}) => {
            return data;
        }).catch((e) => {
            throw new Error(e.response.data.message);
        });
    },

    async [LegalActions.GetLegalLinks]() {
        return LegalService.getLegalLinks().then(({data}) => {
            return data;
        }).catch((e) => {
            throw new Error(e.response.data.message);
        });
    },
};

export default {
    namespaced: true,
    actions,
};