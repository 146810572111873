<template>
  <div>
    <SkeletonLoader v-if="loading"/>

    <WeeklyCalendar
        ref="weeklyCalendar"
        @datesUpdated="onDatesUpdated"
    />
    <v-container>
      <DetailsItem
          v-for="detail in memberDetails"
          :key="detail.date"
          :detail="detail"
          :show-lunch="showLunch"
          :show-pickup="showPickup"
          :ref="isToday(detail.date) ? 'todayCard' : null"
      />
    </v-container>
  </div>
</template>

<script>
import WeeklyCalendar from "@/components/pages/MemberPage/components/PickupTimesLunch/WeeklyCalendar.vue";
import DetailsItem from "@/components/pages/MemberPage/components/PickupTimesLunch/DetailsItem.vue";
import {removeCurrentDate} from "@/helpers/localStorageUtils";
import {mapActions, mapGetters} from "vuex";
import {PickupLunchDetailsActions, PickupLunchDetailsGetters} from "@/store/pickupLunchDetails";
import dateUtilityMixin from "@/mixins/dateUtilityMixin";

export default {
  name: "PickupTimeLunchDetails",
  components: {DetailsItem, WeeklyCalendar},

  mixins: [dateUtilityMixin],

  props: {
    showLunch: {
      type: Boolean,
      default: false,
    },
    showPickup: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      memberDetails: `pickupLunchDetails/${PickupLunchDetailsGetters.List}`,
      loading: `pickupLunchDetails/${PickupLunchDetailsGetters.Loading}`,
    }),
  },

  async mounted() {
    this.$nextTick(() => {
      const todayCardElement = this.$refs.todayCard;

      if (todayCardElement && todayCardElement[0]) {
        const headerElement = document.querySelector('header');
        const headerHeight = headerElement ? headerElement.offsetHeight : 0;
        const elementPosition = todayCardElement[0].$el.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerHeight;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
        });
      }
    });

    removeCurrentDate();
  },

  methods: {
    ...mapActions({
      fetchMemberDetails: `pickupLunchDetails/${PickupLunchDetailsActions.FetchPickupLunchDetails}`,
    }),

    async fetchDetailsByDateRange(startDate, endDate) {
      await this.fetchMemberDetails({
        memberId: this.$route.params.id,
        startDate: startDate,
        endDate: endDate
      });
    },

    async onDatesUpdated({startDate, endDate}) {
      await this.fetchDetailsByDateRange(startDate, endDate);
    },
  },
};
</script>