
const initialState = {
    query: '',
    count: null,
};

export const GlobalGetters = {
    Query: 'query',
    UnreadChatsCount: 'unreadChatsCount',
};

export const GlobalActions = {
    UpdateQuery: 'updateQuery',
    UpdateUnreadChatsCount: 'updateUnreadChatsCount'
};

export const GlobalMutations = {
    SetQuery: 'setQuery',
    SetUnreadChatsCount: 'setUnreadChatsCount',
};

const state = { ...initialState };

const getters = {
    [GlobalGetters.Query](state) {
        return state.query;
    },
    [GlobalGetters.UnreadChatsCount](state) {
        return state.count;
    },
};

const actions = {
    [GlobalActions.UpdateQuery]({commit}, query) {
        commit(GlobalMutations.SetQuery, query);
    },
    [GlobalActions.UpdateUnreadChatsCount]({commit}, count) {
        commit(GlobalMutations.SetUnreadChatsCount, count);
    }
}

const mutations = {
    [GlobalMutations.SetQuery](state, query) {
        state.query = query;
    },
    [GlobalMutations.SetUnreadChatsCount](state, count) {
        state.count = count;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
