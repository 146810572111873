<template>
  <full-width-page-form-layout :max-width="588" :loading="loading">
    <v-form @submit.prevent="initRegistration" class="single-column-form">
      <v-container>
        <v-row no-gutters>
          <v-col col="12" class="d-flex justify-space-between align-center">
            <div class="text-sm-h2 text-subtitle-1 Text01--text">{{ $t('registerTitle') }}</div>
            <language-changer/>
          </v-col>

          <v-col cols="12" class="mt-6">
            <v-text-field
                v-model="firstName"
                :error-messages="firstNameErrors"
                :label="$t('registerFirstName')"
                required
                outlined
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
                v-model="lastName"
                :error-messages="lastNameErrors"
                :label="$t('registerLastName')"
                required
                outlined
            />
          </v-col>
          <template v-if="!hasPassword">
            <v-col cols="12" md="6" :class="$vuetify.rtl ? 'pl-md-3' : 'pr-md-3'">
              <v-text-field
                  v-model="password"
                  :error-messages="passwordErrors"
                  :label="$t('registerPassword')"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  @click:append="showPassword = !showPassword"
                  outlined
              />
            </v-col>
            <v-col cols="12" md="6" :class="$vuetify.rtl ? 'pr-md-3' : 'pl-md-3'">
              <v-text-field
                  v-model="confirmPassword"
                  :error-messages="confirmPasswordErrors"
                  :label="$t('registerPasswordConfirm')"
                  :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showConfirmPassword ? 'text' : 'password'"
                  @click:append="showConfirmPassword = !showConfirmPassword"
                  outlined
              />
            </v-col>
          </template>
          <v-col cols="12">
            <v-text-field
                v-model="code"
                :error-messages="codeErrors"
                :label="$t('registerCode')"
                required
                outlined
            />
          </v-col>
          <template v-if="hasPassword">
            <v-col cols="12" md="12" :class="$vuetify.rtl ? 'pl-md-3' : 'pr-md-3'">
              <p class="subtitle-2">{{ $t('registerPasswordNotRequired') }}</p>
            </v-col>
          </template>
          <v-col cols="12">
            <v-checkbox
                v-model="termAndConditionsAccept"
                :error-messages="termAndConditionsAcceptErrors"
                class="mt-0"
            >
              <template v-slot:label>
                <PrivacyTerms class="text-body-1"/>
              </template>
            </v-checkbox>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-btn
                :loading="submitLoading"
                class="w-100 mt-6"
                type="submit"
                color="primary"
            >{{ $t('registerSend') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </full-width-page-form-layout>
</template>

<script>
import FullWidthPageFormLayout from "@/components/FullWidthPageFormLayout";
import PrivacyTerms from "@/components/pages/RegisterPage/components/PrivacyTerms";
import validationMixin from "@/mixins/validationMixin";
import {mapActions, mapMutations} from 'vuex';
import {UserActions} from '@/store/user';
import {required, sameAs} from 'vuelidate/lib/validators';
import {strongPassword} from "@/helpers/customValidationRules";
import LanguageChanger from "@/components/LanguageChanger";

export default {
  components: {
    PrivacyTerms,
    FullWidthPageFormLayout,
    LanguageChanger
  },
  mixins: [validationMixin],
  data() {
    return {
      firstName: null,
      lastName: null,
      password: null,
      confirmPassword: null,
      code: null,
      submitLoading: false,
      loading: true,
      termAndConditionsAccept: false,
      showPassword: false,
      showConfirmPassword: false,
      hasPassword: false,
      associationId: null,
    }
  },
  computed: {
    firstNameErrors() {
      return this.getErrorMessagesForFieldWithRules('firstName', {
        required
      });
    },
    lastNameErrors() {
      return this.getErrorMessagesForFieldWithRules('lastName', {
        required
      });
    },
    passwordErrors() {
      return this.getErrorMessagesForFieldWithRules('password', {
        required,
        strongPassword,
      });
    },
    confirmPasswordErrors() {
      return this.getErrorMessagesForFieldWithRules('confirmPassword', {
        required,
        sameAsPassword: this.$t('registerPasswordConfirmationNotSame')
      });
    },
    codeErrors() {
      return this.getErrorMessagesForFieldWithRules('code', {
        required
      });
    },
    termAndConditionsAcceptErrors() {
      return this.getErrorMessagesForFieldWithRules('termAndConditionsAccept', {
        checked: this.$t('registerTermsAndConditionsNotAccepted')
      });
    },
    passwordValidationRule() {
      if (this.hasPassword) {
        return {};
      }

      return {
        required,
        strongPassword,
      };
    },
    confirmPasswordValidationRule() {
      if (this.hasPassword) {
        return {};
      }

      return {
        required,
        sameAsPassword: sameAs('password'),
      };
    }
  },
  methods: {
    ...mapActions({
      fetchUserInfoByToken: `user/${UserActions.FetchUserInfoByToken}`,
      registerUser: `user/${UserActions.RegisterUser}`,
    }),
    ...mapMutations("user", {
      setAssociationMainId: "setAssociationMainId",
    }),
    initRegistration() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.submitLoading = true;

        this.registerUser({
          firstName: this.firstName,
          lastName: this.lastName,
          password: this.password,
          code: this.code,
          token: this.$route.params.token
        }).then(() => {
          this.submitLoading = false;
          this.$router.replace({name: 'login'}, () => {
            this.$toasted.success(this.$t('registerSuccess'));
          })
        }).catch((e) => {
          this.$toasted.error(e.message);
          this.submitLoading = false;
        })
      }
    },
    removePassword() {
      if (this.hasPassword) {
        this.passwordErrors = null;
        this.confirmPassword = null;
      }
    }
  },
  validations() {
    return {
      firstName: {required},
      lastName: {required},
      password: this.passwordValidationRule,
      confirmPassword: this.confirmPasswordValidationRule,
      code: {required},
      termAndConditionsAccept: {checked: (val) => val},
    }
  },
  mounted() {
    this.fetchUserInfoByToken(this.$route.params.token)
        .then((data) => {
          this.firstName = data.first_name;
          this.lastName = data.last_name;
          this.hasPassword = data.hasPassword;
          this.removePassword();
          this.loading = false;

          this.setAssociationMainId(data.association_id);
        })
        .catch(() => {
          this.submitLoading = false;
          this.$router.replace({name: '404'}, () => {
            this.$toasted.error(this.$t('registerInvalidLink'));
          })
        });
  }
}
</script>
