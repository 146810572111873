<template>
  <v-overlay
      :value="loading"
      absolute
      color="SystemWhite"
      opacity="0.8"
      z-index="3"
  >
    <lottie-animation
        :loop="true"
        :width="350"
        :height="350"
        path="lf30_hrlvzmbe.json"
    />
  </v-overlay>
</template>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

export default {
  name: "SkeletonLoader",

  props: {
    loading: {
      type: Boolean,
      default: true
    }
  },

  components: {
    LottieAnimation,
  },
}
</script>

<style scoped>

</style>