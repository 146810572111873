import {UserGetters} from "@/store/user";
import {FoodPlansService} from "@/services/ApiServices";

const initialState = {
    foodPlans: [],
};

export const FoodPlansActions = {
    FetchFoodPlans: 'fetchFoodPlans',
    FetchFoodPlanPdf: 'fetchFoodPlanPdf',
};

const state = {...initialState};

const actions = {
    async [FoodPlansActions.FetchFoodPlans]({rootGetters}, offset = 0) {
        const associationId = rootGetters[`user/${UserGetters.AssociationId}`];
        const authenticatedUser = rootGetters[`user/${UserGetters.User}`];

        return FoodPlansService.getFoodPlans(associationId, authenticatedUser.user_id, offset).then(({data}) => {
            return data.items;
        }).catch((e) => {
            throw new Error(e.response.data.message);
        });
    },

    async [FoodPlansActions.FetchFoodPlanPdf]({rootGetters}, foodPlanId) {
        const associationId = rootGetters[`user/${UserGetters.AssociationId}`];
        const authenticatedUser = rootGetters[`user/${UserGetters.User}`];

        return FoodPlansService.getFoodPlanPdf(associationId, authenticatedUser.user_id, foodPlanId).then(({data}) => {
            return data;
        }).catch((e) => {
            throw new Error(e.response.data.message);
        });
    },
};

export default {
    namespaced: true,
    state,
    actions,
};
