const colors = Object.freeze({
    SystemCTA: "#0652C2",
    SystemWhite: "#FFFFFF",
    SystemBG: "#EAF1FD",

    Text01: "#061F4B",
    Text02: "#4F6380",
    Text03: "#61738D",
    Text04: "#183E77",
    Text05: "#BDD1EF",

    GreyFooter: "#F5F6F8",
    GreyBorder: "#AAB3C5",
    GreyLine: "#DBDBE2",

    IconsBlue: "#5A85FC",
    IconsLightBlue: "#D0E0FF",

    NewsSkyBlue: "#F5F9FF",
    NewsLightGreen: "#E8F6EF",

    MenuHover: "#226AD6",

    Message01: "#F5F6F8",

    AlertRed: "#CC0001",

    AvatarsBerry: "#BB76E5",
    AvatarsGreen: "#80C229",
    AvatarsBlue: "#4294FF",
    AvatarsPurple: "#6369E1",

    LabelRed: "#E96565",
    LabelGreen: "#057F3E",
    LabelYellow: "#FFB300",
    LabelOrange: "#EB7100",
    LabelGray: "#EEF2F9",
    LabelBlack: "#00050B",

    BtnDisable: "#99BEF4",

    calendarPaleBlue: "#C8F1FF",
    calendarDeepSkyBlue: "#00BFFF",
    calendarUranianBlue: "#C3DEFF",
    calendarRoyalBlue: "#0067ED",
    calendarPaleViolet: "#E3CDF8",
    calendarUltraViolet: "#645394",
    calendarPalePurple: "#FFB9FF",
    calendarPurple: "#B600B6",
    calendarFairyTale: "#FFC8E4",
    calendarRose: "#FF0080",
    calendarPaleRed: "#FFC7C7",
    calendarRed: "#C50000",
    calendarPaleOrange: "#FFE6D8",
    calendarOrange: "#EC5800",
    calendarAquamarine: "#A9FBD0",
    calendarPigmentGreen: "#06994B",
    calendarMintGreen: "#CAE9E8",
    calendarMyrtleGreen: "#317873",
    calendarCornsilk: "#FFF4D1",
    calendarAmber: "#F5B800",
    calendarRichBlack: "#000B0F",
    calendarTimberwolf: "#E5EBED"
});

const vuetify = Object.freeze({
    primary: {
        base: colors.SystemCTA,
        lighten1: colors.MenuHover,
    },
    error: {
        base: colors.AlertRed,
        lighten1: colors.LabelRed,
    },
    warning: {
        base: colors.LabelOrange,
        lighten2: colors.LabelYellow,
    },
});

export default { ...colors, ...vuetify };
