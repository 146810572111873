<template>
  <v-tooltip content-class="text-caption" :disabled="!showTooltip" :[tooltipPosition]="true" color="Text04" v-if="newsItem.is_pinned">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          color="primary"
          icon
          @click.stop.prevent="togglePinNewsItem(false)"
          v-bind="attrs"
          v-on="on"
      >
        <v-icon style="transform: rotate(45deg);">
          mdi-pin
        </v-icon>
      </v-btn>
    </template>
    {{ $t('unpinNewsItem') }}
  </v-tooltip>

  <v-tooltip content-class="text-caption" :disabled="!showTooltip" :[tooltipPosition]="true" color="Text04" v-else>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          icon
          color="primary"
          @click.stop.prevent="togglePinNewsItem(true)"
          v-bind="attrs"
          v-on="on"
      >
        <v-icon :size="iconSize">mdi-pin-outline</v-icon>
      </v-btn>
    </template>
    {{ $t('pinNewsItem') }}
  </v-tooltip>
</template>

<script>
import {mapActions} from "vuex";
import {NewsActions} from "@/store/news";

export default {
  name: "PinUnpinButton",

  props: {
    newsItem: {
      type: Object,
      required: true
    },
    showTooltip: {
      type: Boolean,
      default: false
    },
    iconSize: {
      type: String,
      required: false,
      default: "24"
    },
    tooltipPosition: {
      type: String,
      required: false,
      default: 'bottom'
    }
  },

  methods: {
    ...mapActions({
      pinUnpinNewsItem: `news/${NewsActions.PinUnpinNewsItem}`,
      fetchLastPinnedNews: `news/${NewsActions.FetchLastPinnedNews}`,
    }),

    async togglePinNewsItem(isPinned) {
      const {id, _id} = this.newsItem;

      this.newsItem.is_pinned = isPinned;

      await this.pinUnpinNewsItem({newsId: id || _id, isPinned});

      await this.fetchLastPinnedNews();

      this.$toasted.clear();

      this.$emit('togglePinNewsItem', {id, isPinned});

      if (isPinned) {
        this.$toasted.info(this.$t('newsItemPinned'));
      } else {
        this.$toasted.info(this.$t('newsItemUnpinned'));
      }
    },
  }
}
</script>

<style scoped/>