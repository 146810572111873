<template>
  <div class="pt-2">
    <template v-if="showTranslationWarning">
      <v-alert
          rounded="0"
          dense
          color="#EEF5FF"
          class="translation-warning pa-3"
          type="info"
      >
        {{ $t('translationWarningText') }}
      </v-alert>

      <a href="#" @click.stop.prevent="showOriginal()" class="text-subtitle-2 text-decoration-none">
        {{ $t('seeOriginalText') }}
      </a>
    </template>
    <template v-else>
      <a href="#" @click.stop.prevent="translate()" class="text-subtitle-2 text-decoration-none">
        {{ $t('translateBtn') }}
      </a>
    </template>

    <template v-if="isLanguageSelectionRequired">
      <translation-component translation-lang-selector
                             language-list-in-modal
                             only-modal
                             @languageSelected="languageSelected()"/>
    </template>
  </div>
</template>

<script>
import {InAppTranslationLangKey} from "@/constants";
import TranslationComponent from "@/components/pages/ProfileSettingsPage/components/TranslationComponent";

export default {
  name: "TranslationBtn",

  components: {
    TranslationComponent,
  },

  data() {
    return {
      isLanguageSelectionRequired: false,
      showTranslationWarning: false,
    }
  },

  mounted() {
    if (this.$route.params.translationLang) {
      this.showTranslationWarning = true;
    }
  },

  methods: {
    async translate() {
      const inAppTranslationLang = window.localStorage.getItem(InAppTranslationLangKey) || null;

      if (!inAppTranslationLang) {
        this.isLanguageSelectionRequired = true;
      }

      await this.reloadPage(inAppTranslationLang);
    },

    async languageSelected() {
      await this.translate();
    },

    async showOriginal() {
      await this.reloadPage(null);
    },

    async reloadPage(inAppTranslationLang) {
      await this.$router.push({
        name: this.$route.name,
        params: {id: this.$route.params.id, translationLang: inAppTranslationLang}
      });

      this.$emit('ready');
    }
  }
}
</script>