import {ValidationObserver, ValidationProvider, extend, setInteractionMode} from "vee-validate";
import {required, email, min, max, size} from "vee-validate/dist/rules";
import Vue from "vue";
import i18next from "i18next";

setInteractionMode("lazy");

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

extend("required", required);
extend("email", email);
extend("min", min);
extend("max", max);
extend("size", size);

extend("phoneNumber", {
    validate(value) {
        let matches = (value || "").match(/^[\d*#+()., -]+$/);

        return matches !== null;
    },
    message: () => i18next.t("phoneNumberInvalidFormat")
});