<template>
  <div class="v-text-field" v-show="errors.length">
    <div class="v-text-field__details">
      <div class="v-messages theme--light error--text" role="alert">
        <div class="v-messages__wrapper">
          <div class="v-messages__message" v-for="(error, index) in errors" :key="index">
            {{ error }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomErrorMessages",

  props: {
    errors: {
      type: Array,
      required: true
    }
  }
};
</script>

<style scoped />
