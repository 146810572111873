<template>
  <div>
    <news-response-avatars
        v-if="response.member_ids.length"
        :option-chosen-key="getOptionsChosenKey"
        :children-names="childrenNames"
        :response="response"
    />

    <div
        class="text-body-1 Text01--text mt-2"
        v-if="response.member_ids.length"
        v-html="$t(getResponseKey, {children: childrenNames, response_date: $options.filters.date(response.date_answered, $t('normalDateFormatWithMonth')), response_time: $options.filters.date(response.date_answered, $t('normalTimeFormat'))})"
    />
    <div
        class="text-body-1 Text01--text mt-2"
        v-else
        v-html="$t(getResponseKey, {response_date: $options.filters.date(response.date_answered, $t('normalDateFormatWithMonth')), response_time: $options.filters.date(response.date_answered, $t('normalTimeFormat'))})"
    />
  </div>
</template>

<script>
import NewsResponseAvatars from "@/components/pages/NewsItemPage/components/NewsResponseAvatars";
import {mapGetters} from "vuex";
import {UserGetters} from "@/store/user";
import {availableChildren} from "@/helpers/childrenConditions";

export default {
  name: "AnswerHeader",

  components: {
    NewsResponseAvatars
  },

  props: {
    childrenNames: {
      required: true,
    },
    response: {
      required: true,
    },
    newsItem: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      members: `user/${UserGetters.Members}`,
    }),

    membersList() {
      return availableChildren(this.members, this.newsItem.groups);
    },

    getOptionsChosenKey() {
      if (this.response.member_ids.length >= 1) {
        return 'newsResponseInstMultiOptionAnsweredMembers';
      }

      return 'newsResponseInstMultiOptionAnswered'
    },

    getResponseKey() {
      if (this.membersList.length >= 1 && this.newsItem.show_signature) {
        return 'newsResponseTxtReceivedWithSignature';
      } else if (this.membersList.length >= 1 && !this.newsItem.show_signature) {
        return 'newsResponseTxtReceived';
      } else if (this.membersList.length == 0 && this.newsItem.show_signature) {
        return 'newsResponseTxtReceivedWithSignature';
      }

      return 'newsResponseTxtReceived'
    },
  }
}
</script>