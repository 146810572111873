<template>
  <div :class="hasResponse ? 'mb-2' : 'mb-6'">
    <div :class="hasResponse ? 'mb-5' : 'mb-2'" v-if="readonly">
      <answer-header
          :children-names="responseChildrenNames"
          :response="response"
          :news-item="newsItem"
          v-if="hasResponse"
      />
    </div>

    <div class="mb-4 text-subtitle-1 Text01--text" v-else-if="!isLimitReached">
      {{ $t('newsResponseInstMultiOption') }}
    </div>

    <p class="text-subtitle-1 Text01--text" v-if="hasResponse">
      {{ $t('newsResponseInstMultiOptionAnswered') }}
    </p>

    <v-row no-gutters :class="{'direction-rtl': isRTL, 'has-response': hasResponse}">
      <v-col
          :class="{'selected': mutableValue && mutableValue.includes(option.id)}"
          cols="12"
          v-for="(option, index) in newsItem.options"
          :key="index" class="mb-2"
      >
        <v-checkbox
            v-model="mutableValue"
            :value="option.id"
            :error="fieldErrors.length > 0"
            class="mt-0 options-checkbox"
            :class="{rtl: $vuetify.rtl || isRTL}"
            :disabled="isDisabled(option)"
            hide-details="auto"
        >
          <template v-slot:label>
            <span class="text-body-1 Text01--text" :class="$vuetify.rtl ? 'text-right' : 'text-left'">
              {{ option.text }}
            </span>
          </template>
        </v-checkbox>

        <div :class="$vuetify.rtl ? 'ml-9' : 'mr-9'" class="mb-4 mt-2" v-if="option.limited && !readonly">
          <v-progress-linear
              background-color="GreyLine"
              height="6"
              :color="getProgressColor(option)"
              rounded
              :value="getProgressValue(option)"
          />
          <div class="mt-2">
            <span class="Text01--text text-body-2">
              {{ $t('contributed', {options_chosen: getChoicesDone(option)}) }}
            </span>
            •
            <span class="Text01--text text-body-2 font-weight-medium">
              {{$t('still_needs_webapp', {options_available: option.max_choices - getChoicesDone(option)}) }}
            </span>
          </div>
        </div>

        <v-divider class="mt-3" v-if="index < newsItem.options.length - 1" :key="option.id"/>

      </v-col>
      <v-col cols="12" v-if="fieldErrors.length > 0">
        <small class="error--text">{{ fieldErrors.join(', ') }}</small>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AbstractOptions from "@/components/pages/NewsItemPage/components/AbstractOptions";
import AnswerHeader from "@/components/pages/NewsItemPage/components/AnswerHeader";
import {isInAppTranslationRequestedLangRTL} from "@/helpers/helpers";

export default {
  name: "CheckboxOptions",

  components: {
    AnswerHeader,
  },

  extends: AbstractOptions,

  props: {
    value: {
      required: true,
      type: Array,
    },
  },

  data() {
    return {
      isRTL: false,
    }
  },

  mounted() {
    this.isRTL = isInAppTranslationRequestedLangRTL(this.$route.params.translationLang);
  },

  computed: {
    mutableValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      }
    },
    fieldErrors() {
      if (!this.validationResult) {
        return [];
      }

      return this.getErrorMessagesForField(this.validationResult, this.$t('newsResponseErrorSelect'));
    }
  },
  methods: {
    getProgressColor(optionItem) {
      if (this.getProgressValue(optionItem) === 100) {
        return 'LabelGreen';
      }
      return 'SystemCTA';
    },

    getChoicesDone(optionItem) {
      let choicesDone = parseInt(optionItem.choices_done);
      if (this.value.includes(optionItem.id)) {
        choicesDone++;
      }
      return choicesDone;
    },

    getProgressValue(optionItem) {
      return this.getChoicesDone(optionItem) * 100 / parseInt(optionItem.max_choices);
    },

    isDisabled(optionItem) {
      if (this.readonly) {
        return true;
      }

      if (!optionItem.limited) {
        return false;
      }

      return optionItem.choices_done === optionItem.max_choices;
    },
  }
}
</script>

<style scoped lang="scss">
.row {
  .options-checkbox {
    &.v-input--is-disabled {
      ::v-deep {
        .v-input__control {
          .v-input__slot {
            .v-input--selection-controls__input {
              display: none;
            }
          }
        }
      }
    }
  }

  &.has-response {
    > div {
      &:not(.selected) {
        display: none;
      }

      .v-input {
        ::v-deep {
          .v-input__control {
            .v-input__slot {
              .v-input--selection-controls__input {
                display: none;
              }
            }
          }
        }
      }

      hr {
        display: none;
      }
    }
  }
}
</style>