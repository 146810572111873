function toCapitalizedWords(string) {
    let words = string.match(/[A-Za-z][a-z]*/g) || [];

    return words.map(capitalizeFirstLetter).join(" ");
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export default {
    methods: {
        getErrorMessagesForField(field, fieldName) {
            let errors = [];
            if (!field.$dirty) {
                return errors;
            }

            for (const [rule] of Object.entries(field.$params)) {
                if (field[rule] === false) {
                    if (rule === 'required') {
                        errors.push(fieldName);
                    } else {
                        errors.push(rule);
                    }
                }
            }

            return errors;
        },
        getErrorMessage(rule, fieldName) {
            switch (rule) {
                case 'required': {
                    return this.$t('fieldIsRequired');
                }
                case 'strongPassword': {
                    return this.$t('registerPasswordNotCompliant');
                }
                case 'email': {
                    return this.$t('validEmailAddress', {fieldname: toCapitalizedWords(fieldName)});
                }
                case 'sameAsPassword': {
                    return this.$t('registerPasswordConfirmationNotSame');
                }
                case 'maxLengthValue': {
                    return this.$t('maxLengthValue');
                }
                default:
                    return rule;
            }
        },
        getErrorMessagesForFieldWithRules(fieldName, rules) {
            const errors = [];

            if (!this.$v[fieldName].$dirty) {
                return errors;
            }
            Object.keys(rules).forEach((rule) => {
                if (!this.$v[fieldName][rule]) {
                    if (typeof rules[rule] !== "string") {
                        errors.push(this.getErrorMessage(rule, fieldName));
                    } else {
                        errors.push(rules[rule]);
                    }
                }
            });

            return errors;
        }
    }
}
