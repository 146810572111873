var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-banner',{attrs:{"app":"","sticky":""}},[_c('div',{staticClass:"overflow-y-auto news-filter",staticStyle:{"max-height":"60vh"}},[(_vm.pinnedNewsCount)?[_c('v-sheet',{staticClass:"text-subtitle-2 Text01--text py-3 d-flex justify-space-between align-center",class:_vm.$vuetify.rtl ? 'pr-3 pl-1' : 'pl-3 pr-1',attrs:{"color":"SystemBG"}},[_vm._v(" "+_vm._s(_vm.$t('pinnedNewsTitle'))+" "),_c('v-btn',{staticClass:"text-body-2 font-weight-medium text-capitalize",attrs:{"color":"primary","small":"","text":"","to":{name: 'news.pinned'}}},[_vm._v(" "+_vm._s(_vm.$t('showAll'))+" "),(_vm.pinnedNewsCount)?[_vm._v("("+_vm._s(_vm.pinnedNewsCount)+")")]:_vm._e()],2)],1),_c('v-list',{staticClass:"py-0"},_vm._l((_vm.lastPinnedNewsItems),function(ref){
var id = ref.id;
var title = ref.title;
return _c('v-list-item',{key:id,attrs:{"to":{name: 'news.show', params: {id: id}}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-subtitle-2 Text01--text"},[_vm._v(" "+_vm._s(title)+" ")])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"small":"","icon":"","color":"primary"}},[_c('v-icon',[_vm._v(" mdi-chevron-right ")])],1)],1)],1)}),1)]:_vm._e(),_c('v-sheet',{staticClass:"text-subtitle-2 Text01--text pa-3",attrs:{"color":"SystemBG"}},[_vm._v(" "+_vm._s(_vm.$t("filter_news"))+" "),(_vm.resetButtonCounter)?[_vm._v("("+_vm._s(_vm.resetButtonCounter)+")")]:_vm._e()],2),_c('v-expansion-panels',{attrs:{"multiple":"","accordion":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"text-subtitle-2 Text01--text pa-3",attrs:{"hide-actions":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var open = ref.open;
return [_c('div',{staticClass:"d-flex align-center justify-space-between"},[_vm._v(" "+_vm._s(_vm.$t('filterByType'))+" "),(_vm.type !== 'all')?[_vm._v("(1)")]:_vm._e(),_c('v-btn',{attrs:{"small":"","icon":"","color":"primary"}},[_c('v-icon',[_vm._v(" "+_vm._s(open ? "mdi-chevron-down" : "mdi-chevron-right")+" ")])],1)],2)]}}])}),_c('v-expansion-panel-content',[_c('v-radio-group',{staticClass:"mt-0 px-3",attrs:{"hide-details":"","mandatory":""},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}},[_c('v-radio',{attrs:{"label":_vm.$t('all'),"value":"all"}}),_c('v-divider',{staticClass:"mb-4 mt-2"}),_c('v-radio',{attrs:{"label":_vm.$t('info'),"value":"article"}}),_c('v-divider',{staticClass:"mb-4 mt-2"}),_c('v-radio',{attrs:{"label":_vm.$t('responses'),"value":"response"}}),_c('v-divider',{staticClass:"mb-4 mt-2"})],1)],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"text-subtitle-2 Text01--text pa-3",attrs:{"hide-actions":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var open = ref.open;
return [_c('div',{staticClass:"d-flex align-center justify-space-between"},[_vm._v(" "+_vm._s(_vm.$t('filterByStatus'))+" "),(_vm.statuses.length)?[_vm._v("("+_vm._s(_vm.statuses.length)+")")]:_vm._e(),_c('v-btn',{attrs:{"small":"","icon":"","color":"primary"}},[_c('v-icon',[_vm._v(" "+_vm._s(open ? "mdi-chevron-down" : "mdi-chevron-right")+" ")])],1)],2)]}}])}),_c('v-expansion-panel-content',[_c('v-list',{staticClass:"pt-0",attrs:{"flat":""}},[_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.statuses),callback:function ($$v) {_vm.statuses=$$v},expression:"statuses"}},[_c('v-list-item',{staticClass:"px-3",attrs:{"value":"unread","ripple":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('unread')))])],1),_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary"}})],1)]}}])}),_c('v-divider',{staticClass:"mx-3"}),_c('v-list-item',{staticClass:"px-3",attrs:{"value":"favourite","ripple":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('favourites')))])],1),_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary"}})],1)]}}])}),_c('v-divider',{staticClass:"mx-3"}),_c('v-list-item',{staticClass:"px-3",attrs:{"value":"hidden","ripple":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('hidden')))])],1),_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary"}})],1)]}}])}),_c('v-divider',{staticClass:"mx-3"})],1)],1)],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"text-subtitle-2 Text01--text pa-3",attrs:{"hide-actions":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var open = ref.open;
return [_c('div',{staticClass:"d-flex align-center justify-space-between"},[_vm._v(" "+_vm._s(_vm.$t('byGroups'))+" "),(_vm.recipientsCounter)?[_vm._v("("+_vm._s(_vm.recipientsCounter)+")")]:_vm._e(),_c('v-btn',{attrs:{"small":"","icon":"","color":"primary"}},[_c('v-icon',[_vm._v(" "+_vm._s(open ? "mdi-chevron-down" : "mdi-chevron-right")+" ")])],1)],2)]}}])}),_c('v-expansion-panel-content',[(_vm.hasDirectNews)?_c('v-list',{staticClass:"py-0",attrs:{"flat":""}},[_c('v-list-item',{staticClass:"px-3",attrs:{"ripple":false}},[_c('v-list-item-content',{staticClass:"cursor-pointer",on:{"click":function($event){_vm.isDirectNews = !_vm.isDirectNews}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('displayIndividualNews')))])],1),_c('v-list-item-action',[_c('v-checkbox',{attrs:{"color":"primary"},model:{value:(_vm.isDirectNews),callback:function ($$v) {_vm.isDirectNews=$$v},expression:"isDirectNews"}})],1)],1),_c('v-divider',{staticClass:"mx-3"})],1):_vm._e(),_c('v-list',{staticClass:"pt-0",attrs:{"flat":""}},[_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.recipients),callback:function ($$v) {_vm.recipients=$$v},expression:"recipients"}},_vm._l((_vm.groups),function(ref){
var id = ref.id;
var name = ref.name;
return _c('div',{key:id},[_c('v-list-item',{staticClass:"px-3",attrs:{"value":id,"ripple":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(name))])],1),_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary"}})],1)]}}],null,true)}),_c('v-divider',{staticClass:"mx-3"})],1)}),0)],1)],1)],1)],1)],2),_c('v-row',{staticClass:"mt-5"},[_c('v-col',[_c('v-btn',{attrs:{"color":"primary","outlined":"","tile":"","block":"","disabled":!_vm.resetButtonCounter},on:{"click":_vm.resetFilters}},[_vm._v(" "+_vm._s(_vm.$t('reset'))+" "),(_vm.resetButtonCounter)?[_vm._v("("+_vm._s(_vm.resetButtonCounter)+")")]:_vm._e()],2)],1),_c('v-col',[_c('v-btn',{attrs:{"color":"primary","depressed":"","tile":"","block":""},on:{"click":_vm.saveFilters}},[_vm._v(" "+_vm._s(_vm.$t('apply'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }