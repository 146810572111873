export function isInTargetGroup(child, targetGroups) {
    if (targetGroups && targetGroups.length === 0) {
        return true;
    }

    const intersectedGroup = child.groups.find((childGroup) => {
        const targetGroup = targetGroups ? targetGroups.find(targetGroup => targetGroup.id === childGroup.id) : null;
        return (typeof targetGroup !== "undefined");
    })

    return (typeof intersectedGroup !== "undefined");
}

export function availableChildrenCount(members, targetGroups) {
    return members.reduce((accumulatedCount, currentChild) => {
        if (isInTargetGroup(currentChild, targetGroups)) {
            return ++accumulatedCount;
        }
        return accumulatedCount;
    }, 0)
}

export function availableChildren(members, targetGroups) {
    return members.filter((member) => {
        if (isInTargetGroup(member, targetGroups)) {
            return member;
        }
    });
}

export function childNameById(members, id) {
    const child = members.find((member) => member.id === id);
    if (child) {
        return [child.first_name, child.last_name].join(' ');
    }
    return id;
}

export function prettyChildrenNames(members, ids) {
    if (ids.length < 2) {
        return '<strong>' + childNameById(members, ids[0]) + '</strong>';
    }
    return ids
        .slice(0, -1)
        .map((id) => '<strong>' + childNameById(members, id) + '</strong>')
        .join(', ') + ' and <strong>' + childNameById(members, ids.slice(-1)[0]) + '</strong>';
}

export function prettyChildGroups(groups) {
    return groups.map(group => group.name).join(', ');
}

export function avatarSrc(avatar) {
    return `/images/avatars/avatars${avatar}.png`;
}