import {UserGetters} from "@/store/user";
import {TimetablesService} from "@/services/ApiServices";

const initialState = {
    timetables: [],
};

export const TimetablesActions = {
    FetchTimetables: 'fetchTimetables',
    FetchTimetablePdf: 'fetchTimetablePdf',
};

const state = {...initialState};

const actions = {
    async [TimetablesActions.FetchTimetables]({rootGetters}, offset = 0) {
        const associationId = rootGetters[`user/${UserGetters.AssociationId}`];
        const authenticatedUser = rootGetters[`user/${UserGetters.User}`];

        return TimetablesService.getTimetables(associationId, authenticatedUser.user_id, offset).then(({data}) => {
            return data.items;
        }).catch((e) => {
            if (e.response.status === 403) {
                throw new Error(e.response.statusText);
            } else {
                throw new Error(e.response.data.message);
            }
        });
    },

    async [TimetablesActions.FetchTimetablePdf]({rootGetters}, timetableId) {
        const associationId = rootGetters[`user/${UserGetters.AssociationId}`];
        const authenticatedUser = rootGetters[`user/${UserGetters.User}`];

        return TimetablesService.getTimetablePdf(associationId, authenticatedUser.user_id, timetableId).then(({data}) => {
            return data;
        }).catch((e) => {
            if (e.response.status === 403) {
                throw new Error(e.response.statusText);
            } else {
                throw new Error(e.response.data.message);
            }
        });
    },
};

export default {
    namespaced: true,
    state,
    actions,
};
