var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-banner',{directives:[{name:"sticky",rawName:"v-sticky"}],staticClass:"mb-5 SystemWhite",attrs:{"app":"","sticky-z-index":2,"sticky-offset":"{top: 65}"}},[(_vm.pinnedNewsCount)?[_c('v-btn',{staticClass:"px-3 SystemBG",attrs:{"ripple":false,"text":"","block":"","to":{name: 'news.pinned'}}},[_c('span',{staticClass:"text-subtitle-2 font-weight-medium text-capitalize Text01--text"},[_vm._v(" "+_vm._s(_vm.$t('pinnedNewsTitle'))+" ")]),_c('v-spacer'),(_vm.pinnedNewsCount)?_c('span',{staticClass:"text-body-2 font-weight-medium text-capitalize primary--text"},[_vm._v(" "+_vm._s(_vm.$t('showAll'))+" "+_vm._s(_vm.pinnedNewsCount)+" ")]):_vm._e()],1),_c('v-divider')]:_vm._e(),_c('confirmation-modal',{attrs:{"max-width":544,"title":_vm.$t('logoutTitle'),"label":_vm.$t('logoutSubtitle'),"apply-button-color":"primary"},on:{"apply":_vm.initLogout},model:{value:(_vm.logoutConfirmationDialog),callback:function ($$v) {_vm.logoutConfirmationDialog=$$v},expression:"logoutConfirmationDialog"}}),_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-sheet',_vm._g(_vm._b({staticClass:"text-subtitle-2 Text01--text pa-3 d-flex justify-space-between",attrs:{"color":"SystemBG"}},'v-sheet',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("filter_news"))+" "),(_vm.resetButtonCounter)?[_vm._v("("+_vm._s(_vm.resetButtonCounter)+")")]:_vm._e(),_c('v-icon',{attrs:{"color":"SystemCTA"}},[_vm._v(_vm._s(_vm.$vuetify.rtl ? 'mdi-chevron-left' : 'mdi-chevron-right'))])],2)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"dark":"","height":"66","color":"primary","rounded":"0"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v(_vm._s(_vm.$vuetify.rtl ? 'mdi-arrow-right' : 'mdi-arrow-left'))])],1),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("filter_news")))]),_c('v-spacer'),(_vm.isAuthenticated)?_c('v-menu',{attrs:{"tile":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({attrs:{"size":"50"}},'v-avatar',attrs,false),on),[_c('avatar-component',{attrs:{"avatar":_vm.avatar,"name":{firstName: _vm.firstName, lastName: _vm.lastName},"size":48,"font-size":25,"color":"#3ba8ff"}})],1)]}}],null,false,1138636560)},[_c('v-list',{staticClass:"py-0"},[_c('v-list-item',{attrs:{"to":{name: 'profile_settings'}}},[_c('v-list-item-icon',{class:_vm.$vuetify.rtl ? 'ml-3' : 'mr-3'},[_c('v-icon',{attrs:{"size":"25","color":"SystemCTA"}},[_vm._v("mdi-account-circle")])],1),_c('v-list-item-title',{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm.$t('accountSettings')))])],1),_c('v-divider'),_c('v-list-item',{on:{"click":_vm.initLogoutConfirm}},[_c('v-list-item-icon',{class:_vm.$vuetify.rtl ? 'ml-3' : 'mr-3'},[_c('v-icon',{attrs:{"size":"25","color":"SystemCTA"}},[_vm._v("mdi-logout")])],1),_c('v-list-item-title',{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm.$t('logout')))])],1)],1)],1):_vm._e()],1),_c('v-card-text',{staticClass:"pa-3 news-filter"},[_c('v-expansion-panels',{attrs:{"multiple":"","accordion":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"text-subtitle-2 Text01--text pa-3",attrs:{"hide-actions":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var open = ref.open;
return [_c('div',{staticClass:"d-flex align-center justify-space-between"},[_vm._v(" "+_vm._s(_vm.$t('filterByType'))+" "),(_vm.type !== 'all')?[_vm._v("(1)")]:_vm._e(),_c('v-btn',{attrs:{"small":"","icon":"","color":"primary"}},[_c('v-icon',[_vm._v(" "+_vm._s(open ? "mdi-chevron-down" : "mdi-chevron-right")+" ")])],1)],2)]}}])}),_c('v-expansion-panel-content',[_c('v-radio-group',{staticClass:"mt-0 px-3",attrs:{"hide-details":"","mandatory":""},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}},[_c('v-radio',{attrs:{"label":_vm.$t('all'),"value":"all"}}),_c('v-divider',{staticClass:"mb-4 mt-2"}),_c('v-radio',{attrs:{"label":_vm.$t('info'),"value":"article"}}),_c('v-divider',{staticClass:"mb-4 mt-2"}),_c('v-radio',{attrs:{"label":_vm.$t('responses'),"value":"response"}}),_c('v-divider',{staticClass:"mb-4 mt-2"})],1)],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"text-subtitle-2 Text01--text pa-3",attrs:{"hide-actions":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var open = ref.open;
return [_c('div',{staticClass:"d-flex align-center justify-space-between"},[_vm._v(" "+_vm._s(_vm.$t('filterByStatus'))+" "),(_vm.statuses.length)?[_vm._v("("+_vm._s(_vm.statuses.length)+")")]:_vm._e(),_c('v-btn',{attrs:{"small":"","icon":"","color":"primary"}},[_c('v-icon',[_vm._v(" "+_vm._s(open ? "mdi-chevron-down" : "mdi-chevron-right")+" ")])],1)],2)]}}])}),_c('v-expansion-panel-content',[_c('v-list',{staticClass:"pt-0",attrs:{"flat":""}},[_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.statuses),callback:function ($$v) {_vm.statuses=$$v},expression:"statuses"}},[_c('v-list-item',{staticClass:"px-3",attrs:{"value":"unread","ripple":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('unread')))])],1),_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary"}})],1)]}}])}),_c('v-divider',{staticClass:"mx-3"}),_c('v-list-item',{staticClass:"px-3",attrs:{"value":"favourite","ripple":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('favourites')))])],1),_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary"}})],1)]}}])}),_c('v-divider',{staticClass:"mx-3"}),_c('v-list-item',{staticClass:"px-3",attrs:{"value":"hidden","ripple":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('hidden')))])],1),_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary"}})],1)]}}])}),_c('v-divider',{staticClass:"mx-3"})],1)],1)],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"text-subtitle-2 Text01--text pa-3",attrs:{"hide-actions":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var open = ref.open;
return [_c('div',{staticClass:"d-flex align-center justify-space-between"},[_vm._v(" "+_vm._s(_vm.$t('byGroups'))+" "),(_vm.recipientsCounter)?[_vm._v("("+_vm._s(_vm.recipientsCounter)+")")]:_vm._e(),_c('v-btn',{attrs:{"small":"","icon":"","color":"primary"}},[_c('v-icon',[_vm._v(" "+_vm._s(open ? "mdi-chevron-down" : "mdi-chevron-right")+" ")])],1)],2)]}}])}),_c('v-expansion-panel-content',[(_vm.hasDirectNews)?_c('v-list',{staticClass:"py-0",attrs:{"flat":""}},[_c('v-list-item',{staticClass:"px-3",attrs:{"ripple":false}},[_c('v-list-item-content',{staticClass:"cursor-pointer",on:{"click":function($event){_vm.isDirectNews = !_vm.isDirectNews}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('displayIndividualNews')))])],1),_c('v-list-item-action',[_c('v-checkbox',{attrs:{"color":"primary"},model:{value:(_vm.isDirectNews),callback:function ($$v) {_vm.isDirectNews=$$v},expression:"isDirectNews"}})],1)],1),_c('v-divider',{staticClass:"mx-3"})],1):_vm._e(),_c('v-list',{staticClass:"pt-0",attrs:{"flat":""}},[_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.recipients),callback:function ($$v) {_vm.recipients=$$v},expression:"recipients"}},_vm._l((_vm.groups),function(ref){
var id = ref.id;
var name = ref.name;
return _c('div',{key:id},[_c('v-list-item',{staticClass:"px-3",attrs:{"value":id,"ripple":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(name))])],1),_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary"}})],1)]}}],null,true)}),_c('v-divider',{staticClass:"mx-3"})],1)}),0)],1)],1)],1)],1)],1),_c('v-footer',{staticStyle:{"bottom":"59px"},attrs:{"height":"100","color":"SystemWhite","fixed":"","app":""}},[_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"color":"primary","outlined":"","tile":"","block":"","disabled":!_vm.resetButtonCounter},on:{"click":_vm.resetFilters}},[_vm._v(" "+_vm._s(_vm.$t('reset'))+" "),(_vm.resetButtonCounter)?[_vm._v("("+_vm._s(_vm.resetButtonCounter)+")")]:_vm._e()],2)],1),_c('v-col',[_c('v-btn',{attrs:{"color":"primary","depressed":"","tile":"","block":""},on:{"click":_vm.applyFilter}},[_vm._v(" "+_vm._s(_vm.$t('apply'))+" ")])],1)],1)],1),_c('v-footer',{attrs:{"height":"60","color":"GreyFooter","fixed":"","app":""}},[_c('span',{staticClass:"text-body-1",class:_vm.$vuetify.rtl ? 'ml-2' : 'mr-2'},[_vm._v("© "+_vm._s(new Date().getFullYear())+".")]),_c('router-link',{staticClass:"text-body-1 text-decoration-none",attrs:{"to":{name: 'dashboard'}}},[_vm._v(" Stay Informed GmbH ")])],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }