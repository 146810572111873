<template>
  <v-row class="h-100 mt-10 mt-md-0">
    <v-col>
      <div class="main-content-container wide h-100">
        <v-row sticky-container no-gutters class="justify-end h-100">
          <TabsList/>

          <v-col cols="12" md="8" class="position-relative">
            <SkeletonLoader v-if="loading"/>

            <template v-else>
              <div class="editable-avatar-container d-flex justify-center">
                <v-avatar size="100" class="overflow-visible">
                  <avatar-component
                      :avatar="avatar"
                      :name="{firstName, lastName}"
                      :size="100"
                      :font-size="40"
                  />

                  <v-btn
                      depressed
                      style="right: 0; bottom: 0; z-index: 2"
                      color="primary"
                      fab
                      dark
                      x-small
                      absolute
                      @click="initChoseFile"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </v-avatar>

                <AvatarUploadDialog
                    hide-btn
                    :loading="loading"
                    @confirm="confirmAvatarUploading"
                    :returnCanvas="true"
                />
              </div>

              <v-row class="mt-5">
                <v-col cols="12">
                  <v-text-field
                      v-model="firstName"
                      :error-messages="firstNameErrors"
                      :label="$t('registerFirstName')"
                      required
                      outlined
                      hide-details="auto"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      v-model="lastName"
                      :error-messages="lastNameErrors"
                      :label="$t('registerLastName')"
                      required
                      outlined
                      hide-details="auto"
                  />
                </v-col>
              </v-row>

              <v-row class="mt-5">
                <v-col cols="12">
                  <p class="Text01--text text-subtitle-2 mb-1">
                    {{ $t('reEnterPassword') }}
                  </p>
                  <p class="Text02--text text-body-2">
                    {{ $t('verifyPasswordInfo') }}
                  </p>
                  <v-text-field
                      v-model="currentPassword"
                      :error-messages="currentPasswordErrors"
                      :type="showCurrentPassword ? 'text' : 'password'"
                      :label="$t('confirmCurrentPassword')"
                      outlined
                      hide-details="auto"
                  >
                    <template v-slot:append>
                      <v-icon color="Text03" @click="showCurrentPassword = !showCurrentPassword">
                        {{ showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off' }}
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-btn class="px-0" text color="error" @click="deleteAccountModal = true">
                    <span class="text-button">
                      {{ $t('deleteMyAccount') }}
                    </span>
                  </v-btn>

                  <confirmation-modal
                      :max-width="555"
                      v-model="deleteAccountModal"
                      :title="$t('removalRequestHeader')"
                      :label="$t('removalRequestInfo')"
                      apply-button-color="error"
                      :apply-button-label="$t('delete')"
                      @apply="submitDeletionRequest"
                  />

                </v-col>
              </v-row>

            </template>

          </v-col>

          <v-col
              :class="$vuetify.rtl ? 'ml-md-auto border-left' : 'mr-md-auto border-right'"
              class="d-none d-md-block"
              cols="12"
              md="3"
          />

          <action-buttons :loading="loading" @discardChanges="discardChanges" @initUpdate="initUpdateAccountDetails"/>

        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import TabsList from "@/components/pages/AccountSettingsPage/components/TabsList";
import ActionButtons from "@/components/pages/AccountSettingsPage/components/ActionButtons";
import ConfirmationModal from "@/components/ConfirmationModal";
import {mapActions, mapGetters} from "vuex";
import {UserGetters} from "@/store/user";
import AvatarUploadDialog from "@/components/pages/MemberPage/components/AvatarUploadDialog";
import {UserActions} from "@/store/user";
import AvatarComponent from "@/components/pages/AccountSettingsPage/components/AvatarComponent";
import {required} from "vuelidate/lib/validators";
import validationMixin from "@/mixins/validationMixin";

export default {
  name: "ProfileInfoPage",

  mixins: [validationMixin],

  data() {
    return {
      loading: true,
      firstName: null,
      lastName: null,
      currentPassword: null,
      showCurrentPassword: false,
      deleteAccountModal: false,
    }
  },

  validations: {
    firstName: {required},
    lastName: {required},
    currentPassword: {required},
  },

  components: {
    TabsList,
    AvatarUploadDialog,
    AvatarComponent,
    ActionButtons,
    ConfirmationModal,
  },

  async mounted() {
    await this.fetchProfileInfo();
  },

  computed: {
    ...mapGetters({
      accountConfiguration: `user/${UserGetters.AccountConfiguration}`,
      avatar: `user/${UserGetters.Avatar}`,
    }),

    isMessengerActive() {
      if (!this.accountConfiguration) {
        return false;
      }
      return this.accountConfiguration.groupChatActive || this.accountConfiguration.individualChatActive;
    },

    firstNameErrors() {
      return this.getErrorMessagesForFieldWithRules('firstName', {
        required
      });
    },

    lastNameErrors() {
      return this.getErrorMessagesForFieldWithRules('lastName', {
        required
      });
    },

    currentPasswordErrors() {
      return this.getErrorMessagesForFieldWithRules('currentPassword', {
        required
      });
    },
  },

  methods: {
    ...mapActions({
      updateAccountDetails: `user/${UserActions.UpdateAccountDetails}`,
      fetchAccountDetails: `user/${UserActions.FetchAccountDetails}`,
      accountDeletionRequest: `user/${UserActions.AccountDeletionRequest}`,
      logout: `user/${UserActions.Logout}`,
      fetchAvatar: `user/${UserActions.FetchAvatar}`,
      saveAvatar: `user/${UserActions.SaveAvatar}`,
    }),

    async submitDeletionRequest() {
      this.loading = true;

      await this.accountDeletionRequest().then(() => {
        this.loading = false;
        this.deleteAccountModal = false;

        this.logout()
            .then(() => {
              this.$router.replace({name: 'login'});

              return this.$toasted.info(this.$t('removalRequestAlert'));
            });
      }).catch((e) => {
        this.$toasted.error(e.message);
        this.loading = false;
      });
    },

    async discardChanges() {
      await this.fetchProfileInfo();
    },

    async fetchProfileInfo() {
      this.loading = true;

      try {
        const {first_name, last_name} = await this.fetchAccountDetails();

        this.firstName = first_name;
        this.lastName = last_name;
        this.currentPassword = null;

        this.loading = false;
      } catch (e) {
        this.$toasted.error(e);
        this.loading = false;
      }
    },

    initChoseFile() {
      this.$root.$refs.avatarUploadDialog.initChoseFile();
    },

    async blobToBase64(blob) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    },

    async confirmAvatarUploading(base64) {
      this.loading = true;

      await this.saveAvatar({
        avatar: base64,
      }).then(() => {
        this.$root.$refs.avatarUploadDialog.close();
      });

      await this.fetchAvatar();

      this.loading = false;

      this.$toasted.success(this.$t('avatarUpdated'));
    },

    async initUpdateAccountDetails() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        this.updateAccountDetails({
          firstName: this.firstName,
          lastName: this.lastName,
          currentPassword: this.currentPassword,
        }).then(() => {
          this.$toasted.success(this.$t('changesSavedSuccessfully'));
          this.loading = false;
        }).catch((e) => {
          this.$toasted.error(e.message);
          this.loading = false;
        });
      }
    }
  }
}
</script>