import moment from "moment/moment";

export default {
    methods: {
        isCheckInCheckOutActive() {
            return this.$store.state.user?.accountConfiguration?.pickupInfo;
        },

        isAbsenceStarted(absence) {
            return moment.utc(absence.start).isBefore(moment.utc());
        },

        showEdit(absence) {
            if (!this.isCheckInCheckOutActive()) {
                return !this.isAbsenceStarted(absence);
            }
            const now = moment.utc();
            const start = moment.utc(absence.start);
            const end = moment.utc(absence.end);

            if (start.isAfter(now)) {
                return true;
            }
            if (start.isSame(now, 'day') && start.isSame(end)) {
                return false;
            }

            return end.isAfter(now);
        },

        showDeleteInModal(absence) {
            return moment.utc(absence.start).isAfter(moment.utc());
        },

        showDeleteInMenu(absence) {
            return this.isCheckInCheckOutActive() ? this.showDeleteInModal(absence) : true;
        },
    },
};
