import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import light from '@/themes/light'
import dark from '@/themes/dark'

Vue.use(Vuetify)

const opts = {
    theme: {
        options: {
            customProperties: true,
        },
        dark: false,
        themes: {dark, light},
    },
    icons: {
        values: {
            noticeboard: "M13.3564 8.70025L13.3566 8.70038L11.6992 11.8176L11.6988 11.8177L13.3564 8.70025ZM11.2282 8.70092L8.51172 7.25654L9.59361 5.2218L11.1581 4.77599L12.1023 3.00016H9.85C8.07042 3.00006 8.01937 3.00002 7.96831 3.00001C7.9376 3 7.90689 3 7.5 3L5.15 3.00016H1.66667C0.75 3.00016 0 3.75016 0 4.66683V16.3335C0 17.2502 0.75 18.0002 1.66667 18.0002H13.3333C14.25 18.0002 15 17.2502 15 16.3335V10.7064L13.7702 10.0525L12.3877 12.6528L10.4408 13.248L9.84562 11.3012L11.2282 8.70092Z M15.9024 7.78073L16.4853 9.36829L16.0726 10.1444L13.3561 8.70004L11.6985 11.8175L11.1041 11.9993L10.9224 11.4049L12.58 8.28736L9.86348 6.84298L10.2762 6.06684L11.8406 5.62103L14.1241 1.32638L13.4773 0.982482L13.89 0.206341L19.2454 3.05384L18.8327 3.82998L18.1859 3.48608L15.9024 7.78073Z",
            human_greeting: "M12 6C13.1046 6 14 5.10457 14 4C14 2.89543 13.1046 2 12 2C10.8954 2 10 2.89543 10 4C10 5.10457 10.8954 6 12 6Z M15.89 8.11C15.5 7.72 14.83 7 13.53 7C13.32 7 12.11 7 10.99 7C8.24 6.99 6 4.75 6 2H4C4 5.16 6.11 7.84 9 8.71V22H11V16H13V22H15V10.05L18.95 14L20.36 12.59L15.89 8.11Z",
            fast_food: "M1 21.98C1 22.54 1.45 22.99 2.01 22.99H15C15.56 22.99 16.01 22.54 16.01 21.98V21H1V21.98ZM8.5 8.99C4.75 8.99 1 11 1 15H16C16 11 12.25 8.99 8.5 8.99ZM3.62 13C4.73 11.45 7.09 10.99 8.5 10.99C9.91 10.99 12.27 11.45 13.38 13H3.62ZM1 17H16V19H1V17ZM18 5V1H16V5H11L11.23 7H20.79L19.39 21H18V23H19.72C20.56 23 21.25 22.35 21.35 21.53L23 5H18Z",
            cottage: "M12 3L6 7.58V6H4V9.11L1 11.4L2.21 12.99L4 11.62V21H20V11.62L21.79 12.98L23 11.4L12 3ZM18 19H13V15H11V19H6V10.1L12 5.52L18 10.1V19ZM10 1C10 2.66 8.66 4 7 4C6.45 4 6 4.45 6 5H4C4 3.34 5.34 2 7 2C7.55 2 8 1.55 8 1H10Z",
            play: "M12 0C5.376 0 0 5.376 0 12C0 18.624 5.376 24 12 24C18.624 24 24 18.624 24 12C24 5.376 18.624 0 12 0ZM9.6 16.2V7.8C9.6 7.308 10.164 7.02 10.56 7.32L16.164 11.52C16.488 11.76 16.488 12.24 16.164 12.48L10.56 16.68C10.164 16.98 9.6 16.692 9.6 16.2Z",
            pending_actions: "M17.2363 12C14.4763 12 12.2363 14.24 12.2363 17C12.2363 19.76 14.4763 22 17.2363 22C19.9963 22 22.2363 19.76 22.2363 17C22.2363 14.24 19.9963 12 17.2363 12ZM18.8863 19.35L16.7363 17.2V14H17.7363V16.79L19.5863 18.64L18.8863 19.35ZM18.2363 3H15.0563C14.6363 1.84 13.5363 1 12.2363 1C10.9363 1 9.83633 1.84 9.41633 3H6.23633C5.13633 3 4.23633 3.9 4.23633 5V20C4.23633 21.1 5.13633 22 6.23633 22H12.3463C11.7563 21.43 11.2763 20.75 10.9263 20H6.23633V5H8.23633V8H16.2363V5H18.2363V10.08C18.9463 10.18 19.6163 10.39 20.2363 10.68V5C20.2363 3.9 19.3363 3 18.2363 3ZM12.2363 5C11.6863 5 11.2363 4.55 11.2363 4C11.2363 3.45 11.6863 3 12.2363 3C12.7863 3 13.2363 3.45 13.2363 4C13.2363 4.55 12.7863 5 12.2363 5Z",
            fast_food_fill: "M18.2363 5V1H16.2363V5H11.2363L11.4663 7.31C15.1363 8.16 18.2363 10.77 18.2363 15L18.2563 23H21.4363L23.2363 5H18.2363ZM1.23633 21H16.2363V23H1.23633V21ZM8.73633 8.99C4.98633 8.99 1.23633 11 1.23633 15H16.2363C16.2363 11 12.4863 8.99 8.73633 8.99ZM1.23633 17H16.2363V19H1.23633V17Z",
            news_response_received: "M12.0002 22.7145C17.9175 22.7145 22.7145 17.9175 22.7145 12.0002C22.7145 6.08284 17.9175 1.28589 12.0002 1.28589C6.08284 1.28589 1.28589 6.08284 1.28589 12.0002C1.28589 17.9175 6.08284 22.7145 12.0002 22.7145ZM7.95389 16.8297L4.13719 13.013L5.10673 12.0503L8.9166 15.8602L7.95389 16.8297ZM11.8184 14.8974L19.0421 7.66687L20.0117 8.63641L11.8184 16.8297L8.00168 13.013L8.97805 12.0503L11.8184 14.8974ZM15.1845 7.66687L16.1472 8.63641L11.8184 12.9652L10.8488 12.0025L15.1845 7.66687Z"
        }
    }
}

export default new Vuetify(opts)