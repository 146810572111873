const colors = Object.freeze([
  {
    index: 0,
    color: "calendarPaleBlue",
    dotColor: "calendarDeepSkyBlue",
  },
  {
    index: 1,
    color: "calendarUranianBlue",
    dotColor: "calendarRoyalBlue",
  },
  {
    index: 2,
    color: "calendarPaleViolet",
    dotColor: "calendarUltraViolet"
  },
  {
    index: 3,
    color: "calendarPalePurple",
    dotColor: "calendarPurple"
  },
  {
    index: 4,
    color: "calendarFairyTale",
    dotColor: "calendarRose"
  },
  {
    index: 5,
    color: "calendarPaleRed",
    dotColor: "calendarRed"
  },
  {
    index: 6,
    color: "calendarPaleOrange",
    dotColor: "calendarOrange"
  },
  {
    index: 7,
    color: "calendarAquamarine",
    dotColor: "calendarPigmentGreen"
  },
  {
    index: 8,
    color: "calendarMintGreen",
    dotColor: "calendarMyrtleGreen"
  },
  {
    index: 9,
    color: "calendarCornsilk",
    dotColor: "calendarAmber"
  }
]);

function transformColorToVar(color) {
  return `var(--v-${color}-base)`;
}

function getFirstColorsElement() {
  return colors.find(Boolean);
}

function getIndex(index) {
  if (!colors.map(color => color.index).includes(index)) {
    index = getFirstColorsElement.index;
  }
  return index;
}

export function dotColorByIndex(index) {
  return transformColorToVar(colors.find(color => color.index === getIndex(index)).dotColor);
}

export function bgColorByIndex(index) {
  if (!colors.map(color => color.index).includes(index)) {
    index = getFirstColorsElement.index;
  }
  return transformColorToVar(colors.find(color => color.index === getIndex(index)).color);
}

export function cancelledColor() {
  return transformColorToVar('calendarTimberwolf');
}
