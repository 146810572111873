<template>
  <div>
    <i18next path="registerTermsConditions">
      <a
          class="text-body-1"
          place="privacyPolicy"
          href="#"
          v-if="Boolean(appUsersPrivacyPolicy)"
          @click.stop.prevent="initFetchPdf('app_users_privacy_policy', appUsersPrivacyPolicy)"
      >
        {{ $t('privacy_policy_title') }}
      </a>
      <a
          class="text-body-1"
          @click.stop
          place="privacyPolicy"
          target="_blank"
          :href="globalPrivacyPolicyUrl"
          v-else-if="Boolean(globalPrivacyPolicyUrl)"
      >
        {{ $t('privacy_policy_title') }}
      </a>
      <a href="#" v-else place="privacyPolicy">{{ $t('privacy_policy_title') }}</a>

      <a
          class="text-body-1"
          place="termConditions"
          href="#"
          v-if="Boolean(appUsersTermsAndConditions)"
          @click.stop.prevent="initFetchPdf('app_users_terms_and_conditions', appUsersTermsAndConditions)"
      >
        {{ $t('termsConditions') }}
      </a>
      <a
          class="text-body-1"
          @click.stop
          place="termConditions"
          target="_blank"
          :href="globalTermsAndConditions"
          v-else-if="Boolean(globalTermsAndConditions)"
      >
        {{ $t('termsConditions') }}
      </a>
      <a class="text-body-1" href="#" v-else place="termConditions">{{ $t('privacy_policy_title') }}</a>

    </i18next>

    <pdf-viewer-dialog
        v-model="pdfDialog"
        :title="pdfDialogTitle"
        :pdfData="pdfData"
    />
  </div>
</template>

<script>

import PrivacyPolicyDialog from '@/components/PrivacyPolicyDialog';
import PdfViewerDialog from "@/components/PdfViewerDialog";

export default {
  name: "PrivacyTerms",

  extends: PrivacyPolicyDialog,

  components: {
    PdfViewerDialog
  }
}
</script>

<style scoped/>