const colors = Object.freeze({
    SystemCTA: "#1A235C",
    SystemWhite: "#FFFFFF",
    SystemBG: "#000307",

    Text01: "#FFFFFF",
    Text02: "#FFFFFF",
    Text03: "#FFFFFF",
    Text04: "#FFFFFF",

    GreyFooter: "#F5F6F8",
    GreyBorder: "#AAB3C5",
    GreyLine: "#DEE4EE",

    IconsBlue: "#648CFD",
    IconsLightBlue: "#D6E6FF",

    MenuHover: "#226AD6",

    NewsPink: "#FFFAFA",

    AlertRed: "#E52020",
    AlertLightYellow: "#FFFAE5",

    AvatarsBerry: "#C464FF",
    AvatarsOrange: "#FF9C41",
    AvatarsGreen: "#90C71F",
    AvatarsBlue: "#64BDFD",
    AvatarsPurple: "#8A8FFF",

    LabelRed: "#EA261A",
    LabelGreen: "#01B56D",
    LabelYellow: "#FFC53E",
    LabelOrange: "#FF7E00",
    LabelGray: "#EEF2F9",
    LabelBlack: "#00050B",

    BtnDisable: "#99BEF4",

    calendarPaleBlue: "#C8F1FF",
    calendarDeepSkyBlue: "#00BFFF",
    calendarUranianBlue: "#C3DEFF",
    calendarRoyalBlue: "#1379FF",
    calendarPaleViolet: "#E3CDF8",
    calendarUltraViolet: "#8E7FB7",
    calendarPalePurple: "#FFB9FF",
    calendarPurple: "#D100D1",
    calendarFairyTale: "#FFC8E4",
    calendarRose: "#FF2492",
    calendarPaleRed: "#FFC7C7",
    calendarRed: "#FF0B0B",
    calendarPaleOrange: "#FFE6D8",
    calendarOrange: "#FF7524",
    calendarAquamarine: "#A9FBD0",
    calendarPigmentGreen: "#08C862",
    calendarMintGreen: "#CAE9E8",
    calendarMyrtleGreen: "#419E98",
    calendarCornsilk: "#FFF4D1",
    calendarAmber: "#FFC61C",
    calendarRichBlack: "#000B0F",
    calendarTimberwolf: "#E5EBED"
});

const vuetify = Object.freeze({

});

export default { ...colors, ...vuetify };
