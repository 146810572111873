<template>
  <v-row>
    <v-col>
      <div class="main-content-container tight">
        <v-col cols="12" no-gutters v-for="foodPlan in foodPlans" :key="foodPlan.id">
          <v-card :loading="requestedFoodPlanId === foodPlan.id" tile outlined>
            <v-card-text class="pa-0">
              <v-list-item @click="openFoodPlanPdf(foodPlan.id, foodPlan.title)" v-ripple="{ class: 'SystemCTA--text' }">
                <v-list-item-icon :class="$vuetify.rtl ? 'ml-4' : 'mr-4'">
                  <v-img :alt="$t('view')" src="/images/pdf.svg"/>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-subtitle-1 Text01--text text-wrap">
                    {{ foodPlan.title }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-body-1 Text02--text">
                    {{ foodPlan.start|date($t('normalDateFormat')) }}
                    -
                    {{ foodPlan.end|date($t('normalDateFormat')) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card-text>
          </v-card>
        </v-col>

        <infinite-loading :identifier="infiniteId" @infinite="loadFoodPlans">
          <template v-slot:spinner>
            <SkeletonLoader/>
          </template>
          <template v-slot:no-results>
            <empty-state
                :title="$t('no_food_plan_title')"
                :subtitle="$t('no_food_plan_subtitle')"
                image="/images/empty-food-plans.svg"
            />
          </template>
        </infinite-loading>

        <pdf-viewer-dialog
            v-model="pdfDialog"
            :title="pdfDialogTitle"
            :pdfData="pdfData"
            @close="requestedFoodPlanId = null"
        />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {FoodPlansActions} from "@/store/foodPlans";
import PdfViewerDialog from "@/components/PdfViewerDialog";
import EmptyState from "@/components/EmptyState";
import {UserGetters} from "@/store/user";

export default {
  components: {
    PdfViewerDialog,
    EmptyState,
  },
  data() {
    return {
      loading: false,
      offset: 0,
      infiniteId: +new Date(),
      foodPlans: [],
      foodPlanPDFs: [],
      pdfDialog: false,
      confirmationDialog: false,
      pdfDialogTitle: null,
      pdfData: null,
      requestedFoodPlanId: null,
      requestedFoodPlanPDFData: null,
    }
  },
  mounted() {
    if (!this.showFoodPlan) {
      this.$router.replace({name: 'news'});
    }
  },
  computed: {
    ...mapGetters({
      accountConfiguration: `user/${UserGetters.AccountConfiguration}`,
    }),
    showFoodPlan() {
      if (!this.accountConfiguration) {
        return false;
      }
      return this.accountConfiguration.showFoodPlan;
    },
  },
  methods: {
    ...mapActions({
      fetchFoodPlans: `foodPlans/${FoodPlansActions.FetchFoodPlans}`,
      fetchFoodPlanPdf: `foodPlans/${FoodPlansActions.FetchFoodPlanPdf}`,
    }),
    loadFoodPlans($state) {
      this.fetchFoodPlans(this.offset)
          .then((data) => {
            if (data.length > 0) {
              this.offset++;
              this.foodPlans.push(...data);
              $state.loaded();
            } else {
              $state.complete();
            }
          });
    },
    async openFoodPlanPdf(foodPlanId, foodPlanTitle) {
      await this.clearPdfData();
      await this.getPdfData(foodPlanId);
      this.showPdf(foodPlanTitle);
    },
    async getPdfData(foodPlanId) {
      this.requestedFoodPlanId = foodPlanId;
      this.requestedFoodPlanPDFData = this.foodPlanPDFs.find(x => x.id === this.requestedFoodPlanId);
      if (!this.requestedFoodPlanPDFData) {
        await this.fetchFoodPlanPdf(this.requestedFoodPlanId)
            .then((data) => {
              this.foodPlanPDFs.push({'id': this.requestedFoodPlanId, 'data': data});
              this.requestedFoodPlanPDFData = {'id': this.requestedFoodPlanId, 'data': data}
              this.requestedFoodPlanId = null;
            })
            .catch((e) => {
              this.$toasted.error(e.message);
              this.requestedFoodPlanId = null;
            });
      }
    },
    showPdf(foodPlanTitle) {
      this.pdfDialogTitle = foodPlanTitle;
      this.pdfData = this.requestedFoodPlanPDFData.data;
      this.pdfDialog = true;
      this.$forceUpdate();
    },
    clearPdfData() {
      this.pdfData = null;
      this.pdfDialog = false;
      this.pdfDialogTitle = null;
    },
  }
}
</script>
