<template>
  <v-banner
      class="mt-auto"
      :height="$vuetify.breakpoint.mdAndUp ? 77 : ''"
      sticky
  >
    <template v-slot:actions>
      <div class="w-100 d-md-flex justify-end" :class="$vuetify.rtl ? 'mr-0' : 'ml-0'">
        <v-btn
            :block="$vuetify.breakpoint.smAndDown"
            :min-width="181"
            depressed
            color="primary"
            outlined
            :disabled="loading"
            style="background-color: white"
            @click="$emit('discardChanges')"
        >
          {{ $t('discardChanges') }}
        </v-btn>
        <v-btn
            :block="$vuetify.breakpoint.smAndDown"
            :class="$vuetify.rtl ? 'mr-md-5' : 'ml-md-5'"
            class="mt-3 mt-md-0"
            :min-width="181"
            depressed
            color="primary"
            :disabled="loading"
            @click="$emit('initUpdate')"
        >
          {{ $t('save') }}
        </v-btn>
      </div>
    </template>
  </v-banner>
</template>

<script>
export default {
  name: "ActionButtons",

  props: {
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
@import '~vuetify/src/styles/settings/_variables';

.v-banner {
  width: 75%;
  bottom: 60px;
  bottom: 0;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    width: 100%;
  }

  ::v-deep .v-banner__wrapper {
    border-bottom: none !important;
    border-top: 1px solid var(--v-GreyLine-base);
    padding-right: 0;

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      padding-right: 0;
      padding-left: 0;
      border-top: none;
    }
  }

  ::v-deep .v-banner__content {
    display: none;
  }
}
</style>