import {AdditionalContactsService} from "@/services/AdminApiService";
import {serialize} from "object-to-formdata";

const initialState = () => ({
    list: [],
    meta: {},
    model: {
        avatar_field: null,
        avatar: null,
        image: null,
        first_name: null,
        last_name: null,
        is_allowed_to_pick_up: false,
        is_emergency_contact: false,
        mobile: null,
        comment: null,
        children_ids: [],
    },
    loading: false,
    children_ids: [],
});

export const AdditionalContactsActions = {
    FetchAdditionalContacts: 'fetchAdditionalContacts',
    FetchAdditionalContact: 'fetchAdditionalContact',
    CreateAdditionalContact: 'createAdditionalContact',
    UpdateAdditionalContact: 'updateAdditionalContact',
    DeleteAdditionalContact: 'deleteAdditionalContact',
};

export const AdditionalContactsGetters = {
    Model: 'model',
    List: 'list',
    Meta: 'meta',
    Loading: 'loading',
    ChildrenIds: 'childrenIds',
};

export const AdditionalContactsMutations = {
    SetModel: 'setModel',
    SetList: 'setList',
    SetMeta: 'setMeta',
    SetLoading: 'setLoading',
    TruncateModel: 'truncateModel',
    SetChildrenIds: "setChildrenIds"
};

const state = initialState();

const getters = {
    [AdditionalContactsGetters.Model](state) {
        return state.model;
    },
    [AdditionalContactsGetters.List](state) {
        return state.list;
    },
    [AdditionalContactsGetters.Meta](state) {
        return state.meta;
    },
    [AdditionalContactsGetters.Loading](state) {
        return state.loading;
    },
    [AdditionalContactsGetters.ChildrenIds](state) {
        return state.children_ids;
    },
};

const actions = {
    async [AdditionalContactsActions.FetchAdditionalContacts]({commit}, {memberId, page}) {
        commit(AdditionalContactsMutations.SetLoading, true);

        return AdditionalContactsService.index(memberId, page).then(async ({data: {data, meta}}) => {
            data = await Promise.all(data.map(async (contact) => {
                const {id, avatar_url, first_name, last_name} = contact;

                contact.avatar_field = null;
                contact.initials = 'N/A';

                if (avatar_url) {
                    let {data} = await AdditionalContactsService.fetchAvatar(id);

                    contact.avatar_field = URL.createObjectURL(data);
                }

                let firstNameLetter = first_name.charAt(0).toUpperCase();
                let lastNameLetter = last_name.charAt(0).toUpperCase();

                contact.initials = `${firstNameLetter}${lastNameLetter}`;


                return contact;
            }));

            commit(AdditionalContactsMutations.SetList, data);
            commit(AdditionalContactsMutations.SetMeta, meta);
            commit(AdditionalContactsMutations.SetLoading, false);

            return data;
        }).catch((e) => {
            commit(AdditionalContactsMutations.SetLoading, false);

            throw new Error(e?.response?.data?.message);
        });
    },

    async [AdditionalContactsActions.FetchAdditionalContact]({commit}, id) {
        commit(AdditionalContactsMutations.SetLoading, true);

        return AdditionalContactsService.show(id).then(async ({data: {data}}) => {
            const {id, avatar_url, first_name, last_name, children_ids} = data;

            data.avatar_field = null;
            data.avatar = null;
            data.image = null;
            data.initials = 'N/A';

            if (avatar_url) {
                let response = await AdditionalContactsService.fetchAvatar(id);

                data.avatar_field = URL.createObjectURL(response.data);
            }

            let firstNameLetter = first_name.charAt(0).toUpperCase();
            let lastNameLetter = last_name.charAt(0).toUpperCase();

            data.initials = `${firstNameLetter}${lastNameLetter}`;

            commit(AdditionalContactsMutations.SetModel, data);

            commit(AdditionalContactsMutations.SetChildrenIds, children_ids);

            commit(AdditionalContactsMutations.SetLoading, false);

            return data;
        }).catch((e) => {
            commit(AdditionalContactsMutations.SetLoading, false);

            throw new Error(e.response.data.message);
        });
    },

    async [AdditionalContactsActions.DeleteAdditionalContact]({commit}, id) {
        commit(AdditionalContactsMutations.SetLoading, true);

        return AdditionalContactsService.destroy(id).then(async ({data}) => {
            commit(AdditionalContactsMutations.SetLoading, false);

            return data;
        }).catch((e) => {
            commit(AdditionalContactsMutations.SetLoading, false);

            throw new Error(e?.response?.data?.message);
        });
    },

    async [AdditionalContactsActions.CreateAdditionalContact]({commit}, payload) {
        commit(AdditionalContactsMutations.SetLoading, true);

        const copy = Object.assign({}, payload);

        const formData = serialize(copy, {
            booleansAsIntegers: true,
            indices: true,
            nullsAsUndefineds: true
        });

        return AdditionalContactsService.create(formData).then(async ({data}) => {
            commit(AdditionalContactsMutations.SetLoading, false);

            return data;
        })
    },

    async [AdditionalContactsActions.UpdateAdditionalContact]({commit}, payload) {
        commit(AdditionalContactsMutations.SetLoading, true);

        const {id} = payload;

        const copy = Object.assign({}, payload);

        delete copy.id;
        delete copy.avatar_url;
        delete copy.image_url;
        delete copy.initials;
        delete copy.avatar_field;

        const formData = serialize(copy, {
            booleansAsIntegers: true,
            indices: true,
            nullsAsUndefineds: true
        });

        return AdditionalContactsService.update(id, formData).then(async ({data}) => {
            commit(AdditionalContactsMutations.SetLoading, false);

            return data;
        })
    },
}

const mutations = {
    [AdditionalContactsMutations.SetModel](state, payload) {
        state.model = payload;
    },
    [AdditionalContactsMutations.SetList](state, payload) {
        state.list = payload;
    },
    [AdditionalContactsMutations.SetMeta](state, payload) {
        state.meta = payload;
    },
    [AdditionalContactsMutations.SetLoading](state, payload) {
        state.loading = payload;
    },
    [AdditionalContactsMutations.SetChildrenIds](state, payload) {
        state.children_ids = payload;
    },
    [AdditionalContactsMutations.TruncateModel](state) {
        state.model = initialState().model;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};