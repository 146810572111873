<template>
  <div>
    <label for="upload-avatar">
      <slot name="activator" @click="initChoseFile" v-if="!hideBtn">
        <v-btn color="primary">{{ title || $t("editAvatar") }}</v-btn>
      </slot>
      <input id="upload-avatar" type="file" ref="file" @change="loadImage($event)" accept="image/*" class="d-none">
    </label>
    <v-dialog
        v-model="dialog"
        max-width="777"
        @click:outside="close"
        overlay-color="#061F4B"
        overlay-opacity="0.8"
    >
      <v-card :disabled="loading" :loading="loading" tile>
        <v-toolbar flat color="transparent">
          <span class="text-subtitle-1 Text01--text">{{ title || $t("editAvatar") }}</span>
          <v-spacer/>
          <v-btn icon color="secondary lighten-4" @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider/>

        <v-card-text class="pa-4">
          <div class="upload-container">
            <cropper
                ref="cropper"
                stencil-component="circle-stencil"
                :stencil-props="stencilProps"
                :src="image.src"
                :canvas="canvasSettings"
            />
          </div>
        </v-card-text>

        <v-divider/>

        <v-card-actions class="justify-end pa-4">
          <v-btn
              :min-width="106"
              color="primary"
              outlined
              :disabled="loading"
              @click="close"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
              :min-width="106"
              color="primary"
              depressed
              :loading="loading"
              class="ml-4"
              @click="confirm"
          >
            {{ $t("ok") }}
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {Cropper} from 'vue-advanced-cropper';
import mime from "mime-types";
import path from "path";

export default {
  name: "AvatarUploadDialog",

  components: {
    Cropper,
  },

  props: {
    hideBtn: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    size: {
      type: Number,
      default: 200
    },
    returnCanvas: {
      type: Boolean,
      default: false
    },
    returnOriginal: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null
    }
  },

  data() {
    let vm = this;

    return {
      image: {
        src: null,
        type: null,
        original: null
      },
      dialog: false,
      stencilProps: {
        movable: true,
        resizable: true,
        aspectRatio: 1
      },
      canvasSettings: {
        height: vm.size,
        width: vm.size
      },
      accept: ["image/png", "image/jpeg"]
    };
  },

  created() {
    this.$root.$refs.avatarUploadDialog = this;
  },

  methods: {
    initChoseFile() {
      this.$refs.file.click();
    },

    reset() {
      this.image = {
        src: null,
        type: null,
        original: null
      };
    },

    loadImage({target: {files}}) {
      this.$toasted.clear();

      const file = Array.from(files).find(Boolean);

      if (files && file) {
        const {size, name} = file;
        const type = mime.lookup(path.extname(name).toLowerCase());

        if (!this.accept.includes(type)) {
          return this.$toasted.error(this.$t("invalidFileType", {types: "jpeg, jpg, png"}));
        }

        if (size >= 5242880) {
          return this.$toasted.error(this.$t('imageFileSizeMustBeLessThan'));
        }

        if (this.image.src) {
          URL.revokeObjectURL(this.image.src);
        }

        const src = URL.createObjectURL(file);

        const reader = new FileReader();

        reader.onload = () => {
          this.image = {src, type, original: file};
        };

        reader.onloadend = () => {
          this.dialog = true;
        };

        reader.readAsArrayBuffer(files[0]);
      }
    },

    close() {
      this.reset();
      this.dialog = false;
    },

    async imageToBlob() {
      const {canvas} = this.$refs.cropper.getResult();

      return await new Promise((resolve) => {
        return canvas.toBlob((blob) => {
          resolve(blob);
        }, this.image.type);
      });
    },

    async confirm() {
      if (this.returnCanvas) {
        const {canvas} = this.$refs.cropper.getResult();
        const base64 = canvas.toDataURL('image/jpeg').replace(/^data:image\/jpeg;base64,/, '');

        if (this.returnOriginal) {
          const {src} = this.image;
          this.$emit("confirm", {cropped: base64, original: src});
        } else {
          this.$emit("confirm", base64);
        }

      } else {
        let image = await this.imageToBlob();

        if (this.returnOriginal) {
          const {original} = this.image;
          this.$emit("confirm", {cropped: image, original});
        } else {
          this.$emit("confirm", image);
        }
      }
    }
  },

  destroyed() {
    if (this.image.src) {
      URL.revokeObjectURL(this.image.src);
    }
  }
};
</script>

<style scoped>
.upload-container {
  width: 100%;
  height: 300px;
  max-height: 300px;
  max-width: 100%;
  overflow: hidden;
}
</style>
