<template>
  <v-card tile outlined class="mb-5" :loading="loadingItem" :disabled="loading" test-id="add_contact_card">
    <v-list-item class="flex-column flex-md-row pa-6">
      <v-list-item-avatar size="80" class="my-0" :class="$vuetify.rtl ? 'ml-0 ml-md-4' : 'mr-0 mr-md-4'">
        <avatar
            :size="80"
            :initials="additionalContact.initials"
            :src="additionalContact.avatar_field"
            color="white"
            backgroundColor="var(--v-IconsBlue-base)"
        />
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title class="Text01--text text-subtitle-2 mb-3 text-wrap text-center"
                           :class="$vuetify.rtl ? 'text-md-right' : 'text-md-left'">
          {{ additionalContact.first_name }} {{ additionalContact.last_name }}
        </v-list-item-title>
        <v-list-item-subtitle>
          <v-list-item
              v-if="additionalContact.is_allowed_to_pick_up || additionalContact.is_emergency_contact"
              class="px-0 mb-2 text-wrap"
              style="min-height: max-content"
          >
            <v-list-item-icon class="my-0 mx-0">
              <v-icon size="17" color="Text03">mdi-account-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="py-0 Text02--text text-body-2">
              <div class="mb-1" v-if="additionalContact.is_allowed_to_pick_up">
                {{
                  $t("membersAdditionalContactsAllowedToPickUp", {memberType: isAppTypeCare() ? $t('resident_genitive') : $t('child_genitive')})
                }}
              </div>
              <div v-if="additionalContact.is_emergency_contact">
                {{ $t("membersAdditionalContactsEmergencyContact") }}
              </div>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="additionalContact.mobile" class="px-0 mb-2 text-wrap" style="min-height: max-content">
            <v-list-item-icon class="my-0 mx-0">
              <v-icon size="17" color="Text03">mdi-cellphone</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="py-0">
              <a class="text-decoration-none Text03--text text-body-2" :href="`tel:${additionalContact.mobile}`">
                {{ additionalContact.mobile }}
              </a>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="additionalContact.comment" class="px-0 text-wrap" style="min-height: max-content">
            <v-list-item-icon class="mb-1 mt-0 mx-0">
              <v-icon size="17" color="Text03">mdi-message-reply-text</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="py-0 Text03--text text-body-2">
              {{ additionalContact.comment }}
            </v-list-item-content>
          </v-list-item>
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-action class="d-inline-flex d-md-none d-flex align-end flex-row justify-end my-0">
        <v-btn
            icon
            color="primary"
            @click="showEditModal"
        >
          <v-icon>
            mdi-pencil
          </v-icon>
        </v-btn>

        <v-btn
            v-if="isDeleteButtonVisible"
            icon
            color="error"
            @click="deleteConfirmationDialog = true"
        >
          <v-icon>
            mdi-delete
          </v-icon>
        </v-btn>
      </v-list-item-action>

      <v-list-item-action class="d-none d-md-inline-flex">
        <v-btn
            icon
            color="primary"
            @click="showEditModal"
        >
          <v-icon>
            mdi-pencil
          </v-icon>
        </v-btn>
      </v-list-item-action>

      <v-list-item-action v-if="isDeleteButtonVisible" class="d-none d-md-inline-flex">
        <v-btn
            icon
            color="error"
            @click="deleteConfirmationDialog = true"
        >
          <v-icon>
            mdi-delete
          </v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>

    <confirmation-modal
        :max-width="544"
        :loading="loading"
        v-model="deleteConfirmationDialog"
        :title="$t('deleteAdditionalContactMessage')"
        :label="$t('deleteAdditionalContactDescription')"
        :apply-button-label="$t('delete')"
        apply-button-color="error"
        @apply="initDeleteAdditionalContact"
    />
  </v-card>
</template>

<script>
import ConfirmationModal from "@/components/ConfirmationModal";
import {AdditionalContactsActions, AdditionalContactsGetters} from "@/store/additionalContacts";
import {mapActions, mapGetters} from "vuex";
import {Avatar} from "vue-avatar";
import appTypeMixin from "@/mixins/appTypeMixin";
import {MemberActions, MemberGetters} from "@/store/member";
import {UserGetters} from "@/store/user";

export default {
  name: "AdditionalContactItem",

  mixins: [appTypeMixin],

  data() {
    return {
      deleteConfirmationDialog: false,
      loadingItem: false
    }
  },

  props: {
    additionalContact: {
      type: Object,
      required: true
    },
  },

  components: {
    ConfirmationModal,
    Avatar,
  },

  computed: {
    ...mapGetters({
      loading: `additionalContacts/${AdditionalContactsGetters.Loading}`,
      authUser: `user/${UserGetters.User}`,
      members: `member/${MemberGetters.Members}`,
    }),

    isDeleteButtonVisible() {
      const {created_id} = this.additionalContact;
      const {user_id} = this.authUser;

      return created_id === user_id;
    },
  },

  methods: {
    ...mapActions({
      deleteAdditionalContact: `additionalContacts/${AdditionalContactsActions.DeleteAdditionalContact}`,
      fetchAdditionalContact: `additionalContacts/${AdditionalContactsActions.FetchAdditionalContact}`,
      fetchMembers: `member/${MemberActions.FetchMembers}`,
    }),

    async showEditModal() {
      this.loadingItem = true;

      const {id} = this.additionalContact;

      await this.fetchMembers();

      await this.fetchAdditionalContact(id);

      this.$emit('editAdditionalContact');

      this.loadingItem = false;
    },

    initDeleteAdditionalContact() {
      const {id, first_name, last_name} = this.additionalContact;

      this.deleteAdditionalContact(id).then(() => {
        this.deleteConfirmationDialog = false;

        this.$emit('deleteAdditionalContact');

        this.$toasted.error(this.$t('deletedSuccess', {name: `${first_name} ${last_name}`}));
      }).catch((e) => {
        this.$toasted.error(e.message);
      });
    }
  }
}
</script>

<style scoped>
::v-deep .v-list-item {
  min-height: 0 !important;
}
</style>