<template>
  <v-dialog
      v-model="dialog"
      scrollable
      fullscreen
  >
    <v-card color="rgba(6, 31, 75, 0.8)" tile elevation="0" @click="close" :ripple="false">
      <v-app-bar height="66" flat color="transparent" class="px-md-5">
        <v-btn color="SystemWhite" icon @click.stop.prevent="close">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-spacer/>

        <v-toolbar-title class="text-subtitle-1 SystemWhite--text">
          {{ title }}
        </v-toolbar-title>

        <v-spacer/>

        <v-btn color="SystemWhite" icon @click.stop.prevent="download()">
          <v-icon>mdi-download</v-icon>
        </v-btn>

        <v-btn color="SystemWhite" icon @click.stop.prevent="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>

      <v-card-text style="max-width: 800px;" class="align-self-center SystemWhite pa-0 mb-md-15 mt-md-5"
                   @click.stop.prevent>
        <pdf
            v-for="i in numPages"
            :key="i"
            :src="pdfSrc"
            :page="i"
            :id="i"
            @loaded="overlay = false"
            class="pdf-canvas"
        />
      </v-card-text>

      <SkeletonLoader v-if="overlay"/>

    </v-card>
  </v-dialog>
</template>

<script>
import pdf from 'vue-pdf'
import {downloadBlob} from "@/helpers/helpers";

export default {
  name: "PdfViewerDialog",

  components: {
    pdf
  },

  props: {
    value: {
      type: Boolean,
    },
    title: {
      type: String
    },
    pdfData: {
      type: ArrayBuffer
    }
  },

  data() {
    return {
      numPages: 0,
      pdfSrc: null,
      overlay: true,
    }
  },

  computed: {
    dialog: {
      get: function () {
        return this.value;
      },
      set: function (newValue) {
        if (!newValue) {
          this.overlay = true;
        }

        this.$emit('input', newValue);
      }
    },
  },

  watch: {
    pdfData: {
      immediate: true,
      async handler() {
        if (!this.pdfData) {
          return;
        }
        this.pdfSrc = await pdf.createLoadingTask({
          data: this.pdfData
        });
        await this.pdfSrc.promise.then(pdf => {
          this.numPages = pdf.numPages;
          this.$forceUpdate();
        });
      }
    }
  },

  methods: {
    download() {
      downloadBlob(this.pdfData, 'application/pdf', this.title)
    },

    close() {
      this.$emit('close');
      this.dialog = false;
    },
  }
}
</script>
