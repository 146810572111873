<template>
  <div class="position-relative">
    <v-avatar
        class="avatar"
        :size="size"
        :color="color"
        :style="borderStyle"
    >
      <img v-if="avatar"
           :alt="initials"
           :src="avatar"
      />
      <span class="white--text" v-else :style="`font-size: ${fontSize}px;`">{{ initials }}</span>
    </v-avatar>
  </div>
</template>

<script>
export default {
  name: "AvatarComponent",

  props: {
    avatar: {
      type: String,
      default: '',
    },
    name: {
      type: Object,
      default() {
        return {
          firstName: '',
          lastName: '',
        };
      },
    },
    size: {
      type: Number,
      default: 100,
    },
    fontSize: {
      type: Number,
      default: 20,
    },
    color: {
      type: String,
      default: 'IconsBlue',
    },
    borderSize: {
      type: Number,
      default: null,
    },
    borderColor: {
      type: Number,
      default: null,
    }
  },

  computed: {
    initials() {
      const {firstName, lastName} = this.name;

      let firstNameLetter = firstName ? firstName.charAt(0).toUpperCase() : '';
      let lastNameLetter = lastName ? lastName.charAt(0).toUpperCase() : '';
      let letters = `${firstNameLetter}${lastNameLetter}`;

      return letters ? letters : 'N/A';
    },
    borderStyle() {
      return (this.borderSize && this.borderColor) ? `border: ${this.borderSize}px; solid ${this.borderColor};` : '';
    }
  },
}
</script>

<style scoped/>