import { mapGetters } from "vuex";
import { AppType, UserGetters } from "@/store/user";

export default {
  computed: {
    ...mapGetters({
      appType: `user/${UserGetters.AppType}`,
    }),
  },
  methods: {
    isAppTypeCare() {
      return this.appType === AppType.Care;
    },
    isAppTypeTeams() {
      return this.appType === AppType.Teams;
    },
  },
};
