<template>
  <v-container class="pa-0">

    <SkeletonLoader v-if="loading"/>

    <v-card
        class="main-content-container w-100 h-100 mb-16"
        max-width="714"
        flat
    >
      <ViewDetails v-if="isPast(date)" :member-detail="memberDetail"/>

      <template v-else>
        <validation-observer ref="observer" tag="div" v-slot="{ dirty }">
          <v-form @submit.prevent="submitForm">
            <!-- Absence Section -->
            <template v-if="memberDetail.absences.length" class="mb-10">
              <v-row class="mb-5">
                <v-col cols="12">
                  <div
                      v-for="(absence, index) in memberDetail.absences"
                      :key="index"
                  >
                    <section class="my-6">
                      <header class="text-subtitle-1 Text01--text">{{ $t('memberAbsent') }}</header>
                    </section>

                    <v-sheet
                        class="my-6 body-1 Text01--text pa-4 d-flex flex-column"
                        color="SystemBG"
                    >
                      <template v-if="isAbsenceDeadlinePassed">
                        <div class="subtitle-2 mb-2">
                          {{ $t('members_absence_can_no_longer_be_changed_info_message') }}
                        </div>
                        <div>{{ $t('members_absence_can_no_longer_be_changed_info_description') }}</div>
                      </template>

                      <template v-else>
                        <div>
                          {{
                            isWholeDayAbsence(memberDetail)
                                ? $t('members_member_absent_whole_day_pickup_info_message', { entity: $t('child') })
                                : $t('members_pickup_partially_absent_info_message')
                          }}
                        </div>
                        <div v-if="absenceButtonText()" class="text-right">
                          <v-btn
                              text
                              color="primary"
                              class="pa-0 text-none subtitle-2"
                              @click="absenceButtonAction"
                          >
                            {{ absenceButtonText() }}
                          </v-btn>
                        </div>
                      </template>
                    </v-sheet>

                    <InfoItem
                        icon="mdi-account-off"
                        icon-color="SystemCTA"
                        :is-text-bold="true"
                        :text-content="getAbsentTextForCard(absence)"
                    />
                    <template v-if="absence.description">
                      <v-divider class="my-3"/>
                      <InfoItem
                          icon="mdi-message-reply-text" icon-color="SystemCTA"
                          :textContent="absence.description"
                      />
                    </template>
                  </div>
                </v-col>
              </v-row>
              <validation-provider vid="absent_entry_form">
                <absent-entry-dialog
                    v-model="absentEntryForm"
                    :mode="absentEntryFormMode"
                    :sick-list-entry="memberDetail.absences[0]"
                    :config="absenceConfig"
                    @saveAbsentEntry="saveAbsentEntry"
                    @saveAbsentEntryError="saveAbsentEntry"
                    @deleteAbsence="initDeleteConfirm"
                />
              </validation-provider>
              <confirmation-modal
                  :max-width="544"
                  :loading="loading"
                  v-model="deleteConfirmationForm"
                  :title="$t('deleteAbsentMessage')"
                  :label="$t('confirmInfo')"
                  :apply-button-label="$t('delete')"
                  apply-button-color="error"
                  @apply="initDeleteAbsentEntry"
              />
            </template>

            <!-- Pick-up Time Section -->
            <template v-if="isPickupActive">
              <template v-if="pickupTimeIsEditable">
                <div v-if="!isPickupDeadlinePassed" class="mb-5">
                  <v-row>
                    <v-col cols="12">
                      <div class="d-flex align-center justify-space-between my-6">
                        <div class="text-subtitle-1 Text01--text mr-0">{{ $t('memberPickupTime') }}</div>
                        <validation-provider vid="pickup_active">
                          <v-switch
                              v-model="memberDetail.pickup_active"
                              class="ma-0 pa-0 mr-n2"
                              inset
                              hide-details="auto"
                          />
                        </validation-provider>
                      </div>

                      <v-sheet
                          v-if="isToday(date) && pickupTimeUntil"
                          class="body-1 Text01--text pa-4 my-5 d-flex justify-space-between align-center"
                          color="SystemBG"
                      >
                        {{ $t('members_pickup_edit_until_info_message', { time: pickupTimeUntil }) }}
                      </v-sheet>


                      <div v-if="memberDetail.pickup_active">
                        <validation-provider v-slot="{ errors }" vid="pickup_time">
                          <PickupTimePicker
                              v-model="memberDetail.pickup_time"
                              :errors="errors"
                              @input="updatePickupTime"
                          />
                        </validation-provider>

                        <validation-provider v-slot="{ errors }" vid="pickup_time_notes">
                          <TextareaWithCounter
                              v-model="memberDetail.pickup_time_notes"
                              :errors="errors"
                              :label="$t('comment')"
                              class="mt-4"
                          />
                        </validation-provider>
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <div v-else class="mb-5">
                  <v-row>
                    <v-col cols="12">
                      <div class="d-flex align-center justify-space-between my-6">
                        <div class="text-subtitle-1 Text01--text mr-0">{{ $t('memberPickupTime') }}</div>
                      </div>
                      <InfoMessage
                          :title="$t('members_pickup_can_no_longer_be_changed_info_message')"
                          :description="$t('members_pickup_can_no_longer_be_changed_description')"
                      />
                    </v-col>
                  </v-row>
                </div>
              </template>
              <template v-else>
                <div class="mb-5">
                  <v-row>
                    <v-col cols="12">
                      <div class="d-flex align-center justify-space-between my-6">
                        <div class="text-subtitle-1 Text01--text mr-0">{{ $t('memberPickupTime') }}</div>
                      </div>
                      <InfoMessage
                          :title="$t('members_pickup_cannot_be_changed_info_message')"
                          :description="$t('members_pickup_cannot_be_changed_description')"
                      />
                    </v-col>
                  </v-row>
                </div>
              </template>
            </template>

            <!-- Sticky Save Button -->
            <div v-if="isSaveButtonVisible" class="sticky-button-container">
              <v-row align="end" justify="center" class="pa-3">
                <v-col cols="12" sm="6" md="5">
                  <v-btn block color="primary" :disabled="!dirty" @click="submitForm">
                    {{ $t('save') }}
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-form>
        </validation-observer>
      </template>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { MemberActions, MemberGetters } from "@/store/member";
import { SickListActions, SickListFormModes } from "@/store/sicklist";
import {
  PickupLunchDetailsActions,
  PickupLunchDetailsGetters,
  PickupLunchDetailsMutations,
} from "@/store/pickupLunchDetails";
import { UserGetters } from "@/store/user";
import pickupLunchMixin from "@/mixins/pickupLunchMixin";
import dateUtilityMixin from "@/mixins/dateUtilityMixin";
import { setCurrentDate } from "@/helpers/localStorageUtils";
import PickupTimePicker from "@/components/pages/MemberPage/components/EditPickupTimes/PickupTimePicker.vue";
import TextareaWithCounter from "@/components/pages/MemberPage/components/PickupTimesLunch/TextareaWithCounter";
import AbsentEntryDialog from "@/components/pages/AbsentEntriesListPage/components/AbsentEntryDialog";
import ViewDetails from "@/components/pages/MemberPage/components/EditPickupTimes/ViewDetails";
import InfoItem from "@/components/pages/MemberPage/components/PickupTimesLunch/InfoItem";
import ConfirmationModal from "@/components/ConfirmationModal";
import InfoMessage from "@/components/pages/MemberPage/components/PickupTimesLunch/InfoMessage";

export default {
  name: "EditPickupTimes",

  components: {
    InfoItem,
    ViewDetails,
    ConfirmationModal,
    AbsentEntryDialog,
    TextareaWithCounter,
    PickupTimePicker,
    InfoMessage
  },

  mixins: [ pickupLunchMixin, dateUtilityMixin ],

  data() {
    return {
      loading: true,
      absentEntryForm: false,
      absentEntryFormMode: SickListFormModes.Edit,
      deleteConfirmationForm: false,
      absenceConfig: {
        showabsentcomment: false,
        showabsentreason: false,
      },
    };
  },

  async created() {
    await this.fetchMember(this.memberId);

    const { config: { show_check_in_check_out } } = this.member;

    if (!show_check_in_check_out) {
      return this.$router.push('/404');
    }

    await this.fetchMemberDetails(true);

    if (Object.keys(this.modelMemberDetail).length === 0) {
      return this.$router.push('/404');
    }

    this.absenceConfig.showabsentcomment = this.member.config.showabsentcomment;
    this.absenceConfig.showabsentreason = this.member.config.showabsentreason;
    setCurrentDate(this.date)

    this.loading = false;
  },

  computed: {
    ...mapGetters({
      member: `member/${MemberGetters.Member}`,
      memberDetail: `pickupLunchDetails/${PickupLunchDetailsGetters.Changed}`,
      modelMemberDetail: `pickupLunchDetails/${PickupLunchDetailsGetters.Model}`,
      accountConfiguration: `user/${UserGetters.AccountConfiguration}`,
    }),
    memberId() {
      return this.$route.params.id;
    },
    date() {
      return this.$route.params.date;
    },
    pickupTimeUntil() {
      return this.accountConfiguration?.pickupTimeUntil;
    },
    isPickupActive() {
      return this.member?.config?.pickupinfo && !this.isWholeDayAbsence(this.memberDetail);
    },
    isPickupDeadlinePassed() {
      if (!this.isToday(this.date)) {
        return false;
      }
      return this.hasTimePassed(this.accountConfiguration.pickupTimeUntil);
    },
    isAbsenceDeadlinePassed() {
      if (!this.isToday(this.date)) {
        return false;
      }
      return this.hasTimePassed(this.accountConfiguration.absencesUntil);
    },
    isSaveButtonVisible() {
      if (this.pickupTimeIsEditable) {
        return !this.isLunchDeadlinePassed || !this.isPickupDeadlinePassed;
      }

      return false;
    }
  },

  methods: {
    ...mapMutations({
      setChangedAbsences: `pickupLunchDetails/${PickupLunchDetailsMutations.SetChangedAbsences}`,
      truncateChanged: `pickupLunchDetails/${PickupLunchDetailsMutations.TruncateChanged}`,
    }),
    ...mapActions({
      fetchMember: `member/${MemberActions.FetchMember}`,
      deleteAbsentEntry: `sicklist/${SickListActions.DeleteAbsentEntry}`,
      fetchPickupLunchDetail: `pickupLunchDetails/${PickupLunchDetailsActions.FetchPickupLunchDetail}`,
      updatePickupLunchDetail: `pickupLunchDetails/${PickupLunchDetailsActions.UpdatePickupLunchDetail}`,
    }),

    updatePickupTime(newTime) {
      this.memberDetail.pickup_time = newTime;
    },

    absenceButtonText() {
      const { absences } = this.memberDetail;
      let first = absences.find(Boolean)
      if (absences.length === 1 && first.editable) {
        return this.$t('actionEditAbsent');
      } else if (absences.length > 1 && absences.some(absence => absence.editable)) {
        return this.$t('action_go_to_absences');
      }
      return null;
    },

    absenceButtonAction() {
      const { absences } = this.memberDetail;
      let first = absences.find(Boolean)
      if (absences.length === 1 && first.editable) {
        this.sickListEntry = first;
        this.absentEntryForm = true;
      } else if (absences.length > 1 && absences.some(absence => absence.editable)) {
        this.$router.push({ name: 'member.show.absent_entries' });
      }
    },

    async saveAbsentEntry() {
      this.loading = true;
      await this.fetchMemberDetails();
      this.setChangedAbsences(this.modelMemberDetail.absences);
      this.loading = false;
    },

    initDeleteConfirm() {
      this.deleteConfirmationForm = true;
    },

    async initDeleteAbsentEntry() {
      this.loading = true;
      try {
        await this.deleteAbsentEntry({
          memberId: this.memberId,
          absentEntryId: this.memberDetail.absences[0].id
        });
        await this.fetchMemberDetails();
        this.setChangedAbsences(this.modelMemberDetail.absences);
        this.$toasted.error(this.$t('absenceDeletedMessage'));
      } catch (e) {
        this.$toasted.error(e.message);
      } finally {
        this.deleteConfirmationForm = false;
        this.absentEntryForm = false;
        this.loading = false;
      }
    },

    async fetchMemberDetails(setChanged = false) {
      await this.fetchPickupLunchDetail({ memberId: this.memberId, date: this.date, setChanged });
    },

    async submitForm() {
      try {
        this.loading = true;

        const payload = this.buildPayload();

        await this.updatePickupLunchDetail({ memberId: this.memberId, payload });
        this.loading = false;

        this.$router.go(-1);

        this.$toasted.success(this.$t('members_pickup_lunch_update_success'));
      } catch (error) {
        this.$refs.observer.setErrors(error.response.data.errors);
        this.loading = false;
        this.$toasted.error(error.response.data.message || 'Something went wrong');
        setTimeout(() => {
          location.reload();
        }, 3000);
      }
    },

    buildPayload() {
      const payload = {
        date: this.memberDetail.date
      };

      const pickupFields = [ 'pickup_active', 'pickup_time', 'pickup_time_notes' ];
      const pickupChanged = pickupFields.some(field => this.memberDetail[field] !== this.modelMemberDetail[field]);

      if (pickupChanged) {
        payload.pickup_active = this.memberDetail.pickup_active;
        if (payload.pickup_active) {
          payload.pickup_time = this.memberDetail.pickup_time;
          payload.pickup_time_notes = this.memberDetail.pickup_time_notes;
        }
      }

      return payload;
    },

  },

  beforeDestroy() {
    this.truncateChanged();
  },
}
</script>

<style scoped lang="scss">
.main-content-container {
  .sticky-button-container {
    position: fixed;
    z-index: 7;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--v-SystemWhite-base);
    padding: 16px 0;
  }
}
</style>
