import Vue from 'vue';
import Vuex from 'vuex';

import user from './user';
import news from './news';
import event from './event';
import contacts from './contacts';
import global from './global';
import member from './member';
import sicklist from './sicklist';
import foodPlans from "./foodPlans";
import timetables from "./timetables";
import hourPlans from "./hourPlans";
import association from "./association";
import noticeboard from "./noticeboard";
import legalsDocuments from "./legalsDocuments";
import additionalContacts from "./additionalContacts";
import errors from "./errors";
import pickupLunchDetails from "@/store/pickupLunchDetails";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        user,
        news,
        event,
        contacts,
        global,
        member,
        sicklist,
        foodPlans,
        timetables,
        hourPlans,
        association,
        noticeboard,
        legalsDocuments,
        additionalContacts,
        pickupLunchDetails,
        errors,
    },
});
