import {UserActions, UserGetters} from "@/store/user";
import {AssociationsService} from "@/services/ApiServices";
import Vue from 'vue';
import eventBus from './../services/EventBus'
import {GlobalEvent} from '@/constants';

const initialState = {
    associations: [],
};

export const AssociationsActions = {
    ChangeAssociation: 'changeAssociation',
    GetListOfAssociations: 'getListOfAssociations'
};

const state = {...initialState};

const actions = {
    async [AssociationsActions.ChangeAssociation]({dispatch, rootGetters}, newAssociationId) {
        const associationId = rootGetters[`user/${UserGetters.AssociationId}`];
        const authenticatedUser = rootGetters[`user/${UserGetters.User}`];

        return AssociationsService.changeAssociation(associationId, authenticatedUser.user_id, newAssociationId).then(async ({data}) => {
            if (Vue.cookie.get('remember_me_token')) {
                data.remember_me_token = Vue.cookie.get('remember_me_token');
            }
            await dispatch(`user/${UserActions.UpdateAssociationInfo}`, data, {root: true});
            eventBus.$emit(GlobalEvent.ASSOCIATION_WAS_CHANGED);
            return true;
        }).catch((e) => {
            throw new Error(e.response.data.message);
        });
    },

    async [AssociationsActions.GetListOfAssociations]({dispatch, rootGetters}) {
        const associationId = rootGetters[`user/${UserGetters.AssociationId}`];
        const authenticatedUser = rootGetters[`user/${UserGetters.User}`];

        return AssociationsService.getListOfAssociations(associationId, authenticatedUser.user_id).then(({data}) => {
            dispatch(`user/${UserActions.UpdateAssociationsList}`, data.associations, {root: true});

            return data;
        }).catch((e) => {
            throw new Error(e.response.data.message);
        });
    }
};

export default {
    namespaced: true,
    state,
    actions,
};
