<template>
  <div class="container pt-0 h-100 px-0 px-md-3">
    <v-row class="justify-center h-100" sticky-container>

      <v-col class="pa-0 pa-md-3" :md="!isNoNewsInSystem ? 7 : 12" cols="12" order="2" order-md="1">
        <v-list two-line class="internal-content-container py-0">
          <SkeletonLoader v-if="loading"/>

          <template v-else>
            <news-list-item
                v-for="newsItem in newsItems"
                :newsItem="newsItem"
                :is-hidden="newsItem.is_hidden"
                :is-pinned="newsItem.is_pinned"
                @hideNewsItem="hideNewsItem"
                @unHideNewsItem="unHideNewsItem"
                @toggleFavourite="toggleFavourite"
                :key="newsItem.id"
            />
            <infinite-loading :identifier="infiniteId" @infinite="loadNews">
              <template v-slot:spinner>
                <SkeletonLoader/>
              </template>
              <template v-slot:no-results>
                <empty-state v-if="filterIsActive"
                    :title="$t('noResultsTitle')"
                    :subtitle="$t('noResultsInfo')"
                    image="/images/empty-results-news.svg"
                />
                <empty-state v-else
                    :title="$t('no_news_title')"
                    :subtitle="$t('no_news_subtitle')"
                    image="/images/empty-news.svg"
                />
              </template>
            </infinite-loading>
          </template>
        </v-list>
      </v-col>

      <v-divider vertical class="order-md-2 d-none d-md-inline-flex mt-n10" v-if="!isNoNewsInSystem"/>

      <v-col class="pa-0 pa-md-3" md="4" cols="12" order="1" order-md="2" v-if="!isNoNewsInSystem">
        <NewsFilter class="d-none d-md-block"/>

        <NewsFilterMobile class="d-block d-md-none"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {NewsActions, NewsGetters} from '@/store/news';
import {UserActions} from "@/store/user";
import NewsListItem from '@/components/pages/NewsListPage/components/NewsListItem';
import NewsFilter from "@/components/pages/NewsListPage/components/NewsFilter";
import NewsFilterMobile from "@/components/pages/NewsListPage/components/NewsFilterMobile";
import {GlobalActions, GlobalGetters} from "@/store/global";
import EmptyState from "@/components/EmptyState";

export default {
  name: "NewsListPage",

  components: {
    NewsListItem,
    NewsFilter,
    NewsFilterMobile,
    EmptyState,
  },

  mounted() {
    this.initFetchMembers();
  },

  data() {
    return {
      loading: true,
      offset: 0,
      infiniteId: +new Date(),
      newsItems: [],
    }
  },
  computed: {
    ...mapGetters({
      newsCount: `news/${NewsGetters.NewsCount}`,
      query: `global/${GlobalGetters.Query}`,
      filters: `news/${NewsGetters.Filters}`,
    }),

    filterIsActive() {
      const {filter_by_type, filter_by_statuses, filter_by_groups_ids, filter_by_receiver_type} = this.filters;

      return filter_by_type !== "all" || filter_by_statuses.length || filter_by_groups_ids.length || filter_by_receiver_type !== "all";
    },

    isNoNewsInSystem() {
      return !this.newsItems.length && !this.filterIsActive;
    }
  },
  watch: {
    query() {
      this.resetList();
    },
    filters: {
      handler: function () {
        this.resetList();
      },
      deep: true
    },
  },
  methods: {
    ...mapActions({
      fetchNewsItems: `news/${NewsActions.FetchNewsItems}`,
      fetchMembers: `user/${UserActions.FetchMembers}`,
      updateQuery: `global/${GlobalActions.UpdateQuery}`
    }),

    async initFetchMembers() {
      await this.fetchMembers();
      this.loading = false;
    },
    loadNews($state) {
      this.fetchNewsItems({
        offset: this.offset,
        query: this.query,
        ...this.filters
      })
          .then((data) => {
            if (data.length > 0) {
              this.offset++;
              this.newsItems.push(...data);
              $state.loaded();
            } else {
              $state.complete();
            }
          });
    },
    resetList() {
      this.newsItems = [];
      this.offset = 0;
      this.infiniteId += 1;
    },
    hideNewsItem(newsId) {
      this.removeNewsItemAndResetList(newsId);
    },
    unHideNewsItem(newsId) {
      this.removeNewsItemAndResetList(newsId);
    },
    toggleFavourite({id, isFavourite}) {
      const {filter_by_statuses} = this.filters;

      if (!isFavourite && filter_by_statuses.includes('favourite')) {
        this.removeNewsItemAndResetList(id);
      }
    },
    removeNewsItemAndResetList(newsId) {
      const newsItemIndex = this.newsItems.findIndex(newsItem => newsItem.id === newsId);
      if (newsItemIndex > -1) {
        this.newsItems.splice(newsItemIndex, 1);
        if (this.newsItems.length === 0) {
          this.resetList();
        }
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.updateQuery('');
    next();
  }
}
</script>
