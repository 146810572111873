<template>
  <div>
    <p class="mb-2 Text01--text text-subtitle-1 mb-5">{{ $t('newsResponseInstSignature') }}</p>
    <div class="signature-container" :class="{'has-error': fieldErrors.length > 0}">
      <vue-signature-pad
          class="signature-pad"
          ref="signaturePad"
          :options="{ onEnd, onBegin: () => {$refs.signaturePad.resizeCanvas()}, backgroundColor: 'white' }"
          width="100%"
          height="272px"
      />
      <v-btn v-if="signature" class="signature-clear-button" small text color="primary" @click="clear">
        <v-icon size="20" :class="$vuetify.rtl ? 'ml-1' : 'mr-1'">mdi-close</v-icon>
        <span class="text-capitalize text-subtitle-2">{{ $t('clear') }}</span>
      </v-btn>
    </div>
    <small class="error--text" v-if="fieldErrors.length > 0">{{ fieldErrors.join(', ') }}</small>
  </div>
</template>

<script>
import validationMixin from "@/mixins/validationMixin";

export default {
  props: {
    validationResult: {
      required: false,
    }
  },

  mixins: [validationMixin],

  computed: {
    fieldErrors() {
      if (!this.validationResult) {
        return [];
      }
      return this.getErrorMessagesForField(this.validationResult, this.$t('newsResponseErrorSignature'));
    },
  },

  data() {
    return {
      signature: null,
    }
  },

  methods: {
    clear() {
      this.$refs.signaturePad.clearSignature();

      this.signature = null;

      this.$emit('input', null);
    },

    onEnd() {
      const {data} = this.$refs.signaturePad.saveSignature('image/jpeg');

      this.signature = data;

      this.$emit('input', data);
    }
  }
}
</script>
