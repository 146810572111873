<template>
  <v-dialog
      v-model="dialog"
      :max-width="600"
      scrollable
      @click:outside="close"
      overlay-color="#061F4B"
      overlay-opacity="0.8"
      :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card :disabled="loading" :loading="loading" tile>
      <v-toolbar flat color="transparent">
        <span class="text-subtitle-1 Text01--text">{{ $t('membersAdditionalContactsAdd') }}</span>
        <v-spacer/>
        <v-btn :class="$vuetify.rtl ? 'ml-0' : 'mr-0'" icon color="Text03" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-divider/>

      <v-card-text>
        <base-form type="create" ref="form" @submit="initSubmit"/>
      </v-card-text>

      <v-divider/>

      <v-card-actions class="justify-end pa-4">
        <v-btn
            :min-width="106"
            color="primary"
            outlined
            :disabled="loading"
            @click="close"
        >
          {{ $t('cancel') }}
        </v-btn>

        <v-btn
            :min-width="106"
            color="primary"
            depressed
            :loading="loading"
            :class="$vuetify.rtl ? 'mr-4' : 'ml-4'"
            @click="initSubmit"
        >
          {{ $t('add') }}
        </v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import {
  AdditionalContactsGetters,
  AdditionalContactsActions,
  AdditionalContactsMutations
} from "@/store/additionalContacts";
import BaseForm from "@/components/pages/MemberPage/components/AdditionalContacts/BaseForm";

export default {
  name: "CreateModal",

  components: {
    BaseForm,
  },

  props: {
    value: {
      type: Boolean,
      default: null
    }
  },

  methods: {
    ...mapActions({
      createAdditionalContact: `additionalContacts/${AdditionalContactsActions.CreateAdditionalContact}`,
    }),

    ...mapMutations({
      truncate: `additionalContacts/${AdditionalContactsMutations.TruncateModel}`,
      setLoading: `additionalContacts/${AdditionalContactsMutations.SetLoading}`,
    }),

    close() {
      this.$emit('close');
      this.dialog = !this.dialog;

      this.truncate();

      this.$refs?.form?.$refs?.observer.reset();
    },

    async initSubmit() {
      const success = await this.$refs?.form?.$refs?.observer.validate();

      if (success) {
        try {
          await this.createAdditionalContact(this.contact);

          this.$emit("createAdditionalContact");

          this.dialog = false;

          const {first_name, last_name} = this.contact;

          this.$toasted.success(this.$t('addedSuccess', {name: `${first_name} ${last_name}`}));

          this.truncate();

          this.$refs?.form?.$refs?.observer.reset();
        } catch (e) {
          this.setLoading(false);
          if (e?.response?.status === 422) {
            const {response: {data: {errors}}} = e;

            this.$refs?.form?.$refs?.observer.setErrors(errors);
          }
        }
      }
    }
  },

  computed: {
    ...mapGetters({
      loading: `additionalContacts/${AdditionalContactsGetters.Loading}`,
      contact: `additionalContacts/${AdditionalContactsGetters.Model}`,
    }),

    dialog: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      }
    },
  }
}
</script>

<style scoped/>