import i18next from 'i18next';
import {polyfillCountryFlagEmojis} from "country-flag-emoji-polyfill";
import ar from '@/translations/ar.json';
import cs from '@/translations/cs.json';
import da from '@/translations/da.json';
import de from '@/translations/de.json';
import en from '@/translations/en.json';
import es from '@/translations/es.json';
import fa from '@/translations/fa.json';
import fr from '@/translations/fr.json';
import it from '@/translations/it.json';
import nl from '@/translations/nl.json';
import pl from '@/translations/pl.json';
import pt from '@/translations/pt.json';
import ro from '@/translations/ro.json';
import ru from '@/translations/ru.json';
import sq from '@/translations/sq.json';
import tr from '@/translations/tr.json';
import uk from '@/translations/uk.json';

polyfillCountryFlagEmojis();

export const AVAILABLE_LANGUAGES = [
    'de',
    'en',
    'ar',
    'sq',
    'cs',
    'da',
    'nl',
    'it',
    'fr',
    'fa',
    'pl',
    'pt',
    'ro',
    'ru',
    'es',
    'tr',
    'uk',
];

export const AVAILABLE_IN_APP_TRANSLATION_LANGUAGES = [
    'de',
    'en',
    'ar',
    'bg',
    'hr',
    'cs',
    'da',
    'nl',
    'et',
    'fi',
    'fr',
    'el',
    'he',
    'hu',
    'it',
    'lv',
    'lt',
    'nb',
    'pl',
    'pt',
    'ro',
    'ru',
    'sr',
    'sk',
    'es',
    'sv',
    'tr',
    'uk',
    'ur',
];

export const LANGUAGE_TITLES = {
    ar: 'lang_arabic',
    bg: 'lang_bulgarian',
    hr: 'lang_croatian',
    cs: 'lang_czech',
    da: 'lang_danish',
    nl: 'lang_dutch',
    en: 'lang_english',
    et: 'lang_estonian',
    fi: 'lang_finnish',
    fr: 'lang_french',
    de: 'lang_german',
    el: 'lang_greek',
    he: 'lang_hebrew',
    hu: 'lang_hungarian',
    it: 'lang_italian',
    fa: 'lang_persian',
    lv: 'lang_latvian',
    lt: 'lang_lithuanian',
    nb: 'lang_norwegian',
    pl: 'lang_polish',
    pt: 'lang_portuguese',
    ro: 'lang_romanian',
    ru: 'lang_russian',
    sq: 'lang_albanian',
    sr: 'lang_serbian',
    sk: 'lang_slovakian',
    es: 'lang_spanish',
    sv: 'lang_swedish',
    tr: 'lang_turkish',
    uk: 'lang_ukrainian',
    ur: 'lang_urdu',
};

export const LANGUAGE_NATIVE_TITLE = {
    ar: 'العربية',
    bg: 'Български',
    hr: 'Hrvatski',
    cs: 'Čeština',
    da: 'Dansk',
    nl: 'Nederlands',
    en: 'English',
    et: 'Eesti',
    fi: 'Suomi',
    fr: 'Français',
    de: 'Deutsch',
    el: 'Ελληνικά',
    he: 'עברית',
    hu: 'Magyar',
    it: 'Italiano',
    fa: 'فارسی',
    lv: 'Latviešu',
    lt: 'Lietuvių',
    nb: 'Norsk',
    pl: 'Polski',
    pt: 'Português',
    ro: 'Română',
    ru: 'Русский',
    sq: 'Shqip',
    sr: 'Српски',
    sk: 'Slovenčina',
    es: 'Español',
    sv: 'Svenska',
    tr: 'Türkçe',
    uk: 'Українська',
    ur: 'اردو',
};

export const LANGUAGE_FLAG_EMOJIS = {
    ar: '🇦🇪',
    bg: '🇧🇬',
    hr: '🇭🇷',
    cs: '🇨🇿',
    da: '🇩🇰',
    nl: '🇳🇱',
    en: '🇬🇧',
    et: '🇪🇪',
    fi: '🇫🇮',
    fr: '🇫🇷',
    de: '🇩🇪',
    el: '🇬🇷',
    he: '🇮🇱',
    hu: '🇭🇺',
    it: '🇮🇹',
    fa: '🇮🇷',
    lv: '🇱🇻',
    lt: '🇱🇹',
    nb: '🇳🇴',
    pl: '🇵🇱',
    pt: '🇵🇹',
    ro: '🇷🇴',
    ru: '🇷🇺',
    sq: '🇦🇱',
    sr: '🇷🇸',
    sk: '🇸🇰',
    es: '🇪🇸',
    sv: '🇸🇪',
    tr: '🇹🇷',
    uk: '🇺🇦',
    ur: '🇵🇰',
};

export const BCP_47_LANGUAGE_TAGS = {
    ar: 'ar-SA',
    cs: 'cs-CZ',
    da: 'da-DK',
    nl: 'nl-NL',
    en: 'en-GB',
    fa: 'fa-IR',
    fi: 'fi-FI',
    fr: 'fr-FR',
    de: 'de-DE',
    el: 'el-GR',
    he: 'he-IL',
    hu: 'hu-HU',
    it: 'it-IT',
    nb: 'no-NO',
    pl: 'pl-PL',
    pt: 'pt-PT',
    ro: 'ro-RO',
    ru: 'ru-RU',
    sk: 'sk-SK',
    es: 'es-ES',
    sv: 'sv-SE',
    tr: 'tr-TR',
    uk: 'uk-UK',
};

export function simpleLocaleName(code) {
    code = code?.toLowerCase();

    if (code && (code.includes('_') || code.includes('-'))) {
        code = code.substring(0, 2);
    }

    return code;
}

export function fullLocaleName(code) {
    code = code.toLowerCase();

    let locale = Object.values(BCP_47_LANGUAGE_TAGS).find((tag) => {
        return tag.includes(code);
    });

    if (!locale) {
        return code;
    }

    return locale.replace('-', '_');
}

export const RTL_LANGUAGE_CODES = ['ar', 'fa'];

export default function () {
    i18next.init({
        lng: 'de',
        fallbackLng: 'de',
        whitelist: AVAILABLE_LANGUAGES,
        resources: {
            ar: {translation: ar},
            cs: {translation: cs},
            da: {translation: da},
            nl: {translation: nl},
            en: {translation: en},
            fr: {translation: fr},
            de: {translation: de},
            it: {translation: it},
            fa: {translation: fa},
            pl: {translation: pl},
            pt: {translation: pt},
            ro: {translation: ro},
            ru: {translation: ru},
            sq: {translation: sq},
            es: {translation: es},
            tr: {translation: tr},
            uk: {translation: uk},
        }
    });
}