<template>
  <v-row class="fill-height">
    <v-col>
      <SkeletonLoader v-if="loading"/>

      <div class="main-content-container fill-height" v-else>
        <v-card tile flat class="fill-height">
          <v-card-text class="px-sm-8">
            <v-toolbar
                height="40"
                flat
                color="white"
                class="news-toolbar"
            >
              <span class="text-subtitle-1 Text01--text">
                {{ eventDate }}
              </span>

              <v-spacer/>

              <v-menu tile offset-y v-if="!event.deleted">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      v-bind="attrs"
                      v-on="on"
                      color="SystemCTA"
                      icon
                  >
                    <v-icon>mdi-calendar-plus</v-icon>
                  </v-btn>
                </template>
                <v-list class="py-0">
                  <v-list-item @click.stop.prevent="addToAppleCalendar">
                    <v-list-item-icon :class="$vuetify.rtl ? 'ml-3' : 'mr-3'">
                      <v-img src="/images/apple-icon.svg"/>
                    </v-list-item-icon>
                    <v-list-item-title class="Text01--text text-body-2">{{ $t('appleCalendar') }}</v-list-item-title>
                  </v-list-item>

                  <v-divider/>

                  <a :href="googleCalendarLink"
                     target="_blank"
                     class="v-list-item v-list-item--link theme--light">
                    <v-list-item-icon :class="$vuetify.rtl ? 'ml-3' : 'mr-3'">
                      <v-img src="/images/google-icon.svg"/>
                    </v-list-item-icon>
                    <v-list-item-title class="Text01--text text-body-2">{{ $t('googleCalendar') }}</v-list-item-title>
                  </a>
                </v-list>
              </v-menu>
            </v-toolbar>

            <div class="d-flex mt-3">
              <v-row no-gutters>
                <v-col cols="12">
                  <MoreGroups class="w-100" :groups="event.groups"/>
                </v-col>

                <v-col cols="12" class="pb-2 pt-2">
                  <event-chips :event-item="event"/>
                </v-col>

                <v-col cols="12" class="pb-2 pt-0">
                  <div
                      class="text-subtitle-1 Text01--text"
                      :class="{'text-decoration-line-through': event.deleted, 'direction-rtl': isRTL}"
                  >
                    {{ event.title }}
                  </div>
                </v-col>

                <v-col cols="12">
                  <span class="Text02--text text-body-2">{{ date }}</span>
                  <span v-if="event.venue" :class="$vuetify.rtl ? 'mr-2' : 'ml-2'" class="Text02--text text-body-2">| {{ event.venue }}</span>
                </v-col>

                <v-col v-if="showPostedBy" cols="12">
                  <span class="Text02--text text-body-2" v-html="$t('posted_by', {poster: itemPoster})"/>
                </v-col>

                <v-col cols="12" v-if="event.in_app_translator_is_active" class="my-2">
                  <translation-btn @ready="requestTranslatedItem()"/>
                </v-col>

                <v-col cols="12" class="py-3 event-content-body">
                  <div class="text-body-1" :class="{'direction-rtl': isRTL}">
                    <span :inner-html.prop="event.content | linkify | new_line_to_br"></span>
                  </div>
                </v-col>
              </v-row>
            </div>

            <div class="d-flex" v-if="event.type === EventType.Meeting">
              <v-row no-gutters>
                <v-col cols="12">
                  <p class="text-subtitle-1 Text01--text">{{ $t('participants') }}</p>
                </v-col>
                <v-col cols="12">
                  <div v-for="(participant, index) in event.participants" :key="index"
                       class="Text02--text text-body-2">
                    {{ participant.participant_name }}
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import {mapActions} from 'vuex';
import {EventActions, EventType} from '@/store/event';
import moment from "moment";
import {downloadBlob, isInAppTranslationRequestedLangRTL} from "@/helpers/helpers";
import {generateAppleCalendarIcsContent, generateGoogleCalendarLink} from "@/helpers/calendars";
import EventChips from "@/components/pages/EventsListPage/components/EventChips";
import i18next from "i18next";
import TranslationBtn from "@/components/TranslationBtn";
import MoreGroups from "@/components/pages/NewsItemPage/components/MoreGroups";

export default {
  name: "EventPage",

  components: {
    EventChips,
    TranslationBtn,
    MoreGroups,
  },

  data() {
    return {
      event: null,
      loading: true,
      isRTL: false,
    }
  },

  async mounted() {
    await this.initLoadingPageData();
  },

  watch: {
    event() {
      if (this.event && !this.event.read) {
        this.markAsSeen(this.event.id);
      }
    }
  },

  computed: {
    date() {
      if (this.event.allDay) {
        return i18next.t('eventAllDay');
      }

      return moment.utc(this.event.start).format(this.$t('normalTimeFormat'))
          + ' - '
          + moment.utc(this.event.end).format(this.$t('normalTimeFormat'));
    },

    eventDate() {
      let dateString = '';
      dateString += moment.utc(this.event.start).format(this.$t('eventsDetailsDateTitle'));
      if (!this.isAllDay() || this.moreThanOneDayEvent()) {
        dateString
            += ' - '
            + moment.utc(this.event.end).format(this.$t('eventsDetailsDateTitle'));
      }
      return dateString;
    },

    googleCalendarLink() {
      return generateGoogleCalendarLink(this.event);
    },

    itemPoster() {
      return '<strong>' + this.event.poster + '</strong>';
    },

    showPostedBy() {
      return (this.event.poster && this.event.poster.length > 0);
    },

    eventBarColor() {
      return this.event.colour || '';
    },

    EventType() {
      return EventType;
    },
  },

  methods: {
    ...mapActions({
      fetchEventsItem: `event/${EventActions.FetchEventsItem}`,
      markAsSeen: `event/${EventActions.MarkAsSeen}`,
    }),

    async initLoadingPageData() {
      this.loading = true;

      this.fetchEventsItem({id: this.$route.params.id, translationLang: this.$route.params.translationLang})
          .then(async data => {
            this.event = data;

            this.loading = false;
            this.isRTL = isInAppTranslationRequestedLangRTL(this.$route.params.translationLang);
          })
          .catch(e => {
            this.$toasted.error(e.message);
          });
    },

    addToAppleCalendar() {
      let icsContent = generateAppleCalendarIcsContent(this.event);
      downloadBlob(icsContent, 'ext/calendar', this.event.title + '.ics')
    },

    isAllDay() {
      return this.event.allDay;
    },

    moreThanOneDayEvent() {
      return this.dayDiff(
          moment.utc(this.event.start),
          moment.utc(this.event.end)
      ) > 1;
    },

    dayDiff(start, end) {
      return end.diff(start, 'days');
    },

    async requestTranslatedItem() {
      await this.initLoadingPageData();
    },
  }
}
</script>
