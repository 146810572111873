import { render, staticRenderFns } from "./PinnedNewsListPage.vue?vue&type=template&id=5ee5ddb3&scoped=true&"
import script from "./PinnedNewsListPage.vue?vue&type=script&lang=js&"
export * from "./PinnedNewsListPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ee5ddb3",
  null
  
)

export default component.exports