<template>
  <div :class="hasResponse ? 'mb-7' : 'mb-4'">
    <div v-if="readonly">
      <answer-header
          :children-names="responseChildrenNames"
          :response="response"
          :news-item="newsItem"
          v-if="hasResponse"
      />
    </div>

    <div class="text-subtitle-1 Text01--text mb-5" v-else>{{ $t('newsResponseInstFbOption') }}</div>

    <p class="text-subtitle-1 Text01--text mt-5" v-if="hasResponse">
      {{ $t('newsResponseInstFbOptionAnswered') }}
    </p>

    <div v-for="(option, index) in newsItem.options" :key="index" :class="{'direction-rtl': isRTL}">
      <v-row no-gutters>
        <v-col cols="12" class="pb-3" v-if="!readonly">
          <p class="text-subtitle-2 Text01--text">{{ option.text }}</p>
          <v-textarea
              v-model="optionsValues[option.id]"
              counter
              :error-messages="fieldErrors(option.id, option.text)"
              outlined
              auto-grow
              rows="1"
              row-height="40"
              @input="updateOptionValues($event)"
              :label="$t('answer')"
          >
            <template v-slot:counter="{ props: { value } }">
              <div class="Text03--text text-caption font-weight-medium">
                {{ value }}/300
              </div>
            </template>
          </v-textarea>
        </v-col>
        <v-col cols="12" v-else>
          <div class="text-subtitle-2 Text01--text mb-3">{{ option.text }}</div>
          <div class="text-body-1 Text01--text mb-3">{{ optionsValues[option.id] }}</div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import AbstractOptions from "@/components/pages/NewsItemPage/components/AbstractOptions";
import AnswerHeader from "@/components/pages/NewsItemPage/components/AnswerHeader";
import {isInAppTranslationRequestedLangRTL} from "@/helpers/helpers";

export default {
  name: "TextareaOptions",

  components: {
    AnswerHeader,
  },

  extends: AbstractOptions,

  props: {
    value: {
      type: Object,
    },
  },

  data() {
    return {
      isRTL: false,
    }
  },
  mounted() {
    this.isRTL = isInAppTranslationRequestedLangRTL(this.$route.params.translationLang);
  },

  methods: {
    updateOptionValues() {
      this.$emit('input', this.optionsValues);
    },
    fieldErrors(optionId, optionText) {
      if (!this.validationResult) {
        return [];
      }

      const {required} = this.validationResult[optionId];

      if (!required) {
        optionText = 'fieldIsRequired';
      }

      let errors = this.getErrorMessagesForField(this.validationResult[optionId], optionText);

      errors = errors.map(error => this.$t(error));

      return errors;
    }
  },
  computed: {
    optionsValues: {
      get() {
        return Object.assign({}, this.value);
      }
    }
  }
}
</script>
