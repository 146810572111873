<template>
  <v-dialog
      v-model="dialog"
      :max-width="maxWidth"
      scrollable
      @click:outside="close"
      overlay-color="#061F4B"
      overlay-opacity="0.8"
  >
    <v-card :disabled="loading" :loading="loading" tile>
      <v-toolbar flat color="transparent">
        <span class="text-subtitle-1 Text01--text">{{ title }}</span>
        <v-spacer/>
        <v-btn :class="$vuetify.rtl ? 'ml-0' : 'mr-0'" icon color="Text03" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-divider/>

      <v-card-text ref="container" class="px-2 mt-5">
        <v-list two-line class="py-0">
          <v-list-item>
            <v-list-item-content class="py-0 text-truncate">
              <v-list-item-title v-if="label">
                <div class="text-subtitle-1 mb-2 text-wrap Text01--text">
                  {{ label }}
                </div>
              </v-list-item-title>
              <v-list-item-subtitle class="overflow-visible w-100">
                <slot/>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-divider/>
      <v-card-actions class="justify-end px-6 py-4">
        <v-btn
            v-if="cancelButtonVisible"
            :min-width="106"
            color="primary"
            outlined
            :disabled="loading"
            @click="close"
        >
          {{ cancelButtonLabel || $t("cancel") }}
        </v-btn>

        <v-btn
            v-if="applyButtonVisible"
            :min-width="106"
            :color="applyButtonColor"
            depressed
            :loading="loading"
            @click="apply"
            :class="$vuetify.rtl ? 'mr-4' : 'ml-4'"
        >
          {{ applyButtonLabel || $t("ok") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ConfirmationModal from "@/components/ConfirmationModal"

export default {
  extends: ConfirmationModal
}
</script>
