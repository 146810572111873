<template>
  <v-row class="h-100">
    <v-col>
      <div class="main-content-container wide h-100">
        <v-row sticky-container no-gutters class="justify-center h-100">
          <TabsList/>

          <v-col cols="12" md="8" class="position-relative">
            <SkeletonLoader v-if="loading"/>

            <template v-else>
              <v-row class="pt-5">
                <v-col cols="12">
                  <p class="text-body-1 Text01--text mt-8 mt-md-0" :class="$vuetify.rtl ? 'mr-3' : 'ml-3'">
                    {{ $t('appLanguageTitle') }}
                  </p>

                  <v-list>
                    <v-list-item
                        v-for="item in languages"
                        :key="item"
                        :class="$vuetify.rtl ? 'pl-0' : 'pr-0'"
                        @click="selectedLanguage = item"
                        v-ripple="{ class: 'SystemCTA--text' }"
                    >
                      <v-list-item-icon :class="$vuetify.rtl ? 'ml-1' : 'mr-1'">
                        <span class="flag">{{ languageFlagEmojis[item] }}</span>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title class="text-body-1 Text01--text text-capitalize">
                          {{ getLanguageTitle(item) }}
                        </v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-action :class="$vuetify.rtl ? 'ml-5' : 'mr-5'">
                        <v-icon v-if="item === selectedLanguage" color="SystemCTA"
                                :class="$vuetify.rtl ? 'float-left' : 'float-right'">
                          mdi-radiobox-marked
                        </v-icon>
                        <v-icon v-else color="GreyBorder" :class="$vuetify.rtl ? 'float-left' : 'float-right'">
                          mdi-radiobox-blank
                        </v-icon>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </template>
          </v-col>

          <v-col
              :class="$vuetify.rtl ? 'ml-md-auto border-left' : 'mr-md-auto border-right'"
              class="d-none d-md-block"
              cols="12"
              md="3"
          />

          <action-buttons :loading="loading" @discardChanges="discardChanges" @initUpdate="initUpdateAccountDetails"/>

        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import TabsList from "@/components/pages/AccountSettingsPage/components/TabsList";
import ActionButtons from "@/components/pages/AccountSettingsPage/components/ActionButtons";
import i18next from 'i18next';
import {saveLocale} from "@/helpers/helpers";
import {
  AVAILABLE_LANGUAGES,
  LANGUAGE_FLAG_EMOJIS,
  LANGUAGE_TITLES,
  RTL_LANGUAGE_CODES,
  fullLocaleName,
  LANGUAGE_NATIVE_TITLE
} from "@/i18nInit";
import moment from 'moment';
import {mapActions} from "vuex";
import {UserActions} from "@/store/user";
import {setVeeValidateTranslations} from "@/helpers/helpers";

export default {
  name: "AppLanguagePage",

  components: {
    TabsList,
    ActionButtons,
  },

  data() {
    return {
      loading: true,
      selectedLanguage: null,
    }
  },

  mounted() {
    this.loading = false;
    this.selectedLanguage = this.$i18n.i18next.language;
  },

  computed: {
    languages() {
      return this.sortLanguages(AVAILABLE_LANGUAGES);
    },
    languageFlagEmojis() {
      return LANGUAGE_FLAG_EMOJIS;
    },
  },

  methods: {
    ...mapActions({
      updateAccountDetails: `user/${UserActions.UpdateAccountDetails}`,
    }),

    getLanguageTitle(lang) {
      if (Object.prototype.hasOwnProperty.call(LANGUAGE_TITLES, lang)) {
        const nativeName = LANGUAGE_NATIVE_TITLE[lang];
        const translatedName = this.$t(LANGUAGE_TITLES[lang]);
        return `${nativeName} (${translatedName})`;
      }

      return lang;
    },

    discardChanges() {
      this.selectedLanguage = this.$i18n.i18next.language;
    },

    initUpdateAccountDetails() {
      i18next.changeLanguage(this.selectedLanguage).then(() => {
        this.$vuetify.rtl = RTL_LANGUAGE_CODES.includes(this.selectedLanguage);
      });

      saveLocale(this.selectedLanguage);
      moment.locale(this.selectedLanguage);
      this.updateAccountDetails({appLanguage: fullLocaleName(this.selectedLanguage)});
      setVeeValidateTranslations(this.selectedLanguage);

      this.$toasted.success(this.$t('changesSavedSuccessfully'));
    },

    sortLanguages(languages) {
      const fixedLanguages = ['de', 'en'];
      const sortedLanguages = languages
          .filter(lang => !fixedLanguages.includes(lang))
          .sort((a, b) => this.$t(LANGUAGE_TITLES[a]).localeCompare(this.$t(LANGUAGE_TITLES[b])));

      return [...fixedLanguages, ...sortedLanguages];
    }
  }

}
</script>

<style scoped lang="scss">
@import '~vuetify/src/styles/settings/_variables';

.v-list {
  height: calc(100vh - 287px);
  overflow-x: auto;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    height: calc(100vh - 450px);
  }
}
</style>