<template>
  <v-row class="h-100">
    <v-col>
      <div class="main-content-container wide h-100">
        <v-row sticky-container no-gutters class="justify-center h-100">
          <TabsList/>

          <v-col cols="12" md="8">
            <SkeletonLoader v-if="loading"/>

            <template v-else>
              <v-row class="mt-md-5">
                <v-col cols="12" v-if="groupChatIsActive">
                  <div class="d-flex align-center">
                    <div
                        class="text-subtitle-2 Text01--text"
                        :class="$vuetify.rtl ? 'ml-auto' : 'mr-auto'"
                    >
                      {{ $t("participateInGroupChats") }}
                    </div>

                    <v-switch
                        class="mt-0 mb-3 mt-md-3 mb-md-0"
                        v-model="isGroupActive"
                        inset
                        hide-details="auto"
                    />
                  </div>
                  <p class="text-body-2 Text02--text">{{ $t("appUserParticipateInfo") }}</p>
                </v-col>

                <v-col cols="12" class="pt-0" v-if="individualChatIsActive">
                  <div class="d-flex align-center">
                    <div
                        class="text-subtitle-2 Text01--text"
                        :class="$vuetify.rtl ? 'ml-auto' : 'mr-auto'"
                    >
                      {{ $t("directMessages") }}
                    </div>

                    <v-switch
                        class="mt-0 mb-3 mt-md-3 mb-md-0"
                        v-model="isIndividualActive"
                        inset
                        hide-details="auto"
                    />
                  </div>
                  <p class="text-body-2 Text02--text">{{ $t("appUserDirectMessagesInfo") }}</p>
                </v-col>
              </v-row>
            </template>

            <confirmation-modal
                :max-width="544"
                v-model="groupChatDisableConfirm"
                :title="$t('groupChatDisableTitle')"
                :label="$t('groupChatDisableSubtitle')"
                apply-button-color="primary"
                :apply-button-label="$t('turnOff')"
                @apply="updateUser"
                @close="fetchUser"
                :loading="loading"
            />

            <confirmation-modal
                :max-width="544"
                v-model="individualChatDisableConfirm"
                :title="$t('individualChatDisableTitle')"
                :label="$t('individualChatDisableSubtitle')"
                apply-button-color="primary"
                :apply-button-label="$t('turnOff')"
                @apply="updateUser"
                @close="fetchUser"
                :loading="loading"
            />

            <confirmation-modal
                :max-width="544"
                v-model="bothChatsDisableConfirm"
                :title="$t('bothChatsDisableTitle')"
                :label="$t('bothChatsDisableSubtitle')"
                apply-button-color="primary"
                :apply-button-label="$t('turnOff')"
                @apply="updateUser"
                @close="fetchUser"
                :loading="loading"
            />

          </v-col>

          <v-col
              :class="$vuetify.rtl ? 'ml-md-auto border-left' : 'mr-md-auto border-right'"
              class="d-none d-md-block"
              cols="12"
              md="3"
          />

          <action-buttons :loading="loading" @discardChanges="fetchUser" @initUpdate="initUpdateAccountDetails"/>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import TabsList from "@/components/pages/AccountSettingsPage/components/TabsList";
import ActionButtons from "@/components/pages/AccountSettingsPage/components/ActionButtons";
import ConfirmationModal from "@/components/ConfirmationModal";
import {mapActions, mapGetters} from "vuex";
import {UserGetters} from "@/store/user";
import {ChatUserActions} from "@/constants";

export default {
  name: "ManageMessengerPage",

  data() {
    return {
      loading: true,
      isGroupActive: false,
      isIndividualActive: false,
      groupChatDisableConfirm: false,
      individualChatDisableConfirm: false,
      bothChatsDisableConfirm: false,
      copy: null,
    }
  },

  computed: {
    ...mapGetters({
      accountConfiguration: `user/${UserGetters.AccountConfiguration}`,
      chatUser: `chat_app/user/${UserGetters.User}`,
    }),

    groupChatIsActive() {
      return this.accountConfiguration ? this.accountConfiguration.groupChatActive : false;
    },

    individualChatIsActive() {
      return this.accountConfiguration ? this.accountConfiguration.individualChatActive : false;
    },
  },

  components: {
    TabsList,
    ActionButtons,
    ConfirmationModal,
  },

  watch: {
    "chatUser.is_individual_active"(value) {
      this.isIndividualActive = value;
    },
    "chatUser.is_group_active"(value) {
      this.isGroupActive = value;
    }
  },

  async mounted() {
    await this.fetchUser();

    await this.$socketService.disconnect();
    await this.$socketService.connect();

    this.loading = false;
  },

  async beforeRouteLeave(from, to, next) {
    await this.$socketService.disconnect();
    next();
  },

  methods: {
    ...mapActions({
      fetchChatUser: `chat_app/user/${ChatUserActions.ShowMe}`,
      updateChatUser: `chat_app/user/${ChatUserActions.UpdateMe}`,
    }),

    async fetchUser() {
      const user = await this.fetchChatUser();
      const {is_group_active, is_individual_active} = user;

      this.isGroupActive = is_group_active;
      this.isIndividualActive = is_individual_active;

      this.copy = Object.assign({}, user);
    },

    async updateUser() {
      this.loading = true;

      await this.updateChatUser({
        is_group_active: this.isGroupActive,
        is_individual_active: this.isIndividualActive,
      }).then(() => {
        this.$toasted.success(this.$t('settingsSavedSuccessfully'));
        this.loading = false;
        this.groupChatDisableConfirm = false;
        this.individualChatDisableConfirm = false;
        this.bothChatsDisableConfirm = false;
      });

      await this.fetchUser();
    },

    async initUpdateAccountDetails() {
      const {is_group_active, is_individual_active} = this.copy;

      if (!this.isGroupActive && is_group_active !== this.isGroupActive) {
        this.groupChatDisableConfirm = true;
        return;
      }

      if (!this.isIndividualActive && is_individual_active !== this.isIndividualActive) {
        this.individualChatDisableConfirm = true;
        return;
      }

      if (!this.isIndividualActive && !this.isGroupActive && is_group_active !== this.isGroupActive && is_individual_active !== this.isIndividualActive) {
        this.bothChatsDisableConfirm = true;
        return;
      }

      await this.updateUser();
    }
  }
}
</script>