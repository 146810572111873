<template>
  <v-banner app class="mb-5 SystemWhite" v-sticky :sticky-z-index="2" sticky-offset="{top: 65}">
    <template v-if="pinnedNewsCount">
      <v-btn :ripple="false" class="px-3 SystemBG" text block :to="{name: 'news.pinned'}">
        <span class="text-subtitle-2 font-weight-medium text-capitalize Text01--text">
          {{ $t('pinnedNewsTitle') }}
        </span>
        <v-spacer/>
        <span
            v-if="pinnedNewsCount"
            class="text-body-2 font-weight-medium text-capitalize primary--text"
        >
          {{ $t('showAll') }} {{ pinnedNewsCount }}
        </span>
      </v-btn>
      <v-divider/>
    </template>

    <confirmation-modal
        :max-width="544"
        v-model="logoutConfirmationDialog"
        :title="$t('logoutTitle')"
        :label="$t('logoutSubtitle')"
        apply-button-color="primary"
        @apply="initLogout"
    />

    <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-sheet
            v-bind="attrs"
            v-on="on"
            class="text-subtitle-2 Text01--text pa-3 d-flex justify-space-between"
            color="SystemBG"
        >
          {{ $t("filter_news") }}
          <template v-if="resetButtonCounter">({{ resetButtonCounter }})</template>
          <v-icon color="SystemCTA">{{ $vuetify.rtl ? 'mdi-chevron-left' : 'mdi-chevron-right' }}</v-icon>
        </v-sheet>
      </template>
      <v-card flat>
        <v-toolbar
            dark
            height="66"
            color="primary"
            rounded="0"
        >
          <v-btn
              icon
              dark
              @click="dialog = false"
          >
            <v-icon>{{ $vuetify.rtl ? 'mdi-arrow-right' : 'mdi-arrow-left' }}</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $t("filter_news") }}</v-toolbar-title>
          <v-spacer/>
          <v-menu tile offset-y v-if="isAuthenticated">
            <template v-slot:activator="{ on, attrs }">
              <v-avatar size="50" v-bind="attrs" v-on="on">
                <avatar-component
                    :avatar="avatar"
                    :name="{firstName, lastName}"
                    :size="48"
                    :font-size="25"
                    color="#3ba8ff"
                />
              </v-avatar>
            </template>
            <v-list class="py-0">
              <v-list-item :to="{name: 'profile_settings'}">
                <v-list-item-icon :class="$vuetify.rtl ? 'ml-3' : 'mr-3'">
                  <v-icon size="25" color="SystemCTA">mdi-account-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="text-body-2">{{ $t('accountSettings') }}</v-list-item-title>
              </v-list-item>
              <v-divider/>
              <v-list-item @click="initLogoutConfirm">
                <v-list-item-icon :class="$vuetify.rtl ? 'ml-3' : 'mr-3'">
                  <v-icon size="25" color="SystemCTA">mdi-logout</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="text-body-2">{{ $t('logout') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>

        <v-card-text class="pa-3 news-filter">
          <v-expansion-panels multiple accordion v-model="panel">
            <v-expansion-panel>
              <v-expansion-panel-header hide-actions class="text-subtitle-2 Text01--text pa-3">

                <template v-slot:default="{ open }">
                  <div class="d-flex align-center justify-space-between">
                    {{ $t('filterByType') }}
                    <template v-if="type !== 'all'">(1)</template>

                    <v-btn small icon color="primary">
                      <v-icon>
                        {{ open ? "mdi-chevron-down" : "mdi-chevron-right" }}
                      </v-icon>
                    </v-btn>
                  </div>
                </template>

              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-radio-group
                    hide-details
                    class="mt-0 px-3"
                    v-model="type"
                    mandatory
                >
                  <v-radio
                      :label="$t('all')"
                      value="all"
                  />
                  <v-divider class="mb-4 mt-2"/>
                  <v-radio
                      :label="$t('info')"
                      value="article"
                  />
                  <v-divider class="mb-4 mt-2"/>
                  <v-radio
                      :label="$t('responses')"
                      value="response"
                  />
                  <v-divider class="mb-4 mt-2"/>
                </v-radio-group>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header hide-actions class="text-subtitle-2 Text01--text pa-3">

                <template v-slot:default="{ open }">
                  <div class="d-flex align-center justify-space-between">
                    {{ $t('filterByStatus') }}
                    <template v-if="statuses.length">({{ statuses.length }})</template>

                    <v-btn small icon color="primary">
                      <v-icon>
                        {{ open ? "mdi-chevron-down" : "mdi-chevron-right" }}
                      </v-icon>
                    </v-btn>
                  </div>
                </template>

              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-list flat class="pt-0">
                  <v-list-item-group v-model="statuses" multiple>

                    <v-list-item value="unread" :ripple="false" class="px-3">
                      <template v-slot:default="{ active }">
                        <v-list-item-content>
                          <v-list-item-title>{{ $t('unread') }}</v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-checkbox
                              :input-value="active"
                              color="primary"
                          />
                        </v-list-item-action>
                      </template>

                    </v-list-item>

                    <v-divider class="mx-3"/>

                    <v-list-item value="favourite" :ripple="false" class="px-3">
                      <template v-slot:default="{ active }">
                        <v-list-item-content>
                          <v-list-item-title>{{ $t('favourites') }}</v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-checkbox
                              :input-value="active"
                              color="primary"
                          />
                        </v-list-item-action>
                      </template>
                    </v-list-item>

                    <v-divider class="mx-3"/>

                    <v-list-item value="hidden" :ripple="false" class="px-3">
                      <template v-slot:default="{ active }">
                        <v-list-item-content>
                          <v-list-item-title>{{ $t('hidden') }}</v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-checkbox
                              :input-value="active"
                              color="primary"
                          />
                        </v-list-item-action>
                      </template>
                    </v-list-item>

                    <v-divider class="mx-3"/>

                  </v-list-item-group>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header hide-actions class="text-subtitle-2 Text01--text pa-3">

                <template v-slot:default="{ open }">
                  <div class="d-flex align-center justify-space-between">
                    {{ $t('byGroups') }}
                    <template v-if="recipientsCounter">({{ recipientsCounter }})</template>

                    <v-btn small icon color="primary">
                      <v-icon>
                        {{ open ? "mdi-chevron-down" : "mdi-chevron-right" }}
                      </v-icon>
                    </v-btn>
                  </div>
                </template>

              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-list flat class="py-0" v-if="hasDirectNews">
                  <v-list-item :ripple="false" class="px-3">
                    <v-list-item-content class="cursor-pointer" @click="isDirectNews = !isDirectNews">
                      <v-list-item-title>{{ $t('displayIndividualNews') }}</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-checkbox
                          color="primary"
                          v-model="isDirectNews"
                      />
                    </v-list-item-action>
                  </v-list-item>

                  <v-divider class="mx-3"/>
                </v-list>

                <v-list flat class="pt-0">
                  <v-list-item-group v-model="recipients" multiple>

                    <div v-for="{id, name} in groups" :key="id">
                      <v-list-item :value="id" :ripple="false" class="px-3">
                        <template v-slot:default="{ active }">
                          <v-list-item-content>
                            <v-list-item-title>{{ name }}</v-list-item-title>
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-checkbox
                                :input-value="active"
                                color="primary"
                            />
                          </v-list-item-action>
                        </template>

                      </v-list-item>

                      <v-divider class="mx-3"/>
                    </div>

                  </v-list-item-group>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>

        <v-footer style="bottom: 59px;" height="100" color="SystemWhite" fixed app>
          <v-row>
            <v-col>
              <v-btn
                  color="primary"
                  outlined
                  tile
                  block
                  @click="resetFilters"
                  :disabled="!resetButtonCounter"
              >
                {{ $t('reset') }}
                <template v-if="resetButtonCounter">({{ resetButtonCounter }})</template>
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                  color="primary"
                  depressed
                  tile
                  block
                  @click="applyFilter"
              >
                {{ $t('apply') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-footer>

        <v-footer height="60" color="GreyFooter" fixed app>
          <span :class="$vuetify.rtl ? 'ml-2' : 'mr-2'" class="text-body-1">© {{ new Date().getFullYear() }}.</span>

          <router-link class="text-body-1 text-decoration-none" :to="{name: 'dashboard'}">
            Stay Informed GmbH
          </router-link>
        </v-footer>
      </v-card>
    </v-dialog>

  </v-banner>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {UserGetters} from "@/store/user";
import {UserActions} from "@/store/user";
import ConfirmationModal from "@/components/ConfirmationModal";
import newsFilterMixin from "@/mixins/newsFilterMixin";
import AvatarComponent from "@/components/pages/AccountSettingsPage/components/AvatarComponent";

export default {
  name: "NewsFilterMobile",

  mixins: [newsFilterMixin],

  components: {
    ConfirmationModal,
    AvatarComponent
  },

  data() {
    return {
      dialog: false,
      logoutConfirmationDialog: false,
    }
  },

  computed: {
    ...mapGetters({
      isAuthenticated: `user/${UserGetters.IsAuthenticated}`,
      accountDetails: `user/${UserGetters.AccountDetails}`,
      avatar: `user/${UserGetters.Avatar}`,
    }),

    firstName() {
      return this.accountDetails?.first_name;
    },
    lastName() {
      return this.accountDetails?.last_name;
    }
  },

  methods: {
    ...mapActions({
      logout: `user/${UserActions.Logout}`,
    }),

    applyFilter() {
      this.saveFilters();
      this.dialog = false;
    },

    initLogoutConfirm() {
      this.logoutConfirmationDialog = true;
    },

    initLogout() {
      this.logout()
          .then(() => {
            this.logoutConfirmationDialog = false;
            return this.$router.replace({name: 'login'});
          })
    },
  }
}
</script>

<style lang="scss" scoped>
.v-banner {
  ::v-deep .v-banner__wrapper {
    border-bottom: none !important;
    padding: 0;

    .v-banner__content {
      padding-right: 0;
    }
  }
}

.v-btn {
  ::v-deep .v-btn__content {
    max-width: 100%;
  }
}
</style>
